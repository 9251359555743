import { Container, Row, Col, Table, Button, Form, InputGroup } from 'react-bootstrap';
import React from 'react';
import { NumericFormat, NumberFormatValues, SourceInfo } from 'react-number-format';
import { GOAPIPATH } from '../constants';
import useAxiosGet from '../hooks/use-axios-get';
import useComponentDidMount from '../hooks/use-component-did-mount';
import useScroll from '../hooks/use-scroll';
import { Bank } from '../store/banks-slice';
import * as depositsInterfaces from '../deposits/DepositsInterfaces';
import DepositOverrideTotalModal, { usePostUpdateDeposit } from './DepositTotalOverrideModal';

interface DepositCellProps {
    deposit: depositsInterfaces.DepositDetail;
    isAM: boolean;
    storeIndex: number;
    dayIndex: number;
    weekIndex: number;
    updateHandler: (weekIndex: number, dayIndex: number, storeIndex: number, isAM: boolean, deposit: depositsInterfaces.DepositDetail) => void;
    dayString: string;
    leftClickAction: string;
    displayTotal: string;
    isFound: boolean;
    isLocked: boolean;
    setModalProps: React.Dispatch<React.SetStateAction<ModalPropsInterface>>;
}

// Deconstructed several props to make useCallback for submitOverrideTotal happy. 
// Was demanding full props dependency without deconstruction.
const DepositCell = ({
    deposit,
    isAM,
    storeIndex,
    dayIndex,
    weekIndex,
    updateHandler,
    ...props
}: DepositCellProps) => {
    const storeHasSubmitted = deposit.SubmittedString !== '';
    var classes: string[] = [];
    if (deposit.Bank.toLowerCase() === "pnc") classes.push('fw-bold');
    if (deposit.AcceptedString !== '') { // Sandy and I saw a situation where a deposit was ONTIME, but not accepted. This is to help prevent that (visually).
        if (deposit.Status === 'ONTIME') {
            classes.push('bg-warning bg-opacity-50');
        } else if (deposit.Status === 'LATE') {
            classes.push('bg-info bg-opacity-50');
        } else if (deposit.Status === 'EARLY') {
            classes.push('bg-success bg-opacity-50');
        } else if (deposit.Status === 'MISSING') {
            classes.push('bg-danger bg-opacity-50');
        };
    };
    if (deposit.IsOverride && deposit.Total !== deposit.OverrideTotal) {
        classes.push('text-danger');
    };
    if (!storeHasSubmitted) classes.push('text-light');
    if (props.isFound && storeHasSubmitted) classes.push('border border-danger border-5');
    if (deposit.Notes !== '') classes.push('corner-cut');

    const acceptClickHandler = (status: string) => {
        const now = new Date(Date.now());
        const updatedDeposit = {
            ...deposit,
            Accepted: now,
            AcceptedString: now.toString(),
            Status: status,
        };
        updateHandler(weekIndex, dayIndex, storeIndex, isAM, updatedDeposit);
    };

    const clearClickHandler = () => {
        // if (deposit.OverrideTotal !== 0) return;
        const updatedDeposit = {
            ...deposit,
            Accepted: new Date(0),
            AcceptedString: '',
            Status: '',
        };
        updateHandler(weekIndex, dayIndex, storeIndex, isAM, updatedDeposit);
    };

    const clickHandler = () => {
        // If the month is locked OR the Sales Journal day has been accepted, don't allow changes.
        // if (!storeHasSubmitted || props.isLocked || deposit.SJAccepted) return;
        if (!storeHasSubmitted || props.isLocked) return;
        switch (props.leftClickAction) {
            case '':
                break;
            case 'ontime':
                acceptClickHandler('ONTIME');
                break;
            case 'late':
                acceptClickHandler('LATE');
                break;
            case 'early':
                acceptClickHandler('EARLY');
                break;
            case 'missing':
                acceptClickHandler('MISSING');
                break;
            case 'unverified':
                clearClickHandler();
        }
    };

    const submitOverrideTotal = React.useCallback((overrideTotal: number, isOverride: boolean, notes: string, bank: Bank, hitBankNextMonth: boolean) => {
        // If the month is locked OR the Sales Journal day has been accepted, don't allow changes.
        // if (deposit.SJAccepted || props.isLocked) return;
        if (props.isLocked) return;
        const updatedDeposit: depositsInterfaces.DepositDetail = {
            ...deposit,
            OverrideTotal: overrideTotal,
            IsOverride: isOverride,
            Notes: notes,
            Bank: bank.Name,
            BankID: bank.ID,
            HitBankNextMonth: hitBankNextMonth,
        };
        if (isOverride && updatedDeposit.AcceptedString === '') {
            const now = new Date(Date.now());
            updatedDeposit.Accepted = now;
            updatedDeposit.AcceptedString = now.toString();
            updatedDeposit.Status = 'ONTIME';
        }
        updateHandler(weekIndex, dayIndex, storeIndex, isAM, updatedDeposit);
    }, [deposit, weekIndex, dayIndex, isAM, storeIndex, updateHandler, props.isLocked]);

    const rightClickHandler = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        event.preventDefault();
        if (storeHasSubmitted) {
            props.setModalProps({
                show: true,
                // deposit: deposit,
                weekIndex: weekIndex,
                dayIndex: dayIndex,
                storeIndex: storeIndex,
                isAM: isAM,
                dayString: props.dayString,
                submitOverrideHandler: submitOverrideTotal,
            });
        }
    };

    return (
        <td
            onClick={clickHandler}
            className={`py-1 ${classes.join(' ')}`}
            onContextMenu={rightClickHandler}
        >
            <div className={deposit.HitBankNextMonth ? 'border border-secondary border-1' : ''}>
                {props.displayTotal}
            </div>
        </td>
    );
};

const CellMemo = React.memo(DepositCell);

interface DayRowProps {
    day: depositsInterfaces.DayData;
    dayIndex: number;
    weekIndex: number;
    updateHandler: (weekIndex: number, dayIndex: number, storeIndex: number, isAM: boolean, deposit: depositsInterfaces.DepositDetail) => void;
    leftClickAction: string;
    findValue: number | undefined;
    isLocked: boolean;
    setModalProps: React.Dispatch<React.SetStateAction<ModalPropsInterface>>;
}

const DayRow = ({ ...props }: DayRowProps) => {
    const integerFormatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 });
    const numberFormatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
    const dayString = `${props.day.Day} ${props.day.DayNum}`;

    const createCellElement = (index: number, deposit: depositsInterfaces.DepositDetail, isAM: boolean) => {
        const total = deposit.IsOverride ? deposit.OverrideTotal : deposit.Total;
        const isFound = props.findValue !== undefined ? total === props.findValue : false;
        const displayTotal = Number.isInteger(total) ? integerFormatter.format(total) : numberFormatter.format(total);
        return (
            <CellMemo
                key={`${isAM ? 'AM' : 'PM'}${index}`}
                deposit={deposit}
                isAM={isAM}
                storeIndex={index}
                weekIndex={props.weekIndex}
                dayIndex={props.dayIndex}
                updateHandler={props.updateHandler}
                dayString={dayString}
                leftClickAction={props.leftClickAction}
                displayTotal={displayTotal}
                isFound={isFound}
                isLocked={props.isLocked}
                setModalProps={props.setModalProps}
            />
        );
    };

    const amElements = props.day.AM.map((deposit, index) => {
        return createCellElement(index, deposit, true);
    });

    const pmElements = props.day.PM.map((deposit, index) => {
        return createCellElement(index, deposit, false);
    });

    return (
        <React.Fragment>
            <tr>
                <th className='align-middle px-0' rowSpan={2}>{props.day.Day}</th>
                <th className='align-middle px-0' rowSpan={2}>{props.day.DayNum}</th>
                {amElements}
            </tr>
            <tr className='border-bottom border-dark'>
                {pmElements}
            </tr>
        </React.Fragment>
    );
};

export const TotalColumn = ({ description, total, classes }: { description: string; total: string; classes: string; }) => {
    return (
        <Col xs='auto'>
            <h6 className={classes}>{description}</h6>
            <h5 className={classes}>{total}</h5>
        </Col>
    );
};

interface WeekSummaryProps {
    week: depositsInterfaces.WeekData;
    weekIndex: number;
    updateHandler: (weekIndex: number, dayIndex: number, storeIndex: number, isAM: boolean, deposit: depositsInterfaces.DepositDetail) => void;
    // bankCompanyOrder: string[];
    leftClickAction: string;
    isLocked: boolean;
    setLeftClickAction: React.Dispatch<React.SetStateAction<string>>;
    setModalProps: React.Dispatch<React.SetStateAction<ModalPropsInterface>>;
}

const WeekSummary = ({ ...props }: WeekSummaryProps) => {
    const [minimize, setMinimize] = React.useState(!props.week.Show);
    const [findValue, setFindValue] = React.useState<{ float?: number; value: string; }>({ value: '' });

    const [executeScroll, scrollToElementRef] = useScroll();
    // useComponentDidMount can be dangerous since it ONLY runs on mount using useEffect with
    // an empty dependency array. We only want to scroll to the starting location ONCE, so it's use is justified.
    useComponentDidMount(() => { if (props.week.ScrollTo) executeScroll(); });

    const integerFormatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 });
    const numberFormatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
    const totalColors = ['text-danger', 'text-success', 'text-primary', 'text-purple', 'text-secondary', 'text-info', 'text-danger opacity-75', 'text-success opacity-75', 'text-primary opacity-75'];

    var bankCompanyOrder = Object.keys(props.week.BankCompanyTotals);
    bankCompanyOrder = bankCompanyOrder.sort((bankCompanyA, bankCompanyB) => {
        const [bankA, companyA] = bankCompanyA.split(' ');
        const [bankB, companyB] = bankCompanyB.split(' ');
        // Sort by company first (in reverse order)
        if (companyA > companyB) return -1;
        if (companyA < companyB) return 1;
        // Then by bank in regular order.
        if (bankA > bankB) return 1;
        if (bankA < bankB) return -1;
        return 0;
    });

    const bankCompanyTotalElements = bankCompanyOrder.map(bankCompany => {
        const total = props.week.BankCompanyTotals[bankCompany] ? props.week.BankCompanyTotals[bankCompany] : 0;
        const displayTotal = Number.isInteger(total) ? integerFormatter.format(total) : numberFormatter.format(total);
        return (
            <TotalColumn
                key={bankCompany}
                description={bankCompany}
                total={displayTotal}
                classes={`text-center text-nowrap fw-bold ${totalColors[bankCompanyOrder.indexOf(bankCompany)]}`} />
        );
    });

    const storeHeaderElements = props.week.Days[0].AM.map(detail => <th key={detail.Store}>#{detail.Store}</th>);

    const storeTotalsElements = props.week.Days[0].AM.map(detail => {
        const total = props.week.StoreTotals[detail.Store];
        const displayTotal = Number.isInteger(total) ? integerFormatter.format(total) : numberFormatter.format(total);
        return (
            <th
                className={`${totalColors[bankCompanyOrder.indexOf(`${detail.Bank} ${detail.Company}`)]}`}
                key={detail.Store}
            >
                {displayTotal}
            </th>
        );
    });

    const dayRowElements = props.week.Days.map((day, index) => (
        <DayRow
            key={day.DayNum}
            day={day}
            dayIndex={index}
            weekIndex={props.weekIndex}
            updateHandler={props.updateHandler}
            leftClickAction={props.leftClickAction}
            findValue={findValue.float}
            isLocked={props.isLocked}
            setModalProps={props.setModalProps}
        />
    ));

    const handleActionButtonClick = (action: string) => {
        props.setLeftClickAction(prevAction => {
            if (prevAction === action) return '';
            return action;
        });
    };

    const handleFindChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (sourceInfo.event === null || sourceInfo.event === undefined || sourceInfo.event.target === null) {
            return;
        }
        setFindValue({
            float: values.floatValue,
            value: values.value,
        });
    };

    return (
        <Container ref={scrollToElementRef} className='shadow bg-secondary bg-opacity-10 mb-5 pb-1 rounded' fluid='lg'>
            <div onClick={() => setMinimize(prev => !prev)} className='pt-3 ml-3'>
                <Row>
                    <Col>
                        <h4>Week Ending {props.week.EndDate}</h4>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-evenly gy-2'>
                    {bankCompanyTotalElements}
                </Row>
            </div>
            <Row>
                <Table className='mb-0' borderless responsive>
                    <thead className='bg-secondary bg-opacity-50'>
                        <tr className='text-center border-bottom border-dark'>
                            <th className='px-0'>Day</th>
                            <th className='px-0'>Date</th>
                            {storeHeaderElements}
                        </tr>
                    </thead>
                    {!minimize &&
                        <tbody className='text-center'>
                            {dayRowElements}
                        </tbody>
                    }
                    <tfoot className='text-center'>
                        <tr className='table-secondary'>
                            <th colSpan={2}>TOTALS</th>
                            {storeTotalsElements}
                        </tr>
                    </tfoot>
                </Table>
            </Row>
            {!minimize &&
                <Row className='pt-1'>
                    {!props.isLocked &&
                        <React.Fragment>
                            <Col xs='auto' className='pe-0'>
                                <Button
                                    onClick={() => handleActionButtonClick('ontime')}
                                    className='me-1 mb-1 mb-md-0'
                                    variant={props.leftClickAction === 'ontime' ? 'warning' : 'secondary'}
                                >
                                    On-Time
                                </Button>
                                <Button
                                    onClick={() => handleActionButtonClick('late')}
                                    className='me-1 mb-1 mb-md-0'
                                    variant={props.leftClickAction === 'late' ? 'info' : 'secondary'}
                                >
                                    Late
                                </Button>
                                <Button
                                    onClick={() => handleActionButtonClick('early')}
                                    className='me-1 mb-1 mb-md-0'
                                    variant={props.leftClickAction === 'early' ? 'success' : 'secondary'}
                                >
                                    Early
                                </Button>
                            </Col>
                            <Col className='ps-sm-0' xs={12} sm='auto'>
                                <Button
                                    onClick={() => handleActionButtonClick('missing')}
                                    className='me-1 mb-1 mb-md-0'
                                    variant={props.leftClickAction === 'missing' ? 'danger' : 'secondary'}
                                >
                                    Missing
                                </Button>
                                <Button
                                    onClick={() => handleActionButtonClick('unverified')}
                                    className={`me-1 mb-1 mb-md-0 text-light ${props.leftClickAction === 'unverified' ? 'bg-dark' : ''}`}
                                    variant={props.leftClickAction === 'unverified' ? 'dark' : 'secondary'}
                                >
                                    Unverified
                                </Button>
                            </Col>
                        </React.Fragment>
                    }
                    <Col className='ms-auto' xs={12} md={3} xl={2}>
                        <InputGroup>
                            <InputGroup.Text>Find:</InputGroup.Text>
                            <Form.Control
                                as={NumericFormat}
                                // size='lg'
                                className='text-end'
                                id='find'
                                name='find'
                                value={findValue.value}
                                onValueChange={handleFindChange}
                                type='text'
                                // prefix='$ '
                                thousandSeparator=','
                                allowNegative={false}
                                decimalScale={2}
                                valueIsNumericString
                            />
                        </InputGroup>
                    </Col>
                </Row>
            }
        </Container>
    );
};

interface VerifyDepositProps {
    start: string; // YYYY-MM-DD 
    end: string; // YYYY-MM-DD
    isLocked: boolean;
}

interface ModalPropsInterface {
    show: boolean;
    // deposit: depositsInterfaces.DepositDetail;
    weekIndex: number;
    dayIndex: number;
    storeIndex: number;
    isAM: boolean;
    dayString: string;
    submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, bank: Bank, hitBankNextMonth: boolean) => void;
}

const VerifyDeposits = ({ ...props }: VerifyDepositProps) => {
    const [weeks, setWeeks] = React.useState<depositsInterfaces.WeekData[]>([]);
    // const [isLocked, setIsLocked] = React.useState(false);
    // const [bankCompanyOrder, setBankCompanyOrder] = React.useState<string[]>([]);
    const [leftClickAction, setLeftClickAction] = React.useState('');
    const [modalProps, setModalProps] = React.useState<ModalPropsInterface>({
        show: false,
        weekIndex: 0,
        dayIndex: 0,
        storeIndex: 0,
        // deposit: {
        //     Store: 0,
        //     Bank: '',
        //     Company: '',
        //     Total: 0,
        //     OverrideTotal: 0,
        //     IsOverride: false,
        //     Submitted: new Date(),
        //     SubmittedString: '',
        //     Accepted: new Date(),
        //     AcceptedString: '',
        //     Status: '',
        //     Notes: '',
        //     ID: '',
        //     BankID: '',
        // },
        isAM: true,
        dayString: '',
        submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, bank: Bank) => { return; },
    });

    const applyWeeksData = React.useCallback((data: {
        weeks: depositsInterfaces.WeekData[];
    }) => {
        let scrollTo = false;
        const newWeeks = data.weeks.map(week => {
            let show = false;
            week.Days = week.Days.map(day => {
                day.AM = day.AM.map(deposit => {
                    deposit.Accepted = new Date(deposit.AcceptedString);
                    deposit.Submitted = new Date(deposit.SubmittedString);
                    if (!deposit.AcceptedString && deposit.SubmittedString) show = true;
                    return deposit;
                });
                day.PM = day.PM.map(deposit => {
                    deposit.Accepted = new Date(deposit.AcceptedString);
                    deposit.Submitted = new Date(deposit.SubmittedString);
                    if (!deposit.AcceptedString && deposit.SubmittedString) show = true;
                    return deposit;
                });
                return day;
            });
            if (show && !scrollTo) {
                week.ScrollTo = true;
                scrollTo = true;
            }
            week.Show = show;
            return week;
        });

        if (!scrollTo) newWeeks[0].ScrollTo = true;

        // var bcOrder = Object.keys(newWeeks[0].BankCompanyTotals);
        // bcOrder = bcOrder.sort((bankCompanyA, bankCompanyB) => {
        //     const [bankA, companyA] = bankCompanyA.split(' ');
        //     const [bankB, companyB] = bankCompanyB.split(' ');
        //     // Sort by company first (in reverse order)
        //     if (companyA > companyB) return -1;
        //     if (companyA < companyB) return 1;
        //     // Then by bank in regular order.
        //     if (bankA > bankB) return 1;
        //     if (bankA < bankB) return -1;
        //     return 0;
        // });
        // setBankCompanyOrder(bcOrder);
        setWeeks(newWeeks);
        // setIsLocked(data.isLocked);
    }, []);

    const [axiosGetWeeksData] = useAxiosGet(applyWeeksData);

    React.useEffect(() => {
        const getWeeksData = () => {
            axiosGetWeeksData(
                `${GOAPIPATH}/companyDeposits?from=${props.start}&to=${props.end}`,
                {
                    withCredentials: true
                }
            );
        };
        getWeeksData();
        const intervalId = setInterval(getWeeksData, 1 * 60 * 1000); // x minute(s) x*60*1000
        return () => clearInterval(intervalId);
        // Including storeIndex, dayIndex and isAM in dependency array to trigger refresh on cell right click. Just using modalProps.show triggers to often.
    }, [props.start, props.end, axiosGetWeeksData, modalProps.storeIndex, modalProps.dayIndex, modalProps.isAM]);

    // const [axiosPostDeposit] = useAxiosPost(undefined);
    const postUpdateDeposit = usePostUpdateDeposit();

    const updateDeposit = React.useCallback(async (weekIndex: number, dayIndex: number, storeIndex: number, isAM: boolean, deposit: depositsInterfaces.DepositDetail) => {
        // If the month is locked OR the Sales Journal day has been accepted, don't allow changes.
        // if (props.isLocked || deposit.SJAccepted) return;
        if (props.isLocked) return;
        const error = await postUpdateDeposit(deposit);
        if (error) {
            console.log(error);
        } else {
            setWeeks(prevWeeks => {
                var newWeeks = [...prevWeeks];
                var oldDeposit: depositsInterfaces.DepositDetail | null = null;
                if (isAM) {
                    oldDeposit = newWeeks[weekIndex].Days[dayIndex].AM[storeIndex];
                    newWeeks[weekIndex].Days[dayIndex].AM[storeIndex] = deposit;
                } else {
                    oldDeposit = newWeeks[weekIndex].Days[dayIndex].PM[storeIndex];
                    newWeeks[weekIndex].Days[dayIndex].PM[storeIndex] = deposit;
                }
                const oldTotal = oldDeposit.IsOverride ? oldDeposit.OverrideTotal : oldDeposit.Total;
                const newTotal = deposit.IsOverride ? deposit.OverrideTotal : deposit.Total;
                if (oldTotal !== newTotal || oldDeposit.Bank !== deposit.Bank) {
                    // Subtract old total.
                    newWeeks[weekIndex].BankCompanyTotals[`${oldDeposit.Bank} ${oldDeposit.Company}`] -= oldTotal;
                    newWeeks[weekIndex].StoreTotals[oldDeposit.Store] -= oldTotal;

                    // Add new total.
                    newWeeks[weekIndex].BankCompanyTotals[`${deposit.Bank} ${deposit.Company}`] += newTotal;
                    newWeeks[weekIndex].StoreTotals[deposit.Store] += newTotal;
                }
                // if (oldTotal !== newTotal) {
                //     const difference = newTotal - oldTotal;
                //     if (difference !== 0) {
                //         newWeeks[weekIndex].BankCompanyTotals[`${deposit.Bank} ${deposit.Company}`] += difference;
                //         newWeeks[weekIndex].StoreTotals[deposit.Store] += difference;
                //     }
                // }
                return newWeeks;
            });
        }
    }, [postUpdateDeposit, props.isLocked]);

    // const acceptedCutOffDate = React.useMemo(() => {
    //     const datePieces = props.end.split('-').map(pieceString => Number(pieceString));
    //     return new Date(datePieces[0], datePieces[1]-1, datePieces[2]+2);
    // }, [props.end]);

    const weekElements = weeks.map((week, index) => {
        return (
            <WeekSummary
                key={week.EndDate}
                week={week}
                weekIndex={index}
                updateHandler={updateDeposit}
                // bankCompanyOrder={bankCompanyOrder}
                isLocked={props.isLocked}
                leftClickAction={leftClickAction}
                setLeftClickAction={setLeftClickAction}
                setModalProps={setModalProps}
            />
        );
    });

    return (
        <div>
            <DepositOverrideTotalModal
                show={modalProps.show}
                hideModal={() => {
                    setModalProps(prevState => {
                        return { ...prevState, show: false };
                    });
                }}
                // deposit={modalProps.deposit}
                deposit={
                    weeks.length > 0 ?
                        modalProps.isAM
                            ? weeks[modalProps.weekIndex].Days[modalProps.dayIndex].AM[modalProps.storeIndex]
                            : weeks[modalProps.weekIndex].Days[modalProps.dayIndex].PM[modalProps.storeIndex]
                        : { ...depositsInterfaces.defaultDepositDetail }
                }
                isAM={modalProps.isAM}
                dayString={modalProps.dayString}
                submitOverrideHandler={modalProps.submitOverrideHandler}
                isLocked={props.isLocked}
            />
            <Container fluid='lg' className='mt-3'>
                {weekElements}
            </Container>
        </div>
    );
};

export default VerifyDeposits;