import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format';
import * as salesJournalInterfaces from './SalesJournalInterfaces';

interface SalesJournalEditModalInputProps {
    id?: string;
    label?: string;
    value: number;
    originalValue?: number;
    valueString: string;
    handleChange?: ((values: NumberFormatValues, sourceInfo: SourceInfo) => void);
    disabled?: boolean;
    decimalScale?: number;
    allowNegative?: boolean;
    autoFocus?: boolean;
    labelElement?: JSX.Element;
    accepted?: boolean;
}

export const SalesJournalEditModalInput = ({ ...props }: SalesJournalEditModalInputProps) => {
    const acceptedMarkerElement = props.accepted !== undefined ? <span className='text-danger'>{props.accepted ? '✅ ' : '❗'}</span> : undefined;

    return (
        <InputGroup className='mb-2'>
            <InputGroup.Text className='w-50'>
                {props.labelElement ?
                    props.labelElement :
                    <p className='my-0 ms-auto'>{acceptedMarkerElement}{props.label}</p>
                }
            </InputGroup.Text>
            {props.originalValue &&
                <InputGroup.Text className='w-25'>
                    <span className='w-100 text-end'>
                        Orig: <span className={`${props.originalValue < 0 ? 'text-danger' : 'text-dark'}`}>{props.originalValue}</span>{' -->'}
                    </span>
                </InputGroup.Text>
            }
            <Form.Control
                as={NumericFormat}
                className={`text-end ${props.value < 0 ? 'text-danger' : ''}`}
                id={props.id}
                name={props.id}
                value={props.valueString}
                onValueChange={props.handleChange}
                type='text'
                prefix='$ '
                thousandSeparator=','
                allowNegative={props.allowNegative}
                decimalScale={props.decimalScale}
                // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                fixedDecimalScale={false}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                valueIsNumericString
            // placeholder='0'
            />
        </InputGroup>
    );
};

interface ExtendedItemElementProps {
    extendedItem: salesJournalInterfaces.ExtendedItem;
    index: number;
    editHandler: (extendedItemIndex: number) => void;
    disabled: boolean;
}

const ExtendedItemElement = ({ ...props }: ExtendedItemElementProps) => {
    const onClickHandler = () => {
        if (!props.disabled) {
            props.editHandler(props.index);
        }
    };

    // const style = props.disabled ? {} : {cursor: 'pointer'}

    return (
        <InputGroup className={`mb-2 ${props.disabled ? '' : 'cursor-pointer'}`} onClick={onClickHandler}>
            <InputGroup.Text className='ps-4 w-50 text-wrap text-start'><p className='my-0'><strong>{props.extendedItem.AccountDesc}</strong>: {props.extendedItem.Notes}</p></InputGroup.Text>
            {/* <InputGroup.Text className='w-25' id=''>{props.extendedItem.desc}</InputGroup.Text>
            <InputGroup.Text className='w-50' id=''>{props.extendedItem.note}</InputGroup.Text> */}
            <Form.Control
                // style={style}
                as={NumericFormat}
                className={`text-end ${props.disabled ? '' : 'cursor-pointer'} ${props.extendedItem.Total < 0 ? 'text-danger' : ''}`}
                // className='text-end'
                id=''
                name=''
                value={props.extendedItem.Total}
                // onValueChange={handleChange}
                type='text'
                // prefix={isPaidOut ? '$ -' : '$ '}
                prefix='$ '
                thousandSeparator=','
                allowNegative={true}
                decimalScale={2}
                fixedDecimalScale={true}
                // autoFocus={!deposit.AMAccepted}
                // readOnly={true}
                disabled={true}
            // placeholder='0'
            />
        </InputGroup>
    );
};

interface ExtendedItemsDisplayProps {
    isPaidOut: boolean;
    extendedItems: salesJournalInterfaces.ExtendedItem[];
    editHandler: (extendedItemIndex: number) => void;
    disabled: boolean;
}

export const ExtendedItemsDisplay = ({ ...props }: ExtendedItemsDisplayProps) => {
    let extendedItemElements: JSX.Element[] = [];
    props.extendedItems.forEach((extendedItem, index) => {
        if (props.isPaidOut === extendedItem.IsPaidOut) {
            extendedItemElements.push(
                <ExtendedItemElement
                    key={index}
                    extendedItem={extendedItem}
                    index={index}
                    // isPaidOut={props.isPaidOut}
                    editHandler={props.editHandler}
                    disabled={props.disabled}
                />
            );
        }
    });

    return (
        <React.Fragment>
            {extendedItemElements.length > 0 &&
                <h5 className='text-start ms-3 mb-1'>{props.isPaidOut ? 'Paid Outs' : 'Other Income'}</h5>
            }
            {extendedItemElements}
        </React.Fragment>
    );
};