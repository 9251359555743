import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Routes, Route, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

// import Layout from './components/Layout';
// import RequireAuth from './components/RequireAuth';
// import DepositEntry from './deposits/DepositEntry';
// import DepositTabs from './deposits/DepositTabs';
// import EditSalesJournal from './sales-journal/EditSalesJournal';
// import SalesJournalEntry from './sales-journal/SalesJournalEntry';

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     // https://www.youtube.com/watch?v=oUZjO00NkhY&t=196s
//     <Route element={<Layout />} >
//       <Route element={<RequireAuth allowedRoles={['PFC', 'acct payable', 'comptroller', 'superUser']} />} >
//         <Route path='/dailyLogSummary' element={<DepositTabs />} />
//       </Route>
//       <Route element={<RequireAuth allowedRoles={['supervisor', 'manager', 'comptroller', 'superUser']} />} >
//         <Route path='/dailyLog' element={<DepositEntry />} />
//         <Route path='/salesJournalEntry' element={<SalesJournalEntry />} />
//         <Route 
//           path='/editSalesJournal/:storeId/:date' 
//           loader={({params}) => {
//             return `${params.storeId} and ${params.date}`
//           }}
//           element={<EditSalesJournal />} 
//         />
//       </Route>
//       <Route element={<RequireAuth allowAll />} >
//         <Route path="*" element={<h2>404 Not Found</h2>} />
//       </Route>
//     </Route>
//   )
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
        {/* <BrowserRouter>
          <App />
        </BrowserRouter> */}
        {/* <RouterProvider router={router} /> */}
        <App />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
