import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import React from 'react';
import { redirectToLogin } from '../helpers';

const useAxiosGet = (applyData: (data: any) => void) => {
    // const [isLoading, setIsLoading] = React.useState(false);
    // const [error, setError] = React.useState<string | null>(null);
    // console.log("hello from useAxiosGet")
    const sendRequest = React.useCallback((url: string, config?: AxiosRequestConfig<any> | undefined) => {
        // setIsLoading(true);
        axios.get(
            url,
            config
        ).then(response => {
            applyData(response.data);
        }).catch(function (error: AxiosError) {
            // console.log(error.response?.status);
            if (error.response?.status === 401) redirectToLogin();
            console.log('Error getting data:', error)
        });
    }, [applyData]); // the passed applyData function should be wrapped in React.useCallback (or, applyData should be made a parameter of sendRequest).

    return [
        // isLoading,
        // error,
        sendRequest,
    ];
};

export default useAxiosGet;