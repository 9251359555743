import React from "react";
import { Button, Container, Form, Modal, Table, Row, Col } from "react-bootstrap";
import { NumericFormat, NumberFormatValues, SourceInfo } from 'react-number-format';
import { GOAPIPATH } from '../constants';
import useAxiosGet from '../hooks/use-axios-get';
import useAxiosPost from '../hooks/use-axios-post';
import { useAppSelector } from '../hooks/use-redux';
import StoreSelector from '../components/StoreSelector';

interface DepositRowData {
    Date: string; // UTC date string from golang.
    DateLocal: Date; // Local date created from DateString
    DateString: string; // String date from golang. Just year, month, and day.
    Day: string;
    Submitted: boolean;
    AM: number;
    AMOverride: number;
    AMAccepted: boolean;
    PM: number;
    PMOverride: number;
    PMAccepted: boolean;
    CC1: number;
    CC2: number;
    CCAccepted: boolean;
    CCOverride: number;
    CCTotal: number;
}

interface SubmitDepositModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    deposit: DepositRowData;
    submitHandler: (deposit: DepositRowData) => void;
    storeNum: number;
}

const SubmitDepositModal = ({ show, setShow, deposit, submitHandler, storeNum }: SubmitDepositModalProps) => {
    const [values, setValues] = React.useState({
        deposit_am: NaN,
        deposit_am_string: '',
        deposit_pm: NaN,
        deposit_pm_string: '',
        creditcard_1: NaN,
        creditcard_1_string: '',
        creditcard_2: NaN,
        creditcard_2_string: '',
    });

    React.useEffect(() => {
        if (deposit !== undefined && deposit !== null) {
            setValues({
                deposit_am: deposit.AM !== 0 ? deposit.AM : NaN,
                deposit_am_string: deposit.AM !== 0 ? deposit.AM.toString() : '',
                deposit_pm: deposit.PM !== 0 ? deposit.PM : NaN,
                deposit_pm_string: deposit.PM !== 0 ? deposit.PM.toString() : '',
                creditcard_1: deposit.CC1 !== 0 ? deposit.CC1 : NaN,
                creditcard_1_string: deposit.CC1 !== 0 ? deposit.CC1.toString() : '',
                creditcard_2: deposit.CC2 !== 0 ? deposit.CC2 : NaN,
                creditcard_2_string: deposit.CC2 !== 0 ? deposit.CC2.toString() : '',
            });
        }
    }, [deposit]);

    const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const name = sourceInfo.event.target.name;
        setValues(prevValues => {
            return {
                ...prevValues,
                [name]: values.floatValue,
                [`${name}_string`]: values.value,
            };
        });
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const cc1 = values.creditcard_1 ? values.creditcard_1 : 0;
        const cc2 = values.creditcard_2 ? values.creditcard_2 : 0;
        const data: DepositRowData = {
            ...deposit,
            AM: values.deposit_am ? values.deposit_am : 0,
            PM: values.deposit_pm ? values.deposit_pm : 0,
            CC1: cc1,
            CC2: cc2,
            CCTotal: cc1 + cc2,
            Submitted: true,
        };
        submitHandler(data);
    };

    const hideModal = () => {
        setShow(false);
    };

    const depositDecimalScale = storeNum === 310 ? 2 : 0;

    return (
        <Modal
            show={show}
            onHide={hideModal}
            backdrop='static'
        // animation={false}
        >
            {deposit &&
                <Form onSubmit={submit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Deposits for {deposit?.Day} ({deposit?.DateLocal.toLocaleDateString()})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label className='fs-5' htmlFor='deposit_am'>Deposit AM</Form.Label>
                            <Form.Control
                                as={NumericFormat}
                                className='text-end mb-2'
                                id='deposit_am'
                                name='deposit_am'
                                value={values.deposit_am_string}
                                onValueChange={handleChange}
                                type='text'
                                prefix='$ '
                                thousandSeparator=','
                                allowNegative={false}
                                decimalScale={depositDecimalScale}
                                // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                                fixedDecimalScale={false}
                                autoFocus={!deposit.AMAccepted}
                                disabled={deposit.AMAccepted}
                                valueIsNumericString
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='fs-5' htmlFor='deposit_pm'>Deposit PM</Form.Label>
                            <Form.Control
                                as={NumericFormat}
                                className='text-end mb-2'
                                id='deposit_pm'
                                name='deposit_pm'
                                value={values.deposit_pm_string}
                                onValueChange={handleChange}
                                type='text'
                                prefix='$ '
                                thousandSeparator=','
                                allowNegative={false}
                                decimalScale={depositDecimalScale}
                                // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                                fixedDecimalScale={false}
                                autoFocus={deposit.AMAccepted && !deposit.PMAccepted}
                                disabled={deposit.PMAccepted}
                                valueIsNumericString
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='fs-5' htmlFor='creditcard_1'>Credit Card 1</Form.Label>
                            <Form.Control
                                as={NumericFormat}
                                className='text-end mb-2'
                                id='creditcard_1'
                                name='creditcard_1'
                                value={values.creditcard_1_string}
                                onValueChange={handleChange}
                                type='text'
                                prefix='$ '
                                thousandSeparator=','
                                allowNegative={false}
                                decimalScale={2}
                                // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                                fixedDecimalScale={false}
                                autoFocus={deposit.AMAccepted && deposit.PMAccepted && !deposit.CCAccepted}
                                disabled={deposit.CCAccepted}
                                valueIsNumericString
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='fs-5' htmlFor='creditcard_2'>Credit Card 2</Form.Label>
                            <Form.Control
                                as={NumericFormat}
                                className='text-end mb-3'
                                id='creditcard_2'
                                name='creditcard_2'
                                value={values.creditcard_2_string}
                                onValueChange={handleChange}
                                type='text'
                                prefix='$ '
                                thousandSeparator=','
                                allowNegative={false}
                                decimalScale={2}
                                // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                                fixedDecimalScale={false}
                                disabled={deposit.CCAccepted}
                                valueIsNumericString
                            />
                        </Form.Group>
                        {/* <button onClick={submit}>Submit</button> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' size='lg' onClick={hideModal} tabIndex={-1}>Cancel</Button>
                        <Button disabled={deposit.AMAccepted && deposit.PMAccepted && deposit.CCAccepted} type='submit' size='lg'>Submit</Button>
                    </Modal.Footer>
                </Form>
            }
        </Modal>
    );
};

interface DepositCellProps {
    value: number;
    override: number;
    precision: number;
    accepted: boolean;
}

const DepositCell = ({ value, override, precision, accepted }: DepositCellProps) => {
    const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: precision });
    const displayValue = formatter.format(value);
    return (
        <td className={accepted ? '' : 'text-secondary'}>
            {override !== 0 && override !== value
                ? <React.Fragment><del className='text-danger'>{displayValue}</del> {formatter.format(override)}</React.Fragment>
                : <React.Fragment>{displayValue}</React.Fragment>
            }
        </td>
    );
};

interface DepositRowProps {
    deposit: DepositRowData;
    rowClickHandler: () => void;
    storeNum: number;
}

const DepositRow = ({ deposit, rowClickHandler, storeNum }: DepositRowProps) => {
    const rowClasses = 'text-end' + (deposit.Submitted ? ' bg-light' : ' bg-warning bg-opacity-50 bg-gradient');
    const depositPrecision = storeNum === 310 ? 2 : 0;
    return (
        <tr className={rowClasses} onClick={rowClickHandler}>
            <td className='text-center'>{deposit.DateLocal.toLocaleDateString()}</td>
            <td className='text-center'>{deposit.Day}</td>
            <DepositCell value={deposit.AM} override={deposit.AMOverride} precision={depositPrecision} accepted={deposit.AMAccepted} />
            <DepositCell value={deposit.PM} override={deposit.PMOverride} precision={depositPrecision} accepted={deposit.PMAccepted} />
            <DepositCell value={deposit.CC1} override={0} precision={2} accepted={deposit.CCAccepted} />
            <DepositCell value={deposit.CC2} override={0} precision={2} accepted={deposit.CCAccepted} />
            <DepositCell value={deposit.CCTotal} override={deposit.CCOverride} precision={2} accepted={deposit.CCAccepted} />
            {/* <td>{deposit.AM.toLocaleString()}</td>
            <td>{deposit.PM.toFixed(0).toLocaleString()}</td>
            <td>{deposit.CC1.toFixed(2).toLocaleString()}</td>
            <td>{deposit.CC2.toFixed(2).toLocaleString()}</td> */}
        </tr>
    );
};

const DepositEntry = () => {
    const [depositData, setDepositData] = React.useState<DepositRowData[]>([]);
    const [index, setIndex] = React.useState(0);
    const [show, setShow] = React.useState(false);

    // const userValue = useAppSelector(state => state.auth.user);
    const store = useAppSelector(state => state.selectors.selectedStore);

    const store_filter = React.useMemo(() => [1,2,3,5,7,8,9,11,12,16,17,210], []);
    // const [store, setStore] = React.useState({
    //     id: '',
    //     number: 0,
    // });

    // React.useEffect(() => {
    //     if (userValue === undefined || userValue.Stores === undefined || userValue.Stores.length < 1) {
    //         return;
    //     }
    //     setStore({
    //         id: userValue.Stores[0].ID,
    //         number: userValue.Stores[0].Number,
    //     });
    // }, [userValue]);

    const applyStoreDeposits = React.useCallback((data: {deposits: DepositRowData[]}) => {
        const deposits = data.deposits.map((deposit: DepositRowData) => {
            // Convert date string to date Date.
            deposit.DateLocal = new Date(deposit.DateString);
            return deposit;
        });
        setDepositData(deposits);
    }, []);

    const [axiosGetStoreDeposits] = useAxiosGet(applyStoreDeposits)

    const getStoreDeposits = React.useCallback((storeID: string) => {
        if (storeID === '') {
            setDepositData([]);
            return;
        }
        axiosGetStoreDeposits(
            `${GOAPIPATH}/storeDeposits/${storeID}`,
            {
                withCredentials: true
            }
        );
    }, [axiosGetStoreDeposits]);

    React.useEffect(() => {
        getStoreDeposits(store.ID);
        // I don't think this screen needs to auto update since we update when a row is clicked.
        // const intervalId = setInterval(getStoreDeposits(store.ID), 5 * 60 * 1000); // x minute(s) x*60*1000
        // return () => clearInterval(intervalId);
    }, [getStoreDeposits, store.ID]);

    // const storeOptionElements = userValue?.Stores?.filter(store => store.Number === 310).map(store => (
    //     <option key={store.ID} value={store.ID}>{store.Number}</option>
    // ));

    const [axiosPostDeposit] = useAxiosPost(undefined);

    const submitHandler = React.useCallback(async (deposit: DepositRowData) => {
        const data = {
            date: deposit.Date,
            store: store.Number,
            depositAM: deposit.AM,
            depositPM: deposit.PM,
            creditCard1: deposit.CC1,
            creditCard2: deposit.CC2,
        };
        const error = await axiosPostDeposit(
            `${GOAPIPATH}/submitDeposit`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        )
        if (error !== null) {
            console.log('Failed to submit deposit. The following error was generated:', error);
            return;
        }
        setDepositData(prevState => {
            var state = [...prevState];
            state[index] = deposit;
            return state;
        });
        setShow(false);
    }, [axiosPostDeposit, index, store.Number]);

    const rowClickHandler = (index: number) => {
        getStoreDeposits(store.ID);
        setIndex(index);
        setShow(true);
    };

    // const selectStoreHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { value, selectedIndex } = event.target;
    //     setStore({ id: value, number: Number(event.target[selectedIndex].innerText) });
    // };

    const rows = depositData.map((deposit, index) => (
        <DepositRow 
            key={index} 
            deposit={deposit} 
            rowClickHandler={() => rowClickHandler(index)} 
            storeNum={store.Number} 
        />
    ));

    return (
        <div>
            <SubmitDepositModal show={show} setShow={setShow} deposit={depositData[index]} submitHandler={submitHandler} storeNum={store.Number} />
            <Container fluid='lg' className='mt-3'>
                <Row className='text-nowrap'>
                    <h2>Daily Log Entry</h2>
                </Row>
                <Row className='d-flex align-items-center mb-2'>
                    <Col xs='auto'>
                        <StoreSelector filter={store_filter}/>
                        {/* <InputGroup>
                            <InputGroup.Text>Store #</InputGroup.Text>
                            <Form.Select
                                className='text-end'
                                onChange={selectStoreHandler}
                                disabled={userValue && userValue.Stores && userValue.Stores.length > 1 ? false : true}
                            >
                                {storeOptionElements}
                            </Form.Select>
                        </InputGroup> */}
                    </Col>
                    <Col>
                        <p className='fs-4 mt-2 mb-0 text-nowrap'>Click on a row to edit.</p>
                    </Col>
                </Row>
                <Row>
                    <Table className='mb-0' bordered hover responsive>
                        <thead>
                            <tr className='text-end text-nowrap bg-secondary bg-opacity-50 bg-gradient'>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Day</th>
                                <th>Deposit AM</th>
                                <th>Deposit PM</th>
                                <th>Credit Card 1</th>
                                <th>Credit Card 2</th>
                                <th>Credit Card Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
};

export default DepositEntry;