import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import banksSlice from './banks-slice'
import salesJournalSlice from './sales-journal-slice';
import selectorsSlice from './selectors-slice';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        banks: banksSlice.reducer,
        salesJournal: salesJournalSlice.reducer,
        selectors: selectorsSlice.reducer,
    }
});

// https://redux.js.org/usage/usage-with-typescript#define-root-state-and-dispatch-types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;