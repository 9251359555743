import React from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format';
import { useAppDispatch, useAppSelector } from '../hooks/use-redux';
import { getSalesJournalAccounts } from '../store/sales-journal-actions';
import * as salesJournalInterfaces from './SalesJournalInterfaces';
import { SJAccount } from '../store/sales-journal-slice';

interface SalesJournalExtendedModalProps {
    show: boolean;
    hideModal: () => void;
    extendedItem: salesJournalInterfaces.ExtendedItem;
    extendedItemIndex: number;
    // isPaidOut: boolean;
    adminMode?: boolean;
    companyID: string;
    submitHandler: (extendedItem: salesJournalInterfaces.ExtendedItem, extendedItemIndex: number) => void;
    deleteExtendedItemHandler: (extendedItemIndex: number) => void;
    account?: string;
}

const GetAccountDesc = (account?: SJAccount) => {
    if (!account) return '';
    return `${account.Account} ${account.Description}`;
}

const SalesJournalExtendedModal = ({ ...props }: SalesJournalExtendedModalProps) => {
    const dispatch = useAppDispatch();

    const [values, setValues] = React.useState({
        accountID: '',
        desc: '',
        accountHelpText: '',
        notes: '',
        total: NaN,
        totalString: '',
    });
    const { accounts, accountLookup, accountsLoaded } = useAppSelector(state => state.salesJournal);

    React.useEffect(() => {
        if (!accountsLoaded) {
            dispatch(getSalesJournalAccounts());
        }
    }, [accountsLoaded, dispatch]);


    React.useEffect(() => {
        if (props.show && props.extendedItem !== undefined && props.extendedItem !== null) {
            let accountID = props.extendedItem.AccountID;
            let desc = props.extendedItem.AccountDesc;
            if (props.account) {
                const account = accounts.find(acct => acct.Account === props.account && acct.CompanyID === props.companyID);
                accountID = account?.ID ?? '';
                desc = GetAccountDesc(account);
            }
            const helpText = accountLookup[accountID]?.HelpText ?? '';
            setValues({
                accountID: accountID,
                desc: desc,
                accountHelpText: helpText,
                notes: props.extendedItem.Notes,
                total: props.extendedItem.Total !== 0 ? props.extendedItem.Total : NaN,
                totalString: props.extendedItem.Total !== 0 ? props.extendedItem.Total.toString() : '',
            });
        }
    }, [props.extendedItem, props.show, props.account, props.companyID, accountLookup, accounts]);


    // Only matters for stores.
    // !!!! 400 codes can only be paid outs !!!
    // !!!! 300 codes can only be other income !!!
    const accountOptionElements = accounts?.filter(account => {
        // if (props.storeNum === 310 ? !account.AllowLP : !account.Allow) return false;
        if (props.companyID !== account.CompanyID) return false;
        // Admins can see all accounts.
        if (props.adminMode) return true;
        // Hide accounts that are just for admins.
        if (account.AdminOnly) return false;
        if (props.extendedItem.IsPaidOut) {
            if (!account.Account.startsWith('4') && !account.Account.startsWith('2')) return false;
        }
        return true;
        // if (props.storeNum === 310) {
        //     return account.AllowLP;
        // }
        // return account.Allow;
    }).map(account => (
        <option key={account.ID} value={account.ID}>{GetAccountDesc(account)}</option>
    ));

    const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const name = sourceInfo.event.target.name;
        setValues(prevValues => {
            return {
                ...prevValues,
                [name]: values.floatValue,
                [`${name}String`]: values.value,
            };
        });
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const flipSign = accountLookup[values.accountID].FlipSign;
        let isPaidOut = props.extendedItem.IsPaidOut
        if (props.adminMode) {
            isPaidOut = values.total >= 0;
            if (flipSign) {
                isPaidOut = !isPaidOut;
            }
        }
        const data: salesJournalInterfaces.ExtendedItem = {
            ...props.extendedItem,
            AccountID: values.accountID,
            AccountDesc: values.desc,
            Notes: values.notes,
            Total: values.total,
            FlipSign: flipSign,
            IsPaidOut: isPaidOut,
        };
        props.submitHandler(data, props.extendedItemIndex);
        props.hideModal();
    };

    const selectAccountChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value, selectedIndex } = event.target;
        const selectedAccount = accountLookup[value];
        setValues(prevValues => {
            return {
                ...prevValues,
                accountID: value,
                desc: event.target[selectedIndex].innerText,
                accountHelpText: selectedAccount?.HelpText ?? '',
            };
        });
        // setStore({ id: value, number: Number(event.target[selectedIndex].innerText) });
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValues(prevValues => {
            return {
                ...prevValues,
                [name]: value
            };
        });
    };

    const validateInput = () => {
        if (!values.notes.trim()) {
            return false;
        }
        if (!values.accountID || !values.desc) {
            return false;
        }
        if (!values.total) {
            return false;
        }
        return true;
    };

    let title = props.extendedItem.IsPaidOut ? 'Paid Out' : 'Other Income';
    let notesHeader = props.extendedItem.IsPaidOut ? 'Notes (What was paid for?):' : 'Notes (What was sold?):'
    if (props.adminMode) {
        title = 'Paid Out / Other Income'
        notesHeader = 'Notes'
    }

    return (
        <Modal
            show={props.show}
            onHide={props.hideModal}
            backdrop='static'
            centered
        // animation={false}
        >
            <Form onSubmit={submit}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Deposits for {deposit?.Day} ({deposit?.DateLocal.toLocaleDateString()})</Modal.Title> */}
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className='mb-2'>
                        <InputGroup.Text className='' id=''>Account</InputGroup.Text>
                        {/* <Form.Label className='fs-5' htmlFor="account">Account</Form.Label> */}
                        <Form.Select
                            id='accountID'
                            name='accountID'
                            value={values.accountID}
                            // className='mb-2'
                            // className='text-end'
                            onChange={selectAccountChangeHandler}
                            // disabled={userValue && userValue.Stores && userValue.Stores.length > 1 ? false : true}
                            autoFocus
                            disabled={props.account !== undefined}
                        >
                            <option key="-" value="-"></option>
                            {accountOptionElements}
                            {/* {accountOptionElements} */}
                        </Form.Select>
                    </InputGroup>
                    {
                        values.accountHelpText !== '' &&
                        <Container className='mt-2 mb-3'>
                            <Col>
                                {/* <Row>
                                    <div className=''>Account Description</div>
                                </Row> */}
                                <Row className='text-bg-warning border rounded border-1 bg-opacity-50'>
                                    {/* If we need better formatting, check out https://github.com/remarkjs/react-markdown */}
                                    <div className='my-2 pre-wrap'>{values.accountHelpText}</div>
                                </Row>
                            </Col>
                        </Container>
                    }
                    <Form.Group className='mb-3'>
                        <Form.Label className='ms-2 mb-1 ' htmlFor='notes'>{notesHeader}</Form.Label>
                        {/* <InputGroup className='mb-2'>
                        <InputGroup.Text id=''>What was purchased?</InputGroup.Text> */}
                        <Form.Control
                            id='notes'
                            name='notes'
                            as='textarea'
                            value={values.notes}
                            onChange={handleNotesChange}
                            maxLength={200}
                            rows={3}
                            autoFocus={props.account !== undefined}
                        />
                        {/* </InputGroup> */}
                    </Form.Group>
                    <InputGroup className='mb-2'>
                        <InputGroup.Text className='' id=''>Total</InputGroup.Text>
                        {/* <Form.Label className='fs-5' htmlFor='total'>Total</Form.Label> */}
                        <Form.Control
                            as={NumericFormat}
                            className={`text-end ${values.total < 0 ? 'text-danger' : ''}`}
                            // className='text-end'
                            id='total'
                            name='total'
                            value={values.totalString}
                            onValueChange={handleChange}
                            type='text'
                            // prefix={props.isPaidOut ? '$ -' : '$ '}
                            prefix='$ '
                            thousandSeparator=','
                            allowNegative={props.adminMode}
                            decimalScale={2}
                            // Forces tailing decimal zeros. Causes problems with entering ".86" on a controlled component as of version 5.1.4. Hopefully, they will fix in the future.
                            fixedDecimalScale={false}
                            // autoFocus
                            valueIsNumericString
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    {/* Only show the delete button for existing records. */}
                    {props.extendedItemIndex >= 0 &&
                        <Button
                            className='me-auto'
                            variant='danger'
                            size='lg'
                            tabIndex={-1}
                            onClick={() => {
                                props.deleteExtendedItemHandler(props.extendedItemIndex);
                                props.hideModal();
                            }}
                        >
                            Delete
                        </Button>
                    }
                    <Button
                        variant='secondary'
                        size='lg'
                        onClick={props.hideModal}
                        tabIndex={-1}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!validateInput()}
                        type='submit'
                        size='lg'
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SalesJournalExtendedModal;