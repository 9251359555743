import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './App.css';
// import Login2 from './components/Login2';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
// import DepositEntry from './deposits/DepositEntry';
// import VerifyCreditTotals from './deposits/VerifyCreditTotals';
// import VerifyDeposits from './deposits/VerifyDeposits';
import DepositTabs from './deposits/DepositTabs';
import ErrorElement from './ErrorElement';
import SalesJournalEntry from './sales-journal/SalesJournalEntry';
import SalesJournalExport from './sales-journal/SalesJournalExport';
import SalesJournalSummary from './sales-journal/SalesJournalSummary';
import DepositEntry from './deposits/DepositEntry';
import AboutComponent from './components/About';
// import { useAppDispatch } from './hooks/use-redux';
// import Cookies from 'js-cookie';

const router = createBrowserRouter(
  createRoutesFromElements(
    // https://www.youtube.com/watch?v=oUZjO00NkhY&t=196s
    <Route element={<Layout />} errorElement={<ErrorElement />} >
      <Route element={<RequireAuth allowedRoles={['PFC', 'acct payable', 'comptroller', 'superUser']} />} errorElement={<ErrorElement />} >
        <Route path='/dailyLogSummary' element={<DepositTabs />} />
        <Route path='/salesJournal' element={<SalesJournalSummary />} />
        <Route path='/salesJournalExport' element={<SalesJournalExport />} />
      </Route>
      <Route element={<RequireAuth allowedRoles={['supervisor', 'manager', 'comptroller', 'superUser']} />} errorElement={<ErrorElement />} >
        <Route path='/dailyLog' element={<SalesJournalEntry />} />
        <Route path='/dailyLogClassic' element={<DepositEntry />} />
        <Route path='/salesJournalEntry' element={<SalesJournalEntry />} />
        {/* <Route 
          path='/editSalesJournal/:storeId/:date' 
          loader={({params}) => {
            return `${params.storeId} and ${params.date}`
          }}
          element={<EditSalesJournal />} 
        /> */}
      </Route>
      <Route element={<RequireAuth allowedRoles={['superUser']} />} errorElement={<ErrorElement />} >
        <Route path='/about' element={<AboutComponent />} />
      </Route>
      <Route element={<RequireAuth allowAll />} errorElement={<ErrorElement />} >
        <Route path="*" element={<h2>404 Not Found</h2>} />
      </Route>
    </Route>
  )
);

function App() {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // TODO: Lazy loading?
  // React.useEffect(() => {
  //   const token = Cookies.get('token');
  //   if (!token) {
  //     return
  //   }
  //   const base64Payload = token.split('.')[1];
  //   const payload = JSON.parse(window.atob(base64Payload));
  //   console.log(payload);
  //   dispatch(authActions.login(payload.user))
  // }, [dispatch])

  // React.useEffect(() => {
  // }, []);

  return (
    <div className='App d-flex flex-column'>
      <RouterProvider router={router} />
    </div>
  )

  // return (
  //   <div className='App d-flex flex-column'>
  //     {/* https://www.youtube.com/watch?v=oUZjO00NkhY&t=196s */}
  //     <Routes>
  //       <Route element={<Layout />} >
  //         {/* <Route path='/' element={<h1>Welcome to the LMC App!</h1>} /> */}
  //         {/* index = show when url is equal to parent route */}
  //         {/* <Route index element={<h1>Welcome to the LMC App!</h1>} /> */}
  //         <Route element={<RequireAuth allowedRoles={['PFC', 'acct payable', 'comptroller', 'superUser']} />} >
  //           <Route path='/dailyLogSummary' element={<DepositTabs />} />
  //         </Route>
  //         <Route element={<RequireAuth allowedRoles={['supervisor', 'manager', 'comptroller', 'superUser']} />} >
  //           <Route path='/dailyLog' element={<DepositEntry />} />
  //           <Route path='/salesJournalEntry' element={<SalesJournalEntry />} />
  //           <Route path='/editSalesJournal/:storeId/:date' element={<EditSalesJournal />} />
  //         </Route>
  //         {/* <Route path='/unauthorized' element={<p>401 Unauthorized</p>} /> */}
  //         <Route element={<RequireAuth allowAll />} >
  //           <Route path="*" element={<h2>404 Not Found</h2>} />
  //         </Route>
  //       </Route>
  //       {/* <Route path='/login' element={<Login2 />}></Route> */}
  //     </Routes>
  //   </div>
  // );
}

export default App;
