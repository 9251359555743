import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { salesJournalActions } from './sales-journal-slice';
import { GOAPIPATH } from '../constants';

export const getSalesJournalAccounts = () => {
    return async (dispatch: React.Dispatch<AnyAction>) => {
        const fetchAccounts = async () => {
            // axios
            const response = await axios.get(
                `${GOAPIPATH}/salesJournalAccounts`,
                {
                    withCredentials: true
                }
            );
            return response.data;
        };

        try {
            const responseData = await fetchAccounts();
            dispatch(salesJournalActions.updateAccounts(responseData['accounts']));
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log(error);
            }
        }
    };
};

export const getCompanies = () => {
    return async (dispatch: React.Dispatch<AnyAction>) => {
        const fetchCompanies = async () => {
            // axios
            const response = await axios.get(
                `${GOAPIPATH}/companies`,
                {
                    withCredentials: true
                }
            );
            return response.data;
        };

        try {
            const responseData = await fetchCompanies();
            dispatch(salesJournalActions.updateCompanies(responseData['companies']));
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log(error);
            }
        }
    };
};