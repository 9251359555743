import { createSlice } from '@reduxjs/toolkit';

export interface Bank {
    ID: string;
    Name: string;
}

interface BanksState {
    all: Bank[];
}

const initialState: BanksState = {
    all: [],
}

const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        update(state, action) {
            state.all = action.payload;
        },
        clear(state) {
            state.all = [];
        }
    }
});

export const banksActions = banksSlice.actions;

export default banksSlice;