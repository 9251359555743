import { AnyAction } from '@reduxjs/toolkit';
import axios from 'axios';
// import Cookies from 'js-cookie';
import { Dispatch } from 'react';

import { User, authActions } from './auth-slice';
import { GOAPIPATH } from '../constants';

export const getApiToken = (googleCode: string) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        const fetchToken = async () => {

            // axios
            const response = await axios.post(
                `${GOAPIPATH}/login`,
                JSON.stringify({
                    // clientId: '1009837764622-svs1ckfgvijfk82mpfre1vsqqos5b127.apps.googleusercontent.com',
                    token: googleCode
                }),
                {
                    withCredentials: true
                }
            );
            return response.data;

            // fetch
            // const response = await fetch(
            //     `${GOAPIPATH}/login`,
            //     {
            //         method: 'POST',
            //         body: JSON.stringify({ 
            //             // clientId: '1009837764622-svs1ckfgvijfk82mpfre1vsqqos5b127.apps.googleusercontent.com',
            //             token: googleCode 
            //         }),
            //         credentials: 'same-origin'
            //     }
            // );

            // if (!response.ok) {
            //     throw new Error('Unable to obtain API token.');
            // }
            // console.log(response)
            // const data = await response.json();
            // return data;
        };

        try {
            const tokenData = await fetchToken();
            const base64Payload = tokenData.lmcToken.split('.')[1];
            const payload: {user: User} = JSON.parse(window.atob(base64Payload));
            dispatch(authActions.login(payload.user));
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log(error);
            }
        }
    };
};
