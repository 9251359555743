import { PYTHONFLASKPATH } from './constants';

export const redirectToLogin = () => {
    const loginURL = new URL(`${PYTHONFLASKPATH}/loginRedirect`);
    loginURL.searchParams.append('next_url', window.location.href);
    window.location.href = loginURL.href;
}

export const dayNameDateFormatter = new Intl.DateTimeFormat('en-us', {weekday: 'long'});
export const monthYearDateFormatter = new Intl.DateTimeFormat('en-us', {month: 'long', year: 'numeric'});
export const monthNameDateFormatter = new Intl.DateTimeFormat('en-us', {month: 'long'});
export const dateFormatter = new Intl.DateTimeFormat('en-us');
export const shortDateTimeFormatter = new Intl.DateTimeFormat('en-us', { dateStyle: 'short', timeStyle: 'short' });

export function goDate(date: Date) {
    return date.toISOString().split('T')[0];
}

export const numberFormatter2 = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
export const numberFormatter0 = new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 });
// export const golangDateFormatter = new Intl.DateTimeFormat('en-us', {year:'numeric', month:'2-digit', day:'2-digit'});

/**
 * This function returns the full month name for the given, 0 based, month number.
 * @param {number} month - Month number is zero based. January is 0, February is 1, etc.
 * @returns {string} The month name.
 */
// Month number is zero based. January is 0, February is 1, etc.
export const getMonthName = (month: number) => {
    const date = new Date();
    date.setMonth(month);
    return monthNameDateFormatter.format(date);
}