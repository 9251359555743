// import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
// import { GOAPIPATH } from '../constants';
// import useAxiosPost from '../hooks/use-axios-post';

interface SalesJournalLockButtonProps {
    month?: Date,
    locked: boolean;
    allowToggle: boolean;
    submitLockedState: (locked: boolean) => Promise<void>;
}

const SalesJournalLockButton = ({...props}: SalesJournalLockButtonProps) => {
    
    // const [axiosPostLockedState] = useAxiosPost(undefined);

    // const submitLockedState = React.useCallback(async (locked: boolean) => {
    //     if (!props.month) return;
    //     const data = {
    //         month: props.month.toISOString(),
    //         locked: locked
    //     };
    //     const error = await axiosPostLockedState(
    //         `${GOAPIPATH}/lockSalesJournal`,
    //         JSON.stringify(data),
    //         {
    //             withCredentials: true
    //         }
    //     )
    //     if (error !== null) {
    //         console.log('Failed to toggle locked state. The following error was generated:', error);
    //     }
    // }, [axiosPostLockedState, props.month]);

    // const toggleLocked = () => {
    //     submitLockedState(!props.locked);
    //     if (props.callback) props.callback();
    // };

    return (
        <Row className='justify-content-end align-items-center'>
            <Col className='px-0 fs-4 text-end'>
               {props.locked ? 'LOCKED' : 'UNLOCKED'}
            </Col>
            <Col className='' xs='auto' >
                {
                    props.locked ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                        </svg>
                }
            </Col>
            {
                props.allowToggle &&
                <Col className='ps-1' xs='auto'>
                    <Button variant='primary' onClick={() => props.submitLockedState(!props.locked)}>
                        {props.locked ? 'Unlock' : 'Lock'}
                    </Button>
                </Col>
            }
        </Row>
    );
};

export default SalesJournalLockButton;