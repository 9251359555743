import Cookies from "js-cookie";
import React from 'react';
import { Outlet } from "react-router-dom";
import { DEV_USER } from '../constants';
import { redirectToLogin } from '../helpers';
import { useAppDispatch, useAppSelector } from "../hooks/use-redux";
import { getApiToken } from '../store/auth-actions';
import { authActions } from "../store/auth-slice";

interface Props {
    allowedRoles?: string[];
    allowAll?: boolean;
}

const RequireAuth = ({ allowedRoles, allowAll }: Props) => {
    const dispatch = useAppDispatch();
    const token = Cookies.get('token');
    if (!token) {
        const google_token = Cookies.get('google_token');
        if (!google_token && !DEV_USER) {
            // Without a token or a google token, redirect to login screen.
            redirectToLogin();
        } else dispatch(getApiToken(google_token ?? "DEV_USER"));
    }
    const user = useAppSelector(state => state.auth.user);
    if (token && !user.Email) {
        const base64Payload = token.split('.')[1];
        const payload = JSON.parse(window.atob(base64Payload));
        dispatch(authActions.login(payload.user));
    }

    const logged_in = (token && user.Email);
    const allowed = allowAll || (user.Permissions && user.Permissions.find(role => allowedRoles?.includes(role)));

    return (
        <React.Fragment>
            {!logged_in && <h2>Loading...</h2>}
            {logged_in && !allowed && <h2>403 Forbidden</h2>}
            {logged_in && allowed && <Outlet />}
        </React.Fragment>
    );
};

export default RequireAuth;