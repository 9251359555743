import { createSlice } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";
// import { COOKIEDOMAIN } from '../constants';

export interface StoreInterface {
    ID: string;
    Number: number;
    CompanyID: string;
}
export const defaultStoreInterface = {
    ID: '',
    Number: NaN,
    CompanyID: '',
};

export interface User {
    ID?: string;
    First?: string;
    Last?: string;
    Email?: string;
    Active?: boolean;
    Permissions?: string[];
    Stores?: StoreInterface[];
}

interface AuthState {
    user: User;
    storeLookup: { [storeID: string]: StoreInterface; };
}

const initialState: AuthState = {
    user: {},
    storeLookup: {},
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            const newUser: User = action.payload;
            state.user = newUser;
            let newStoreLookup: { [storeID: string]: StoreInterface; } = {};
            state.storeLookup = newUser.Stores?.reduce((group, store) => {
                group[store.ID] = store;
                return group;
            }, newStoreLookup) ?? {};

        },
        logout(state) {
            // const localCookies = Cookies.withAttributes({path:'/', domain: COOKIEDOMAIN})
            // localCookies.remove('token');
            // localCookies.remove('google_token');
            state.user = {};
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice;