import { AnyAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Dispatch } from 'react';

import { banksActions } from './banks-slice';
import { GOAPIPATH } from '../constants';

export const getBanks = () => {
    return async (dispatch: Dispatch<AnyAction>) => {
        const fetchBanks = async () => {
            // axios
            const response = await axios.get(
                `${GOAPIPATH}/banks`,
                {
                    withCredentials: true
                }
            );
            return response.data;
        };

        try {
            const banksData = await fetchBanks();
            dispatch(banksActions.update(banksData['banks']));
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log(error);
            }
        }
    };
};
