import React from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import MonthYearSelector, { FirstDay, FirstDayUTC, LastDay } from '../components/MonthYearSelector';
import StoreSelector from '../components/StoreSelector';
import { GOAPIPATH } from '../constants';
import { SJType } from '../enums';
import { dayNameDateFormatter, dateFormatter, numberFormatter2, numberFormatter0, monthYearDateFormatter } from '../helpers';
import useAxiosGet from '../hooks/use-axios-get';
import useAxiosPost from '../hooks/use-axios-post';
import { useAppSelector } from '../hooks/use-redux';
import useSJLockedState from '../hooks/use-SJLockedState';
import { SJAccount } from '../store/sales-journal-slice';
import { daySubmitted, itemTotalBase, itemTotalSummable } from './sales-journal-helpers';
import SalesJournalEditModal from './SalesJournalEditModal';
import * as salesJournalInterfaces from './SalesJournalInterfaces';

interface SJRowValues {
    KT2J: {
        Sales: number;
    };
    LaPalapa: {
        FoodSales: number;
        LiquorSales: number;
        BeerSales: number;
        WineSales: number;
        FoodComps: number;
        LiquorComps: number;
        TaxCollected: number;
        GiftCards: number;
    };
    CC: number;
    Deposit: number;
    // Sales: number;
    OverShort: number;
    // PaidOut: number;
    // OtherIncome: number;
    AccountTotals: salesJournalInterfaces.Day['AccountTotals'];
    Sigma: number;
}
const SJRowValuesDefault: SJRowValues = {
    // Sales: 0,
    KT2J: {
        Sales: 0,
    },
    LaPalapa: {
        FoodSales: 0,
        LiquorSales: 0,
        BeerSales: 0,
        WineSales: 0,
        FoodComps: 0,
        LiquorComps: 0,
        TaxCollected: 0,
        GiftCards: 0,
    },
    CC: 0,
    Deposit: 0,
    OverShort: 0,
    // PaidOut: 0,
    // OtherIncome: 0,
    AccountTotals: {},
    Sigma: 0,
};

interface WeekTableProps {
    runningTotals: SJRowValues;
    storeNum: number;
    extendedItemColumns: SJAccount[];
}

const WeekTable = ({ ...props }: React.PropsWithChildren<WeekTableProps>) => {
    const extendedItemHeaderElements = props.extendedItemColumns.map(column => (
        <th key={column.ID}>{column.Account}<br />{column.Description}</th>
    ));
    const extendedItemTotalElements = props.extendedItemColumns.map(column => (
        <SJCell key={column.ID} tableHeader value={props.runningTotals.AccountTotals[column.ID] ?? 0} formatter={numberFormatter2} showZero />
    ));
    return (
        <Table className='p-0 m-0' bordered hover responsive size='sm'>
            <thead>
                <tr className='text-end text-nowrap bg-secondary bg-opacity-50'>
                    <th></th>
                    <th className='text-center'>Date</th>
                    <th>Credit Card</th>
                    <th>Deposit</th>
                    {
                        props.storeNum !== 310
                            ?
                            <th>Sales & Tax</th>
                            :
                            <>
                                <th>Food Sales</th>
                                <th>Liquor Sales</th>
                                <th>Beer Sales</th>
                                <th>Wine Sales</th>
                                <th>Food Comps</th>
                                <th>Liquor Comps</th>
                                <th>Sales Tax</th>
                                <th>Gift Cards</th>
                            </>
                    }
                    <th>Over/Short</th>
                    {/* <th>Paid Out</th>
                    <th>Other Income</th> */}
                    {extendedItemHeaderElements}
                    <th className='text-center'>Σ</th>

                    {/* <th className='text-center'>Day</th> */}
                    {/* <th>Credit Card 1</th>
                    <th>Credit Card 2</th>
                    <th>Deposit AM</th>
                    <th>Deposit PM</th>
                    <th>Sales & Tax</th>
                    <th>Over/Short</th> */}
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
            <tfoot>
                <tr className='text-end text-nowrap table-secondary'>
                    <th>{ }</th>
                    <th className='text-end'>{'Running Total'}</th>
                    <SJCell tableHeader value={props.runningTotals.CC} formatter={numberFormatter2} showZero />
                    <SJCell tableHeader value={props.runningTotals.Deposit} formatter={props.storeNum === 310 ? numberFormatter2 : numberFormatter0} showZero />
                    {
                        props.storeNum !== 310
                            ?
                            <SJCell tableHeader value={props.runningTotals.KT2J.Sales} formatter={numberFormatter2} showZero />
                            :
                            <>
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.FoodSales} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.LiquorSales} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.BeerSales} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.WineSales} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.FoodComps} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.LiquorComps} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.TaxCollected} formatter={numberFormatter2} showZero />
                                <SJCell tableHeader value={props.runningTotals.LaPalapa.GiftCards} formatter={numberFormatter2} showZero />
                            </>
                    }
                    <SJCell tableHeader value={props.runningTotals.OverShort} formatter={numberFormatter2} showZero />
                    {/* <SJCell tableHeader value={props.runningTotals.PaidOut} formatter={numberFormatter2} />
                    <SJCell tableHeader value={props.runningTotals.OtherIncome} formatter={numberFormatter2} /> */}
                    {extendedItemTotalElements}
                    <SJCell tableHeader value={props.runningTotals.Sigma} formatter={numberFormatter2} />
                </tr>
            </tfoot>
        </Table>
    );
};

interface SJCellProps {
    // value: number;
    // accepted: boolean;
    className?: string;
    value: number;
    originalValue?: number;
    formatter: Intl.NumberFormat;
    tableHeader?: boolean;
    showZero?: boolean;
    // locked?: boolean;
}

const SJCell = ({ ...props }: SJCellProps) => {
    const displayValue = props.value !== 0 || props.showZero ? props.formatter.format(props.value) : '';
    const classes = `${props.className ? props.className : ''} ${props.value < 0 ? 'text-danger' : 'text-dark'}`;
    if (props.tableHeader) {
        return (
            <th className={classes}>
                {displayValue}
            </th>
        );
    }
    return (
        <td className={classes}>
            {/* {props.originalValue &&
                <span className='text-dark'>
                    <del className={props.originalValue < 0 ? 'text-danger' : 'text-dark'}>{`${displayOriginalValue}`}</del>
                    {' --> '}
                </span>
            } */}
            {displayValue}
            {props.originalValue && <span className='fw-bold text-dark'> *</span>}
        </td>
    );
};

interface SJDayRowProps {
    data: salesJournalInterfaces.Day;
    rowValues: SJRowValues;
    rowClickHandler: () => void;
    storeNum: number;
    isAdjustment?: boolean;
    extendedItemColumns: SJAccount[];
}

const SJDayRow = ({ ...props }: SJDayRowProps) => {
    if (!props.data) {
        return <React.Fragment></React.Fragment>;
    }

    const overShortItem = props.data.Items[SJType.OVERSHORT] ?? undefined;
    const submitted = daySubmitted(props.data.Items, props.isAdjustment);
    const accepted = overShortItem?.Accepted ?? false;
    const hasNote = (overShortItem && overShortItem.Notes !== '') ?? false;
    // const ccLocked = props.data.Items[SJType.CREDIT_CARD_1]?.Accepted;
    // const locked = ccLocked
    //     && props.data.Items[SJType.AM_DEPOSIT]?.Accepted
    //     && props.data.Items[SJType.PM_DEPOSIT]?.Accepted;

    const sigma = props.rowValues.Sigma; //Math.round(props.data.Sigma * 100) / 100;

    // const ccTotal = itemTotalSummable(props.data.Items[SJType.CREDIT_CARD_1]) + itemTotalSummable(props.data.Items[SJType.CREDIT_CARD_2]);
    // const depositTotal = itemTotalSummable(props.data.Items[SJType.AM_DEPOSIT]) + itemTotalSummable(props.data.Items[SJType.PM_DEPOSIT]);

    // let taxExemptTotal = 0;
    const extendedItemTotalElements = props.extendedItemColumns.map(column => {
        const total = props.data.AccountTotals[column.ID] ?? null;
        // if (column.Account === '305') {
        //     taxExemptTotal = total;
        // }
        return (
            <SJCell key={column.ID} value={total ?? 0} formatter={numberFormatter2} showZero={total !== null} />
        );
    });

    const rowClasses = `text-end ${submitted || props.isAdjustment ? 'cursor-pointer' : ''} ${(sigma !== 0 || (submitted && !accepted) ? 'bg-warning bg-opacity-50 bg-gradient' : ' bg-light')}`;
    return (
        <tr className={rowClasses} onClick={submitted || props.isAdjustment ? props.rowClickHandler : undefined}>
            <td className={`text-center ${hasNote ? ' corner-cut' : ''}`}>{sigma !== 0 ? '❗' : accepted ? '✅' : ''}</td>
            <td className='text-center'>{props.data.DateDisplay}</td>
            <SJCell value={props.rowValues.CC} formatter={numberFormatter2} showZero={submitted} />
            <SJCell value={props.rowValues.Deposit} formatter={props.storeNum === 310 ? numberFormatter2 : numberFormatter0} showZero={submitted} />

            {/* <SJCell value={foodSales} formatter={numberFormatter2} showZero={submitted} /> */}
            {
                props.storeNum !== 310
                    ?
                    <SJCell value={props.rowValues.KT2J.Sales} formatter={numberFormatter2} showZero={submitted} />
                    :
                    <>
                        <SJCell value={props.rowValues.LaPalapa.FoodSales} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.LiquorSales} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.BeerSales} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.WineSales} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.FoodComps} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.LiquorComps} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.TaxCollected} formatter={numberFormatter2} showZero={submitted} />
                        <SJCell value={props.rowValues.LaPalapa.GiftCards} formatter={numberFormatter2} />
                    </>
            }
            {/* Indicate if over/short has been modified */}
            <SJCell value={itemTotalSummable(overShortItem)} originalValue={overShortItem?.IsOverride ? overShortItem.Total : undefined} formatter={numberFormatter2} showZero={submitted} />
            {/* <SJCell value={props.data.PaidOutTotal} formatter={numberFormatter2} /> */}
            {/* <SJCell value={props.data.OtherIncomeTotal} formatter={numberFormatter2} /> */}
            {extendedItemTotalElements}
            <SJCell value={sigma} formatter={numberFormatter2} />
        </tr>
    );
};

const SalesJournalSummary = () => {
    const [salesJournalData, setSalesJournalData] = React.useState<{ [dateString: string]: salesJournalInterfaces.Day; }>({});
    const [tipData, setTipData] = React.useState<{ [dateString: string]: salesJournalInterfaces.Tips; }>({});
    const [extendedItemColumns, setExtendedItemColumns] = React.useState<SJAccount[]>([]);
    const [sjAdjustment, setSJAdjustment] = React.useState<salesJournalInterfaces.Day>({
        Date: 'Date',
        DateDisplay: '[MONTH] [YEAR]',
        Day: 'Day',
        PaidOutTotal: 0,
        OtherIncomeTotal: 0,
        AccountTotals: {},
        Sigma: 0,
        Items: {},
        ItemsExtended: [],
    });
    const [weekDays, setweekDays] = React.useState<salesJournalInterfaces.DateKeyData[][]>([]);
    // const [depositData, setDepositData] = React.useState<DepositRowData[]>([]);
    const [dateKey, setDateKey] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showAdj, setShowAdj] = React.useState(false);
    // const [isLocked, setIsLocked] = React.useState(true);

    // const [monthYearData, setMonthYearData] = React.useState<MonthYearData>({});
    const selectedMonth = useAppSelector(state => state.selectors.selectedMonth);
    const selectedYear = useAppSelector(state => state.selectors.selectedYear);
    const [isLocked, lockButtonElement] = useSJLockedState(false, FirstDayUTC(selectedYear, selectedMonth));
    // const [monthYearData, setMonthYearData] = React.useState<MonthYearData>({
    //     years: [2023],
    //     selectedYear: 2023,
    //     // months: [
    //     //     { number: 1, name: 'January' },
    //     //     { number: 2, name: 'February' },
    //     //     { number: 3, name: 'March' },
    //     //     { number: 4, name: 'April' },
    //     //     { number: 5, name: 'May' },
    //     //     { number: 6, name: 'June' },
    //     //     { number: 7, name: 'July' },
    //     //     { number: 8, name: 'August' },
    //     //     { number: 9, name: 'September' },
    //     //     { number: 10, name: 'October' },
    //     //     { number: 11, name: 'November' },
    //     //     { number: 12, name: 'December' },
    //     // ],
    //     selectedMonth: 1,
    // });

    // const userValue = useAppSelector(state => state.auth.user);
    const store = useAppSelector(state => state.selectors.selectedStore);
    // const [store, setStore] = React.useState<StoreInterface>({
    //     ID: '',
    //     Number: 0,
    //     CompanyID: '',
    // });

    // React.useEffect(() => {
    //     const currentDate = new Date();
    //     let year = currentDate.getFullYear();
    //     let years = [year];
    //     while (years.length < 8 && year > 2023) {
    //         year -= 1;
    //         years.push(year);
    //     }
    //     setMonthYearData({
    //         years: years,
    //         selectedYear: currentDate.getFullYear(),
    //         selectedMonth: currentDate.getMonth(),
    //     });

    // }, []);

    // const store_filter = React.useMemo(() => ([310]), []);

    // React.useEffect(() => {
    //     if (userValue === undefined || userValue.Stores === undefined || userValue.Stores.length < 1) {
    //         return;
    //     }
    //     // setStore({
    //     //     ID: userValue.Stores[0].ID,
    //     //     Number: userValue.Stores[0].Number,
    //     // });
    //     for (const store of userValue.Stores) {
    //         if (!store_filter.includes(store.Number)) {
    //             setStore({
    //                 ID: store.ID,
    //                 Number: store.Number,
    //                 CompanyID: store.CompanyID,
    //             });
    //             break;
    //         }
    //     }
    // }, [userValue, store_filter]);

    const applyCompanySalesJournalData = React.useCallback((data: {
        sales_journal: salesJournalInterfaces.ItemsRaw[];
        weeks: salesJournalInterfaces.DateKeyData[][];
        sales_journal_extended: salesJournalInterfaces.ExtendedItemRaw[];
        sj_adjustment: salesJournalInterfaces.ItemsRaw[];
        sj_adjustment_extended: salesJournalInterfaces.ExtendedItemRaw[];
        // isLocked: boolean;
        column_headers: SJAccount[];
        tips: salesJournalInterfaces.TipsRaw[];
    }) => {
        // console.log(data.column_headers);
        // const defaultAccountTotals: number[] = data.column_headers ? new Array(data.column_headers.length).fill(0) : [];
        if (data.tips) {
            let tips: { [dateString: string]: salesJournalInterfaces.Tips; } = {};
            tips = data.tips.reduce((group, tip) => {
                group[tip.DateString] = {
                    Tips1: tip.Tips1,
                    Tips2: tip.Tips2,
                };
                return group;
            }, tips);
            setTipData(tips);
        }

        let sales_journal: { [dateString: string]: salesJournalInterfaces.Day; } = {};
        sales_journal = data.weeks.flat().reduce((group, dk) => {
            const localDate = new Date(dk.DateString);
            group[dk.DateString] = {
                Date: dk.Date,
                DateDisplay: dateFormatter.format(localDate),
                Day: dayNameDateFormatter.format(localDate),
                PaidOutTotal: 0,
                OtherIncomeTotal: 0,
                AccountTotals: {},
                Sigma: 0,
                Items: {},
                ItemsExtended: [],
            };
            return group;
        }, sales_journal);

        if (data.sales_journal_extended) {
            sales_journal = data.sales_journal_extended.reduce((group, extendedItem) => {
                const isPaidOut = (extendedItem.Total > 0 && !extendedItem.FlipSign) || (extendedItem.Total > 0 && !extendedItem.FlipSign);
                group[extendedItem.DateString].ItemsExtended.push({
                    ID: extendedItem.ID,
                    AccountID: extendedItem.AccountID,
                    AccountDesc: extendedItem.AccountDesc,
                    Total: extendedItem.Total,
                    Notes: extendedItem.Notes,
                    FlipSign: extendedItem.FlipSign,
                    IsPaidOut: isPaidOut,
                });
                // if (isPaidOut) {
                //     group[extendedItem.DateString].PaidOutTotal += extendedItem.Total;
                // } else {
                //     group[extendedItem.DateString].OtherIncomeTotal += extendedItem.Total;
                // }
                if (!group[extendedItem.DateString].AccountTotals[extendedItem.AccountID]) {
                    group[extendedItem.DateString].AccountTotals[extendedItem.AccountID] = 0;
                }
                group[extendedItem.DateString].AccountTotals[extendedItem.AccountID] += extendedItem.Total;
                // 305 (Tax-Exempt) is included in sales total.
                if (!extendedItem.AccountDesc.startsWith('305')) {
                    group[extendedItem.DateString].Sigma += extendedItem.Total;
                }
                return group;
            }, sales_journal);
        }

        if (data.sales_journal) {
            sales_journal = data.sales_journal.reduce((group, item) => {
                // const flipSign = (item.Type === SJType.SALES_AND_TAX || item.Type === SJType.OVERSHORT);
                group[item.DateString].Items[item.Type] = {
                    ID: item.ID,
                    Total: item.Total,
                    Override: item.OverrideTotal,
                    IsOverride: item.IsOverride,
                    // Total: flipSign ? item.Total * -1 : item.Total,
                    // Override: flipSign ? item.OverrideTotal * -1 : item.OverrideTotal,
                    Accepted: item.Accepted,
                    Notes: item.Notes,
                };
                group[item.DateString].Sigma += itemTotalBase(item.Total, item.OverrideTotal, item.IsOverride);
                return group;
            }, sales_journal);
        }
        const adjDateData = data.weeks[0][0];
        let adjustment: salesJournalInterfaces.Day = {
            Date: data.weeks[0][0].Date,
            DateDisplay: monthYearDateFormatter.format(new Date(adjDateData.DateString)),
            Day: 'Adjustment', //monthYearDateFormatter.format(new Date(adjDateData.DateString)),
            PaidOutTotal: 0,
            OtherIncomeTotal: 0,
            AccountTotals: {},
            Sigma: 0,
            Items: {},
            ItemsExtended: [],
        };
        if (data.sj_adjustment) {
            adjustment = data.sj_adjustment.reduce((group, item) => {
                group.Items[item.Type] = {
                    ID: item.ID,
                    Total: item.Total,
                    Override: item.OverrideTotal,
                    IsOverride: item.IsOverride,
                    // Total: flipSign ? item.Total * -1 : item.Total,
                    // Override: flipSign ? item.OverrideTotal * -1 : item.OverrideTotal,
                    Accepted: item.Accepted,
                    Notes: item.Notes,
                };
                group.Sigma += itemTotalBase(item.Total, item.OverrideTotal, item.IsOverride);
                return group;
            }, adjustment);
        }
        if (data.sj_adjustment_extended) {
            adjustment = data.sj_adjustment_extended.reduce((group, extendedItem) => {
                const isPaidOut = (extendedItem.Total > 0 && !extendedItem.FlipSign) || (extendedItem.Total > 0 && !extendedItem.FlipSign);
                group.ItemsExtended.push({
                    ID: extendedItem.ID,
                    AccountID: extendedItem.AccountID,
                    AccountDesc: extendedItem.AccountDesc,
                    Total: extendedItem.Total,
                    Notes: extendedItem.Notes,
                    FlipSign: extendedItem.FlipSign,
                    IsPaidOut: isPaidOut,
                });
                // if (isPaidOut) {
                //     group.PaidOutTotal += extendedItem.Total;
                // } else {
                //     group.OtherIncomeTotal += extendedItem.Total;
                // }
                if (!group.AccountTotals[extendedItem.AccountID]) {
                    group.AccountTotals[extendedItem.AccountID] = 0;
                }
                group.AccountTotals[extendedItem.AccountID] += extendedItem.Total;
                // 305 (Tax-Exempt) is included in sales total.
                if (!extendedItem.AccountDesc.startsWith('305')) {
                    group.Sigma += extendedItem.Total;
                }
                return group;
            }, adjustment);
        }
        // console.log(data.date_keys);
        // console.log(sales_journal);
        setExtendedItemColumns(data.column_headers ?? []);
        // setIsLocked(data.isLocked);
        setSJAdjustment(adjustment);
        setweekDays(data.weeks);
        setSalesJournalData(sales_journal);
    }, []);

    // const firstDayOfMonth = React.useCallback(() =>  {
    //     return new Date(Date.UTC(monthYearData.selectedYear, monthYearData.selectedMonth - 1, 1));
    // }, [monthYearData.selectedYear, monthYearData.selectedMonth]);

    // const lastDayOfMonth = React.useCallback(() =>  {
    //     return new Date(Date.UTC(monthYearData.selectedYear, monthYearData.selectedMonth, 0));
    // }, [monthYearData.selectedYear, monthYearData.selectedMonth]);

    const [axiosGetCompanySalesJournal] = useAxiosGet(applyCompanySalesJournalData);

    const getCompanySalesJournal = React.useCallback((storeID: string) => {
        const firstDay = FirstDay(selectedYear, selectedMonth);
        const lastDay = LastDay(selectedYear, selectedMonth);
        if (storeID === '' || firstDay === '' || lastDay === '') {
            setSalesJournalData({});
            return;
        }
        axiosGetCompanySalesJournal(
            `${GOAPIPATH}/salesJournalMonth?storeID=${storeID}&from=${firstDay}&to=${lastDay}`,
            // `${GOAPIPATH}/salesJournalMonth?storeID=${storeID}&from=${goDate(firstDay)}&to=${goDate(lastDay)}`,
            {
                withCredentials: true
            }
        );
    }, [axiosGetCompanySalesJournal, selectedYear, selectedMonth]);

    React.useEffect(() => {
        // getStoreDeposits(store.ID);
        getCompanySalesJournal(store.ID);
        // I don't think this screen needs to auto update since we update when a row is clicked.
        // const intervalId = setInterval(getStoreDeposits(store.ID), 5 * 60 * 1000); // x minute(s) x*60*1000
        // return () => clearInterval(intervalId);
    }, [getCompanySalesJournal, store.ID]);

    // const storeOptionElements = userValue?.Stores?.map(store => (
    //     <option key={store.ID} value={store.ID}>{store.Number}</option>
    // ));

    // const monthOptionElements = monthYearData.months.map(month => (
    //     <option key={month.number} value={month.number}>{month.name}</option>
    // ));

    // const yearOptionElements = monthYearData.years?.map(year => (
    //     <option key={year} value={year}>{year}</option>
    // ));

    const [axiosPostDeposit] = useAxiosPost(undefined);

    const submitHandler = async (salesJournalDay: salesJournalInterfaces.Day, deleteExtendedItems: string[], isMonthlyAdjustment: boolean, tips?: salesJournalInterfaces.Tips) => {
        const submitSalesJournal: {
            id: string;
            type: string;
            override: number;
            isOverride: boolean;
            accepted: boolean;
            notes: string;
        }[] = [];
        for (let key in salesJournalDay.Items) {
            // let signedTotal = salesJournalDay.Items[key].Total;
            // Sales are always negative and we need to flip the sign on over/short.
            // if (key === SJType.SALES_AND_TAX || key === SJType.OVERSHORT) {
            //     signedTotal *= -1;
            // }
            submitSalesJournal.push({
                id: salesJournalDay.Items[key].ID,
                type: key,
                override: salesJournalDay.Items[key].Override,
                isOverride: salesJournalDay.Items[key].IsOverride,
                accepted: salesJournalDay.Items[key].Accepted,
                notes: salesJournalDay.Items[key].Notes,
            });
        }

        let submitSJExtended = salesJournalDay.ItemsExtended.map(extItem => {
            return {
                id: extItem.ID,
                accountID: extItem.AccountID,
                total: extItem.Total,
                notes: extItem.Notes,
            };
        });
        const tipsData = tips ? {
            tips1: tips.Tips1,
            tips2: tips.Tips2,
            valid: true,
        } : undefined;
        const data = {
            date: salesJournalDay.Date,
            store: store.Number,
            isMonthlyAdjustment: isMonthlyAdjustment,
            salesJournal: submitSalesJournal,
            salesJournalExtended: submitSJExtended,
            deleteSalesJournalExtended: deleteExtendedItems,
            tips: tipsData,
        };
        const error = await axiosPostDeposit(
            `${GOAPIPATH}/verifySalesJournal`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        );
        if (error !== null) {
            console.log('Failed to submit daily log. The following error was generated:', error);
            return;
        }
        getCompanySalesJournal(store.ID); // Is this overkill? Should we just try to update the state with the new values?
        if (show) setShow(false);
        if (showAdj) setShowAdj(false);
    };

    const rowClickHandler = (dateKey: string) => {
        getCompanySalesJournal(store.ID);
        setDateKey(dateKey);
        setShow(true);
    };

    const adjustmentRowClickHandler = () => {
        getCompanySalesJournal(store.ID);
        setShowAdj(true);
    };

    // const selectStoreHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { value, selectedIndex } = event.target;
    //     setStore({ id: value, number: Number(event.target[selectedIndex].innerText) });
    // };

    // const selectMonthYearHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { value, name } = event.target;
    //     setMonthYearData(prevState => {
    //         return {
    //             ...prevState,
    //             [name]: value,
    //         };
    //     });
    // };

    // const canLock = userValue.Permissions ? userValue.Permissions.includes('superUser') || userValue.Permissions.includes('comptroller') : false;

    const taxExemptAccountID = extendedItemColumns.find(value => value.Account === '305')?.ID ?? '';

    const addToRunningTotal = (runningTotals: SJRowValues, values: SJRowValues) => {
        if (store.Number !== 310) {
            runningTotals.KT2J.Sales += values.KT2J.Sales;
        } else {
            runningTotals.LaPalapa.FoodSales += values.LaPalapa.FoodSales;
            runningTotals.LaPalapa.LiquorSales += values.LaPalapa.LiquorSales;
            runningTotals.LaPalapa.BeerSales += values.LaPalapa.BeerSales;
            runningTotals.LaPalapa.WineSales += values.LaPalapa.WineSales;
            runningTotals.LaPalapa.FoodComps += values.LaPalapa.FoodComps;
            runningTotals.LaPalapa.LiquorComps += values.LaPalapa.LiquorComps;
            runningTotals.LaPalapa.TaxCollected += values.LaPalapa.TaxCollected;
            runningTotals.LaPalapa.GiftCards += values.LaPalapa.GiftCards;
        }
        runningTotals.CC += values.CC;
        runningTotals.Deposit += values.Deposit;
        runningTotals.OverShort += values.OverShort;
        runningTotals.Sigma += Math.abs(values.Sigma);
        for (const acctID in values.AccountTotals) {
            if (!runningTotals.AccountTotals[acctID]) {
                runningTotals.AccountTotals[acctID] = 0;
            }
            runningTotals.AccountTotals[acctID] += values.AccountTotals[acctID];
        }
    };

    const getSJRowFromDay = (day: salesJournalInterfaces.Day) => {
        let values: SJRowValues = {
            ...SJRowValuesDefault,
            KT2J: { ...SJRowValuesDefault.KT2J },
            LaPalapa: { ...SJRowValuesDefault.LaPalapa },
            AccountTotals: {},
        };
        const taxExempt = day.AccountTotals[taxExemptAccountID] ?? 0;
        if (store.Number !== 310) {
            values.KT2J.Sales = itemTotalSummable(day.Items[SJType.SALES_AND_TAX]) - taxExempt;
        } else {
            values.LaPalapa.FoodSales = itemTotalSummable(day.Items[SJType.FOOD_SALES]) + itemTotalSummable(day.Items[SJType.SHIFT_MEALS]) - taxExempt;
            values.LaPalapa.LiquorSales = itemTotalSummable(day.Items[SJType.LIQUOR_SALES]);
            values.LaPalapa.BeerSales = itemTotalSummable(day.Items[SJType.BEER_SALES]);
            values.LaPalapa.WineSales = itemTotalSummable(day.Items[SJType.WINE_SALES]);
            values.LaPalapa.FoodComps = itemTotalSummable(day.Items[SJType.FOOD_COMPS]);
            values.LaPalapa.LiquorComps = itemTotalSummable(day.Items[SJType.LIQUOR_COMPS]) + itemTotalSummable(day.Items[SJType.BEER_COMPS]) + itemTotalSummable(day.Items[SJType.WINE_COMPS]);
            values.LaPalapa.TaxCollected = itemTotalSummable(day.Items[SJType.FOOD_TAX]) + itemTotalSummable(day.Items[SJType.LIQUOR_TAX]);
            values.LaPalapa.GiftCards = itemTotalSummable(day.Items[SJType.GIFT_CARD_SOLD]) + itemTotalSummable(day.Items[SJType.GIFT_CARD_REDEEMED]);
        }
        values.CC = itemTotalSummable(day.Items[SJType.CREDIT_CARD_1]) + itemTotalSummable(day.Items[SJType.CREDIT_CARD_2]);
        values.OverShort = itemTotalSummable(day.Items[SJType.OVERSHORT]);
        values.Sigma = Math.round(day.Sigma * 100) / 100;
        values.AccountTotals = { ...day.AccountTotals };
        values.Deposit = itemTotalSummable(day.Items[SJType.AM_DEPOSIT]) + itemTotalSummable(day.Items[SJType.PM_DEPOSIT]);
        return values;
    };

    let runningTotals: SJRowValues = {
        ...SJRowValuesDefault,
        KT2J: { ...SJRowValuesDefault.KT2J },
        LaPalapa: { ...SJRowValuesDefault.LaPalapa },
        AccountTotals: {},
    };
    const weekElements = weekDays.map((week, index) => {
        let weeklyDepositTotal = 0;
        const dayRows = week.map(dayKey => {
            const day = salesJournalData[dayKey.DateString];
            const values = getSJRowFromDay(day);
            weeklyDepositTotal += values.Deposit;
            addToRunningTotal(runningTotals, values);
            return (
                <SJDayRow
                    key={dayKey.DateString}
                    data={day}
                    rowValues={values}
                    rowClickHandler={() => rowClickHandler(dayKey.DateString)}
                    storeNum={store.Number}
                    extendedItemColumns={extendedItemColumns}
                />
            );
        });
        return (
            <Container className='mb-3 p-0 border border-2 rounded' key={index}>
                <h4 className='p-3'>
                    <Row>
                        <Col>
                            Week Ending {salesJournalData[week[week.length - 1].DateString].DateDisplay}
                        </Col>
                        <Col className='text-end'>
                            {`Weekly Deposit Total:`}
                        </Col>
                        <Col className=' me-3' xs='auto'>
                            {`${store.Number === 310 ? numberFormatter2.format(weeklyDepositTotal) : numberFormatter0.format(weeklyDepositTotal)}`}
                        </Col>
                    </Row>
                </h4>
                <WeekTable
                    runningTotals={{
                        ...runningTotals,
                        KT2J: { ...runningTotals.KT2J },
                        LaPalapa: { ...runningTotals.LaPalapa },
                        AccountTotals: { ...runningTotals.AccountTotals }
                    }}
                    storeNum={store.Number}
                    extendedItemColumns={extendedItemColumns}
                >
                    {dayRows}
                </WeekTable>
            </Container>
        );
    });

    const adjustmentRowValues = getSJRowFromDay(sjAdjustment);
    addToRunningTotal(runningTotals, adjustmentRowValues);

    return (
        <div className='h-100 d-flex flex-column'>
            <div className='h-100 overflow-auto'>
                <SalesJournalEditModal
                    show={show || showAdj}
                    setShow={show ? setShow : setShowAdj}
                    data={show ? salesJournalData[dateKey] : sjAdjustment}
                    tips={show ? tipData[dateKey] : undefined}
                    storeNum={store.Number}
                    companyID={store.CompanyID}
                    submitHandler={submitHandler}
                    isAdmin={true}
                    isMonthlyAdjustment={showAdj}
                    locked={isLocked}
                />
                <Container className='px-4 mt-3' fluid >
                    <Row>
                        {weekElements}
                        <Container className='mb-3 p-0 border border-2 rounded'>
                            <h4 className='p-3'>Monthly Adjustment</h4>
                            <WeekTable runningTotals={runningTotals} storeNum={store.Number} extendedItemColumns={extendedItemColumns}>
                                <SJDayRow
                                    data={sjAdjustment}
                                    rowValues={adjustmentRowValues}
                                    rowClickHandler={adjustmentRowClickHandler}
                                    storeNum={store.Number}
                                    extendedItemColumns={extendedItemColumns}
                                    isAdjustment
                                />
                            </WeekTable>
                        </Container>
                    </Row>
                </Container>
            </div>
            <Container className='text-bg-secondary' fluid>
                <Row className='py-1 gy-1 d-flex align-items-center'>
                    <Col xs='auto'>
                        <h3>Sales Journal</h3>
                    </Col>
                    <Col xs='auto'>
                        <StoreSelector
                        // filter={store_filter} 
                        />
                    </Col>
                    <MonthYearSelector />
                    <Col className='text-end'>
                        {lockButtonElement}
                        {/* <SalesJournalLockButton locked={isLocked} allowToggle={canLock} month={FirstDayUTC(monthYearData)} callback={() => getCompanySalesJournal(store.ID) }/> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SalesJournalSummary;