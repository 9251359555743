import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../hooks/use-redux';
import { selectorsActions } from '../store/selectors-slice';
import { defaultStoreInterface } from '../store/auth-slice';

interface StoreSelectorProps {
    // store: StoreInterface;
    // setStore: React.Dispatch<React.SetStateAction<StoreInterface>>;
    filter?: number[]; // Store numbers to filter out (do NOT display) Note: Store can still be manually set  to display a filtered value.
}

const StoreSelector = ({ ...props }: StoreSelectorProps) => {
    const userValue = useAppSelector(state => state.auth.user);
    const storeLookup = useAppSelector(state => state.auth.storeLookup);
    const selectedStore = useAppSelector(state => state.selectors.selectedStore);
    const dispatch = useAppDispatch();

    const selectStoreHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        dispatch(selectorsActions.selectStore({ ...storeLookup[value] }));
    };

    const validStores = React.useMemo(() => {
        return props.filter ? userValue?.Stores?.filter(store => !props.filter?.includes(store.Number)) : userValue?.Stores;
    }, [userValue, props.filter]);

    const currentStoreIsValid = React.useMemo(() => {
        if (selectedStore.ID === '') return false;
        return !props.filter || !props.filter.includes(selectedStore.Number);
    }, [props.filter, selectedStore]);

    React.useEffect(() => {
        // Don't do anything if we have a valid current store.
        if (currentStoreIsValid) return;
        // Select the first valid store.
        const firstStore = (validStores && validStores.length > 0) ? { ...validStores[0] } : { ...defaultStoreInterface };
        dispatch(selectorsActions.selectStore(firstStore));
    }, [dispatch, validStores, currentStoreIsValid]);

    const storeOptionElements = validStores?.map(store => (
        <option key={store.ID} value={store.ID}>{store.Number}</option>
    ));

    return (
        <InputGroup>
            <InputGroup.Text>Store #</InputGroup.Text>
            <Form.Select
                className='text-end'
                onChange={selectStoreHandler}
                value={selectedStore.ID}
                disabled={validStores && validStores.length > 1 ? false : true}
            >
                {storeOptionElements}
            </Form.Select>
        </InputGroup>
    );
};

export default StoreSelector;