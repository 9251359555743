import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import React from 'react';
import { redirectToLogin } from '../helpers';

const useAxiosPost = (applyData: ((data: any) => void) | undefined) => {
    // const [isLoading, setIsLoading] = React.useState(false);
    // const [error, setError] = React.useState<string | null>(null);

    const sendRequest = React.useCallback(async (
        url: string,
        data?: any,
        config?: AxiosRequestConfig<any> | undefined
    ) => {
        // setIsLoading(true);
        try {
            const response = await axios.post(
                url,
                data,
                config
            );
            if (applyData !== undefined) applyData(response.data);
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) redirectToLogin(); // console.log('logout from post!');
                console.log('Error posting data:', error)
            }
            return error;
        }

        // setIsLoading(false);
        return null;
    }, [applyData]); // the passed applyData function should be wrapped in React.useCallback (or, applyData should be made a parameter of sendRequest).

    return [
        // isLoading,
        // error,
        sendRequest,
    ];
};

export default useAxiosPost;