export interface DepositDetail {
    Store: number;
    Bank: string;
    Company: string;
    Total: number;
    OverrideTotal: number;
    IsOverride: boolean;
    Submitted: Date;
    SubmittedString: string;
    Accepted: Date;
    AcceptedString: string;
    Status: string;
    Notes: string;
    ID: string;
    BankID: string;
    SJAccepted: boolean;
    HitBankNextMonth: boolean;
}
export const defaultDepositDetail: DepositDetail = {
    Store: 0,
    Bank: '',
    Company: '',
    Total: 0,
    OverrideTotal: 0,
    IsOverride: false,
    Submitted: new Date(),
    SubmittedString: '',
    Accepted: new Date(),
    AcceptedString: '',
    Status: '',
    Notes: '',
    ID: '',
    BankID: '',
    SJAccepted: false,
    HitBankNextMonth: false,
};

export interface DayData {
    Day: string;
    DayNum: number;
    AM: DepositDetail[];
    PM: DepositDetail[];
}

export interface WeekData {
    Days: DayData[];
    EndDate: string;
    BankCompanyTotals: { [bankCompany: string]: number; };
    StoreTotals: { [store: string]: number; };
    Show: boolean; // Should details be shown initially
    ScrollTo: boolean; // Should this week be scrolled to when the component is first loaded
}