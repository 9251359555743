// Next 3 interfaces match return values from GetSalesJournal API call.
export interface ItemsRaw {
    ID: string;
    Date: string; // UTC date string from golang.
    DateLocal: Date; // Local date created from DateString
    DateString: string; // String date from golang. Just year, month, and day.
    Type: string;
    Total: number;
    OverrideTotal: number;
    IsOverride: boolean;
    Accepted: boolean;
    StoreNum: number;
    Notes: string;
}

export interface ExtendedItemRaw {
    ID: string;
    Date: string;
    DateString: string;
    AccountID: string;
    AccountDesc: string;
    Total: number;
    Notes: string;
    FlipSign: boolean;
    StoreNum: number;
}

export interface DateKeyData {
    Date: string;
    DateString: string;
}

// The next three interfaces combine to make up the main SalesJournalEntry data set.
export interface Item {
    ID: string;
    // Type: string;
    Total: number;
    TotalString?: string;
    Override: number;
    OverrideString?: string;
    IsOverride: boolean;
    Accepted: boolean;
    Notes: string;
}
export const defaultItem: Item = {
    ID: '',
    Total: NaN,
    TotalString: '',
    Override: NaN,
    OverrideString: '',
    IsOverride: false,
    Accepted: false,
    Notes: '',
};

export interface ExtendedItem {
    ID: string;
    AccountID: string;
    AccountDesc: string;
    Total: number;
    Notes: string;
    FlipSign: boolean;
    IsPaidOut: boolean;
}
export const defaultExtendedItem: ExtendedItem = {
    ID: '',
    AccountID: '',
    AccountDesc: '',
    Notes: '',
    Total: NaN,
    FlipSign: false,
    IsPaidOut: true,
};

export interface Day {
    Date: string; // UTC date string from golang.
    DateDisplay: string; // Formatted date for display.
    Day: string;
    PaidOutTotal: number;
    OtherIncomeTotal: number;
    AccountTotals: { [accountID: string]: number; };
    Sigma: number; // Sum of all items. Should always be 0.
    Items: { [type: string]: Item; };
    ItemsExtended: ExtendedItem[];
    // PaidOuts: SJExtendedItem[];
    // OtherIncome: SJExtendedItem[];
}

export interface Items {
    Items: { [type: string]: Item; };
    ItemsExtended: ExtendedItem[];
}

export interface CompanyDay {
    Date: string;
    DateDisplay: string;
    Day: string;
    Stores: { [storeNum: number]: Items; };
}

export interface EditDayOrig {
    totalSales: number;
    cc1: number;
    cc2: number;
    am: number;
    pm: number;
    overShort: number;
    accepted: boolean;
    extendedItems: ExtendedItem[];
    deletedExtendedItems: string[]; // IDs of deleted sales journal extended items.
}

export interface EditDay {
    accepted: boolean;
    notes: string;
    items: { [type: string]: Item; };
    extendedItems: ExtendedItem[];
    deletedExtendedItems: string[]; // IDs of deleted sales journal extended items.
}

export interface Tips {
    Tips1: number;
    Tips1String?: string;
    Tips2: number;
    Tips2String?: string;
}

export interface TipsRaw {
    Date: string;
    DateString: string;
    Tips1: number;
    Tips2: number;
}