import React from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import StoreSelector from '../components/StoreSelector';
import { GOAPIPATH } from '../constants';
import { SJType } from '../enums';
import { dayNameDateFormatter, dateFormatter, numberFormatter2, numberFormatter0 } from '../helpers';
import useAxiosGet from '../hooks/use-axios-get';
import useAxiosPost from '../hooks/use-axios-post';
import { useAppSelector } from '../hooks/use-redux';
import { daySubmitted } from './sales-journal-helpers';
import SalesJournalEditModal, { SignFlipTypes } from './SalesJournalEditModal';
import * as salesJournalInterfaces from './SalesJournalInterfaces';

interface DepositCellProps {
    value?: number;
    override?: number;
    // accepted: boolean;
    // data?: salesJournalInterfaces.Item;
    formatter: Intl.NumberFormat;
    locked?: boolean;
}

const DepositCell = ({ ...props }: DepositCellProps) => {
    // const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: props.precision });
    let value = props.value ?? 0;
    let override = props.override ?? NaN;
    let locked = props.locked ?? false;
    const isOverride = !Number.isNaN(override) && override !== value;
    // if (props.data) {
    //     value = props.data.Total;
    //     override = props.data.Override;
    //     accepted = !props.locked ? props.data.Accepted : props.locked;
    // }
    const displayValue = props.formatter.format(value);
    const currentValue = isOverride ? override : value;
    return (
        <td className={`${currentValue < 0 ? 'text-danger' : 'text-dark'} ${!locked && !isOverride ? 'text-opacity-50' : ''}`}>
            {isOverride
                ? <React.Fragment><del className={value < 0 ? 'text-danger' : 'text-dark'}>{displayValue}</del> {props.formatter.format(override)}</React.Fragment>
                : <React.Fragment>{displayValue}</React.Fragment>
            }
        </td>
    );
};

function sumOfTotalAndOverride(data: salesJournalInterfaces.Day, types: SJType[]) {
    let total = 0;
    let override = 0;
    let noOverride = true;
    for (const type of types) {
        const isOverride = data.Items[type]?.IsOverride ?? false;
        total += data.Items[type]?.Total ?? 0;
        if (isOverride) {
            noOverride = false;
            override += data.Items[type]?.Override ?? 0;
        } else {
            // If there are multiple values, it is possible that one is an override and the other isn't. This ensures the override total is correct in that case.
            override += data.Items[type]?.Total ?? 0;
        }
    }
    return [total, noOverride ? NaN : override];
}

interface DepositRowProps {
    data: salesJournalInterfaces.Day;
    rowClickHandler: () => void;
    storeNum: number;
}

const DepositRow = ({ ...props }: DepositRowProps) => {
    const depositFormatter = props.storeNum === 310 ? numberFormatter2 : numberFormatter0;
    const submitted = daySubmitted(props.data.Items);
    const ccLocked = props.data.Items[SJType.CREDIT_CARD_1]?.Accepted || props.data.Items[SJType.CREDIT_CARD_2]?.Accepted;
    const accepted = props.data.Items[SJType.OVERSHORT]?.Accepted;
    const locked = accepted || (ccLocked
        && props.data.Items[SJType.AM_DEPOSIT]?.Accepted
        && props.data.Items[SJType.PM_DEPOSIT]?.Accepted);

    const [salesTotal, salesOverride] = sumOfTotalAndOverride(props.data, props.storeNum !== 310 ? [SJType.SALES_AND_TAX] : [SJType.FOOD_SALES, SJType.SHIFT_MEALS]);
    const [ccTotal, ccOverride] = sumOfTotalAndOverride(props.data, [SJType.CREDIT_CARD_1, SJType.CREDIT_CARD_2]);
    const [depositTotal, depositOverride] = sumOfTotalAndOverride(props.data, [SJType.AM_DEPOSIT, SJType.PM_DEPOSIT]);
    const [overShortTotal, overShortOverride] = sumOfTotalAndOverride(props.data, [SJType.OVERSHORT]);

    const rowClasses = 'text-end' + (submitted ? ' bg-light' : ' bg-warning bg-opacity-50 bg-gradient');
    return (
        <tr className={rowClasses} onClick={props.rowClickHandler}>
            <td className='text-center'>{props.data.DateDisplay}</td>
            <td className='text-center'>{props.data.Day}</td>
            {props.storeNum !== 310 &&
                <DepositCell value={salesTotal} override={salesOverride} formatter={numberFormatter2} locked={locked} />
            }
            <DepositCell value={ccTotal} override={ccOverride} formatter={numberFormatter2} locked={locked || ccLocked} />
            <DepositCell value={depositTotal} override={depositOverride} formatter={depositFormatter} locked={locked || ccLocked} />
            {/* <DepositCell value={props.data.PaidOutTotal} formatter={numberFormatter2} locked={locked} />
            <DepositCell value={props.data.OtherIncomeTotal} formatter={numberFormatter2} locked={locked} /> */}
            <DepositCell value={overShortTotal} override={overShortOverride} formatter={numberFormatter2} locked={locked} />
            {/* <td>{deposit.AM.toLocaleString()}</td>
            <td>{deposit.PM.toFixed(0).toLocaleString()}</td>
            <td>{deposit.CC1.toFixed(2).toLocaleString()}</td>
            <td>{deposit.CC2.toFixed(2).toLocaleString()}</td> */}
        </tr>
    );
};

const SalesJournalEntry = () => {
    const [salesJournalData, setSalesJournalData] = React.useState<{ [dateString: string]: salesJournalInterfaces.Day; }>({});
    const [tipData, setTipData] = React.useState<{ [dateString: string]: salesJournalInterfaces.Tips; }>({});
    const [dateKeys, setDateKeys] = React.useState<string[]>([]);
    // const [columnHeaders, setColumnHeaders] = React.useState<SJAccount[]>([]);
    const [dateKey, setDateKey] = React.useState('');
    const [show, setShow] = React.useState(false);

    // const userValue = useAppSelector(state => state.auth.user);
    const store = useAppSelector(state => state.selectors.selectedStore);
    // const [store, setStore] = React.useState<StoreInterface>({
    //     ID: '',
    //     Number: 0,
    //     CompanyID: '',
    // });

    // const store_filter = React.useMemo(() => [310], []);

    // React.useEffect(() => {
    //     if (userValue === undefined || userValue.Stores === undefined || userValue.Stores.length < 1) {
    //         return;
    //     }
    //     for (const store of userValue.Stores) {
    //         if (!store_filter.includes(store.Number)) {
    //             setStore({
    //                 ID: store.ID,
    //                 Number: store.Number,
    //                 CompanyID: store.CompanyID,
    //             });
    //             break;
    //         }
    //     }
    // }, [userValue, store_filter]);

    const applyStoreSalesJournalData = React.useCallback((data: {
        sales_journal: salesJournalInterfaces.ItemsRaw[];
        date_keys: salesJournalInterfaces.DateKeyData[];
        sales_journal_extended: salesJournalInterfaces.ExtendedItemRaw[];
        // column_headers: SJAccount[];
        tips: salesJournalInterfaces.TipsRaw[];
    }) => {
        if (data.tips) {
            let tips: { [dateString: string]: salesJournalInterfaces.Tips; } = {};
            tips = data.tips.reduce((group, tip) => {
                group[tip.DateString] = {
                    Tips1: tip.Tips1,
                    Tips2: tip.Tips2,
                };
                return group;
            }, tips);
            setTipData(tips);
        }
        let sales_journal: { [dateString: string]: salesJournalInterfaces.Day; } = {};
        let sjExtended: { [dateString: string]: salesJournalInterfaces.ExtendedItem[]; } = {};
        let newDateKeys: string[] = [];
        sales_journal = data.date_keys.reduce((group, dk) => {
            newDateKeys.push(dk.DateString);
            const localDate = new Date(dk.DateString);
            sjExtended[dk.DateString] = [];
            group[dk.DateString] = {
                Date: dk.Date,
                DateDisplay: dateFormatter.format(localDate),
                Day: dayNameDateFormatter.format(localDate),
                PaidOutTotal: 0,
                OtherIncomeTotal: 0,
                AccountTotals: {},
                Sigma: 0,
                Items: {},
                ItemsExtended: [],
            };
            return group;
        }, sales_journal);

        if (data.sales_journal_extended) {
            sjExtended = data.sales_journal_extended.reduce((group, sjeDetail) => {
                const isPaidOut = (sjeDetail.Total > 0 && !sjeDetail.FlipSign) || (sjeDetail.Total > 0 && !sjeDetail.FlipSign);
                const absTotal = Math.abs(sjeDetail.Total);
                group[sjeDetail.DateString].push({
                    ID: sjeDetail.ID,
                    AccountID: sjeDetail.AccountID,
                    AccountDesc: sjeDetail.AccountDesc,
                    Total: absTotal,
                    Notes: sjeDetail.Notes,
                    FlipSign: sjeDetail.FlipSign,
                    IsPaidOut: isPaidOut,
                });
                if (isPaidOut) {
                    sales_journal[sjeDetail.DateString].PaidOutTotal += absTotal;
                } else {
                    sales_journal[sjeDetail.DateString].OtherIncomeTotal += absTotal;
                }
                return group;
            }, sjExtended);
        }

        if (data.sales_journal) {
            sales_journal = data.sales_journal.reduce((group, detail) => {
                const flipSign = (SignFlipTypes.includes(detail.Type)); // (detail.Type === SJType.SALES_AND_TAX || detail.Type === SJType.OVERSHORT);
                group[detail.DateString].Items[detail.Type] = {
                    ID: detail.ID,
                    Total: flipSign && detail.Total !== 0 ? detail.Total * -1 : detail.Total,
                    Override: flipSign && detail.OverrideTotal !== 0 ? detail.OverrideTotal * -1 : detail.OverrideTotal,
                    IsOverride: detail.IsOverride,
                    Accepted: detail.Accepted,
                    Notes: detail.Notes,
                };
                group[detail.DateString].ItemsExtended = sjExtended[detail.DateString];
                return group;
            }, sales_journal);
        }
        // console.log(data.date_keys);
        // console.log(sales_journal)
        // setColumnHeaders(data.column_headers);
        setDateKeys(newDateKeys);
        setSalesJournalData(sales_journal);
    }, []);

    const [axiosGetStoreSalesJournal] = useAxiosGet(applyStoreSalesJournalData);

    const getStoreSalesJournal = React.useCallback((storeID: string) => {
        if (storeID === '') {
            setSalesJournalData({});
            return;
        }
        axiosGetStoreSalesJournal(
            `${GOAPIPATH}/storeSalesJournal?storeID=${storeID}`,
            {
                withCredentials: true
            }
        );
    }, [axiosGetStoreSalesJournal]);

    React.useEffect(() => {
        // getStoreDeposits(store.ID);
        getStoreSalesJournal(store.ID);
        // I don't think this screen needs to auto update since we update when a row is clicked.
        // const intervalId = setInterval(getStoreDeposits(store.ID), 5 * 60 * 1000); // x minute(s) x*60*1000
        // return () => clearInterval(intervalId);
    }, [getStoreSalesJournal, store.ID]);

    // const storeOptionElements = userValue?.Stores?.filter(store => store.Number !== 310).map(store => (
    //     <option key={store.ID} value={store.ID}>{store.Number}</option>
    // ));

    const [axiosPostDeposit] = useAxiosPost(undefined);

    const submitHandler = async (salesJournalDay: salesJournalInterfaces.Day, deleteExtendedItems: string[], isMonthlyAdjustment: boolean, tips?: salesJournalInterfaces.Tips) => {
        const submitSalesJournal: {
            id: string;
            type: string;
            total: number;
        }[] = [];
        for (let type in salesJournalDay.Items) {
            let signedTotal = salesJournalDay.Items[type].Total;
            // Sales are always negative and we need to flip the sign on over/short.
            if (SignFlipTypes.includes(type)) {
                signedTotal *= -1;
            }
            submitSalesJournal.push({
                id: salesJournalDay.Items[type].ID,
                type: type,
                total: signedTotal,
            });
        }
        for (let type of [SJType.AM_DEPOSIT, SJType.PM_DEPOSIT, SJType.CREDIT_CARD_1, SJType.CREDIT_CARD_2, SJType.OVERSHORT]) {
            if (salesJournalDay.Items[type]) continue;
            // Add required items if they don't exist.
            submitSalesJournal.push({
                id: '',
                type: type,
                total: 0,
            });
        }
        let submitSJExtended = salesJournalDay.ItemsExtended.map(extItem => {
            // Paid outs are positive UNLESS we need to flip the sign.
            // Other income is negative UNLES we need to flip the sign. 
            let signedTotal = extItem.IsPaidOut ? extItem.Total : extItem.Total * -1;
            if (extItem.FlipSign) {
                signedTotal *= -1;
            }
            return {
                id: extItem.ID,
                accountID: extItem.AccountID,
                total: signedTotal,
                notes: extItem.Notes,
            };
        });
        const tipsData = tips ? {
            tips1: tips.Tips1,
            tips2: tips.Tips2,
            valid: true,
        } : undefined;
        const data = {
            date: salesJournalDay.Date,
            store: store.Number,
            // isMonthlyAdjustment: isMonthlyAdjustment,
            salesJournal: submitSalesJournal,
            salesJournalExtended: submitSJExtended,
            deleteSalesJournalExtended: deleteExtendedItems,
            tips: tipsData,
        };
        const error = await axiosPostDeposit(
            `${GOAPIPATH}/submitSalesJournal`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        );
        if (error !== null) {
            console.log('Failed to submit daily log. The following error was generated:', error);
            return;
        }
        getStoreSalesJournal(store.ID); // Is this overkill? Should we just try to update the state with the new values?
        setShow(false);
    };

    const rowClickHandler = (dateKey: string) => {
        getStoreSalesJournal(store.ID);
        setDateKey(dateKey);
        setShow(true);
    };

    // const selectStoreHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const { value, selectedIndex } = event.target;
    //     setStore({ id: value, number: Number(event.target[selectedIndex].innerText) });
    // };

    const rows = dateKeys.map(dateKey => (
        <DepositRow
            key={dateKey}
            data={salesJournalData[dateKey]}
            rowClickHandler={() => rowClickHandler(dateKey)}
            storeNum={store.Number}
        />
    ));

    // const columnHeaderElements = columnHeaders?.map(header => <th className='text-wrap' key={header.ID}>{header.Account}<br/>{header.Description}</th>);

    return (
        <div>
            <SalesJournalEditModal
                show={show}
                setShow={setShow}
                data={salesJournalData[dateKey]}
                tips={tipData[dateKey]}
                storeNum={store.Number}
                companyID={store.CompanyID}
                submitHandler={submitHandler}
                isMonthlyAdjustment={false}
                isAdmin={false}
                locked={false}
            />
            {/* <SubmitDepositModal 
                show={false} 
                setShow={setShow} 
                deposit={depositData[index]} 
                submitHandler={submitHandler} 
                storeNum={store.Number} 
            /> */}
            <Container fluid='lg' className='mt-3'>
                <Row className='text-nowrap'>
                    <h2>Daily Log Entry</h2>
                </Row>
                <Row className='d-flex align-items-center mb-2'>
                    <Col xs='auto'>
                        <StoreSelector 
                            // filter={store_filter} 
                        />
                        {/* <InputGroup>
                            <InputGroup.Text>Store #</InputGroup.Text>
                            <Form.Select
                                className='text-end'
                                onChange={selectStoreHandler}
                                disabled={userValue && userValue.Stores && userValue.Stores.length > 1 ? false : true}
                            >
                                {storeOptionElements}
                            </Form.Select>
                        </InputGroup> */}
                    </Col>
                    <Col>
                        <p className='fs-4 mt-2 mb-0 text-nowrap'>Click on a row to edit.</p>
                    </Col>
                </Row>
                <Row>
                    <Table className='mb-0' bordered hover responsive>
                        <thead>
                            <tr className='text-end text-nowrap bg-secondary bg-opacity-50 bg-gradient'>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Day</th>
                                {store.Number !== 310 &&
                                    <th>Total Sales & Tax</th>
                                }
                                <th>Credit Total</th>
                                <th>Deposit Total</th>
                                <th>Over/Short</th>
                                {/* {columnHeaderElements}
                                <th>Paid Out</th>
                                <th>Other Income</th>
                                <th>Sales & Tax</th>
                                <th>Credit Card 1</th>
                                <th>Credit Card 2</th>
                                <th>Deposit AM</th>
                                <th>Deposit PM</th>
                                <th>Over/Short</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
};

export default SalesJournalEntry;