import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ButtonWithConfirmationProps {
    // show: boolean;
    // hideModal: () => void;
    popupMessage: string;
    popupHeader: string;
    buttonLabel: string;
    buttonVariant?: string;
    buttonDisabled?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const ButtonWithConfirmation = ({ ...props }: ButtonWithConfirmationProps) => {
    const [show, setShow] = React.useState(false);
    const handleCancel = () => {
        setShow(false);
        if (props.onCancel) props.onCancel();
    }

    const handleConfirm = () => {
        setShow(false);
        if (props.onConfirm) props.onConfirm();
    }

    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.popupHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.popupMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                <Button autoFocus className='px-3' variant='secondary' onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm}>OK</Button>
                </Modal.Footer>
            </Modal>
            <Button variant={props.buttonVariant} disabled={props.buttonDisabled} onClick={() => setShow(true)}>{props.buttonLabel}</Button>
        </React.Fragment>
    );
};

export default ButtonWithConfirmation;