import React from 'react';

const useScroll: () => [() => void, React.RefObject<HTMLDivElement>] = () => {
    const elRef = React.useRef<HTMLDivElement>(null);
    const executeScroll = React.useCallback(() => {
        elRef.current?.scrollIntoView();
    }, []);
    return [executeScroll, elRef];
};

export default useScroll;