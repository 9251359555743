import React from 'react';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { NumericFormat, NumberFormatValues, SourceInfo } from 'react-number-format';
import { useAppSelector } from '../hooks/use-redux';
import * as depositsInterfaces from '../deposits/DepositsInterfaces';
import { Bank } from '../store/banks-slice';
import useAxiosPost from '../hooks/use-axios-post';
import { GOAPIPATH } from '../constants';
import { numberFormatter0, numberFormatter2, shortDateTimeFormatter } from '../helpers';

export const usePostUpdateDeposit = () => {
    const [axiosPostDeposit] = useAxiosPost(undefined);
    
    const postUpdateDeposit = React.useCallback(async (deposit: depositsInterfaces.DepositDetail) => {
        const data = [{
            depositID: deposit.ID,
            overrideTotal: deposit.OverrideTotal,
            isOverride: deposit.IsOverride,
            accepted: deposit.Accepted,
            isAccepted: deposit.AcceptedString !== "",
            status: deposit.Status,
            notes: deposit.Notes,
            bankID: deposit.BankID,
            hitBankNextMonth: deposit.HitBankNextMonth
        }];
        const error = await axiosPostDeposit(
            `${GOAPIPATH}/verifyDeposit`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        );
        if (error) {
            console.log(error);
        } 
        return error;
    }, [axiosPostDeposit]);
    return postUpdateDeposit;
}
    
export interface DepositOverrideTotalModalProps {
    show: boolean;
    hideModal: () => void;
    deposit: depositsInterfaces.DepositDetail;
    isAM: boolean;
    dayString: string;
    submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, bank: Bank, hitBankNextMonth: boolean) => void;
    isLocked: boolean;
}

const DepositOverrideTotalModal = ({ ...props }: DepositOverrideTotalModalProps) => {
    const userValue = useAppSelector(state => state.auth.user);
    // const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: Number.isInteger(props.deposit.Total) ? 0 : 2 });
    const formatter = Number.isInteger(props.deposit.Total) ? numberFormatter0 : numberFormatter2;
    // const dateFormatter = new Intl.DateTimeFormat('en-us', { dateStyle: 'short', timeStyle: 'short' });
    const [newValues, setNewValues] = React.useState({
        overrideTotal: NaN,
        overrideTotalString: '',
        notes: '',
        bank: {
            ID: '',
            Name: '',
        },
        hitBankNextMonth: false,
    });

    const storeHasSubmitted = props.deposit.SubmittedString !== '';
    const submitted = storeHasSubmitted ? shortDateTimeFormatter.format(props.deposit.Submitted) : '';
    const accepted = props.deposit.AcceptedString !== '' ? shortDateTimeFormatter.format(props.deposit.Accepted) : '';
    const disabled = props.isLocked; // || props.deposit.SJAccepted;
    const banks = useAppSelector(state => state.banks.all);
    const isSuperUserOrComptroller = userValue?.Permissions?.find(role => ['comptroller', 'superUser'].includes(role)) ? true : false;

    React.useEffect(() => {
        if (props.deposit && props.show) {
            setNewValues({
                overrideTotal: props.deposit.IsOverride ? props.deposit.OverrideTotal : NaN,
                overrideTotalString: props.deposit.IsOverride ? props.deposit.OverrideTotal.toString() : '',
                notes: props.deposit.Notes,
                bank: {
                    ID: props.deposit.BankID,
                    Name: props.deposit.Bank,
                },
                hitBankNextMonth: props.deposit.HitBankNextMonth,
            });
        }
    }, [props.deposit, props.show]);

    const bankOptionElements = banks.map(bank => (
        <option key={bank.ID} value={bank.ID}>{bank.Name}</option>
    ));

    const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const name = sourceInfo.event.target.name;
        setNewValues(prevValues => {
            return {
                ...prevValues,
                [name]: values.floatValue,
                [`${name}String`]: values.value,
            };
        });
    };

    const handleNotesOrCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // const { name, value, checked } = event.target;
        const { name, type, checked, value } = event.target;
        setNewValues(prevValues => {
            return {
                ...prevValues,
                [name]: type === "checkbox" ? checked : value
            };
        });
    };

    const handleBankChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value, selectedIndex } = event.target;
        setNewValues(prevValues => {
            return {
                ...prevValues,
                bank: {
                    ID: value,
                    Name: event.target[selectedIndex].innerText,
                }
            };
        });
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var override = newValues.overrideTotal;
        var isOverride = true;
        if (Number.isNaN(override) || override === undefined) {
            override = 0;
            isOverride = false;
        }
        props.submitOverrideHandler(override, isOverride, newValues.notes, newValues.bank, newValues.hitBankNextMonth);
        props.hideModal();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.hideModal}
            backdrop='static'
            size='lg'
            centered
        // animation={false}
        >
            <Form onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title>Deposit Override</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className='w-50'>
                        <InputGroup.Text>Bank</InputGroup.Text>
                        <Form.Select
                            onChange={handleBankChange}
                            defaultValue={props.deposit.BankID}
                            disabled={disabled}
                        >
                            {bankOptionElements}
                        </Form.Select>
                    </InputGroup>
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <th>Store</th>
                                <th>Day Date</th>
                                <th>AM/PM</th>
                                <th>Submitted</th>
                                <th>Accepted</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <td>#{props.deposit.Store}</td>
                                <td>{props.dayString}</td>
                                <td>{props.isAM ? "AM" : "PM"}</td>
                                <td>{submitted}</td>
                                <td>{accepted}</td>
                                <td>{formatter.format(props.deposit.Total)}</td>
                            </tr>
                        </thead>
                    </Table>
                    <InputGroup>
                        <InputGroup.Text className=''>Override Total:</InputGroup.Text>
                        <Form.Control
                            as={NumericFormat}
                            className='text-end'
                            id='overrideTotal'
                            name='overrideTotal'
                            value={newValues.overrideTotalString}
                            onValueChange={handleChange}
                            type='text'
                            prefix='$ '
                            thousandSeparator=','
                            allowNegative={false}
                            decimalScale={props.deposit.Store === 310 ? 2 : 0}
                            autoFocus
                            disabled={disabled}
                            valueIsNumericString
                        />
                    </InputGroup>
                    <Form.Group className='mt-3'>
                        <Form.Label>Notes:</Form.Label>
                        <Form.Control
                            id='notes'
                            name='notes'
                            as='textarea'
                            value={newValues.notes}
                            onChange={handleNotesOrCheckboxChange}
                            maxLength={200}
                            rows={4}
                            disabled={disabled}
                        />
                    </Form.Group>
                    {isSuperUserOrComptroller &&
                        <Form.Check
                            className={`mt-3 fs-5 rounded`}
                            id='hitBankNextMonth'
                            name='hitBankNextMonth'
                            type='checkbox'
                            checked={newValues.hitBankNextMonth}
                            onChange={handleNotesOrCheckboxChange}
                            label='Posted next month'
                            reverse
                            disabled={disabled}
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' size='lg' onClick={props.hideModal} tabIndex={-1}>Cancel</Button>
                    <Button type='submit' size='lg' disabled={disabled}>Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DepositOverrideTotalModal;