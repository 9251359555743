import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

const ErrorElement = () => {
    const error = useRouteError();
    // console
    if (isRouteErrorResponse(error)) {
        return (
            <div className='p-3' id='error-page'>
                <h1>Error: {error.status}</h1>
                <p>{error.statusText}</p>
                {error.data?.message && (
                    <p>
                        <i>{error.data.message}</i>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof Error) {
        return (
            <div className='p-3' id='error-page'>
                <h1>Unexpected Error</h1>
                {/* <p>Something went wrong.</p> */}
                <p>
                    <i>{error.message}</i>
                </p>
            </div>
        );
    }
    return (
        <div className='p-3' id='error-page'>
            <h1>Unexpected Error</h1> 
        </div>
    )
};

export default ErrorElement;