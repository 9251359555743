import React from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MonthYearSelector, { FirstDay, FirstDayUTC, LastDay } from '../components/MonthYearSelector';
import { GOAPIPATH } from '../constants';
// import DepositGrandTotals from '../deposits/DepositGrandTotals';
import useAxiosGet from '../hooks/use-axios-get';
import { useAppDispatch, useAppSelector } from '../hooks/use-redux';
import useSJLockedState from '../hooks/use-SJLockedState';
import { getCompanies } from '../store/sales-journal-actions';
import { SJCompany } from '../store/sales-journal-slice';
import { selectorsActions } from '../store/selectors-slice';
import { User } from '../store/auth-slice';
import { getMonthName, numberFormatter2 } from '../helpers';

interface ExportCheck {
    StoreID: string;
    StoreNum: number;
    CompanyID: string;
    NotAcceptedCount: number;
    MissingCount: number;
    Sigma: number;
}

interface CompanyCheckExportElementProps {
    company: SJCompany;
    stores: ExportCheck[];
    isLocked: boolean;
    firstDay: string;
    lastDay: string;
    navigateToSalesJournal: (storeID: string) => void;
    allowSageExport: boolean;
}

const CompanyCheckExportElement = ({ ...props }: CompanyCheckExportElementProps) => {
    // console.log(props.stores);
    if (!props.stores) return <React.Fragment></React.Fragment>;
    // let preventExport = false;
    const checkGridRowElements = props.stores.map(store => {
        const highlightClasses = 'bg-warning bg-opacity-50 bg-gradient';
        const naClasses = store.NotAcceptedCount > 0 ? highlightClasses : '';
        const missingClasses = store.MissingCount > 0 ? highlightClasses :'';
        const sigmaClasses = store.Sigma !== 0 ? highlightClasses : '';
        return (
            <tr key={store.StoreID} className='cursor-pointer' onClick={() => props.navigateToSalesJournal(store.StoreID)}>
                <td>{store.StoreNum}</td>
                <td className={naClasses}>{store.NotAcceptedCount}</td>
                <td className={missingClasses}>{store.MissingCount}</td>
                <td className={sigmaClasses}>{numberFormatter2.format(store.Sigma)}</td>
            </tr>
        );
    });
    return (
        <Container className='mb-3 border border-2 rounded'>
            <Row className='my-3 d-flex align-items-center'>
                <Col className='align-center' sm='auto' >
                    <h2>{props.company.Name}</h2>
                </Col>
                {props.allowSageExport &&
                    <Col className='align-center' sm='auto' >
                        <Button
                            className=''
                            variant='secondary'
                            size='lg'
                            href={`${GOAPIPATH}/exportSJtoSage?companyID=${props.company.ID}&from=${props.firstDay}&to=${props.lastDay}`}
                            // disabled={!props.isLocked || preventExport}
                            disabled={!props.isLocked}
                        >
                            Export to Sage
                        </Button>
                    </Col>
                }
                {/* <Col>
                    {(!props.isLocked || preventExport) && 'Issues must be corrected and the month must be locked before exporting to Sage.'}
                </Col> */}
            </Row>
            <Row>
                <Table size='sm' bordered hover responsive>
                    <thead className='text-center text-nowrap bg-secondary bg-opacity-50'>
                        <tr>
                            <th>Store #</th>
                            <th>Unverified Days</th>
                            <th>Missing Days</th>
                            <th>Σ</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {checkGridRowElements}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

const SalesJournalExport = () => {
    // const [monthYearData, setMonthYearData] = React.useState<MonthYearData>({});
    const [exportChecksData, setExportChecksData] = React.useState<{ [companyID: string]: ExportCheck[]; }>({});
    const dispatch = useAppDispatch();
    const { companies, companiesLoaded } = useAppSelector(state => state.salesJournal);
    const userValue: User = useAppSelector(state => state.auth.user);
    const adminMode = userValue.Permissions?.find(role => ['comptroller', 'superUser'].includes(role)) ? true : false;
    // const canLock = userValue.Permissions ? userValue.Permissions.includes('superUser') || userValue.Permissions.includes('comptroller') : false;
    const selectedMonth = useAppSelector(state => state.selectors.selectedMonth);
    const selectedYear = useAppSelector(state => state.selectors.selectedYear);
    const storeLookup = useAppSelector(state => state.auth.storeLookup);
    const [isLocked, lockButtonElement] = useSJLockedState(adminMode, FirstDayUTC(selectedYear, selectedMonth));

    const firstDay = React.useMemo(() => FirstDay(selectedYear, selectedMonth), [selectedYear, selectedMonth]);
    const lastDay = React.useMemo(() => LastDay(selectedYear, selectedMonth), [selectedYear, selectedMonth]);

    const navigate = useNavigate();
    const navigateToSalesJournal = (storeID: string) => {
        dispatch(selectorsActions.selectStore({...storeLookup[storeID]}))
        navigate(`/salesJournal`);
    };

    React.useEffect(() => {
        if (!companiesLoaded) {
            dispatch(getCompanies());
        }
    }, [companiesLoaded, dispatch]);

    const applyExportChecksData = React.useCallback((data: { exportChecks: ExportCheck[]; }) => {
        if (!data.exportChecks) {
            setExportChecksData({});
            return;
        }
        let newExportChecks: { [companyID: string]: ExportCheck[]; } = {};
        newExportChecks = data.exportChecks.reduce((group, check) => {
            if (!group[check.CompanyID]) group[check.CompanyID] = [];
            group[check.CompanyID].push(check);
            return group;
        }, newExportChecks);
        setExportChecksData(newExportChecks);
    }, []);

    const [axiosGetExportChecks] = useAxiosGet(applyExportChecksData);
    
    React.useEffect(() => {
        if (firstDay === "" || lastDay === "") return;
        const getExportChecks = () => {
            axiosGetExportChecks(
                `${GOAPIPATH}/sjExportChecks?from=${firstDay}&to=${lastDay}`,
                {
                    withCredentials: true
                }
            );
        };
        getExportChecks();
        const intervalId = setInterval(getExportChecks, 1 * 60 * 1000); // x minute(s) x*60*1000
        return () => clearInterval(intervalId);
    }, [axiosGetExportChecks, firstDay, lastDay]);

    // let companyElements: JSX.Element[] = [];
    // companyElements = companies.reduce((group, company) => {
    //     group.push(<div>{company.ID}</div>);
    //     return group;
    // }, companyElements);

    const companyElements = companies.map(company => (
        <CompanyCheckExportElement
            key={company.ID}
            company={company}
            stores={exportChecksData[company.ID]}
            isLocked={isLocked}
            firstDay={firstDay}
            lastDay={lastDay}
            navigateToSalesJournal={navigateToSalesJournal}
            allowSageExport={adminMode}
        />
        // <Button
        //     key={company.ID}
        //     className='my-3'
        //     variant='secondary'
        //     size='lg'
        //     href={`${GOAPIPATH}/exportSJtoSage?companyID=${company.ID}&from=${firstDay}&to=${lastDay}`}
        //     disabled={!isLocked}
        // >
        //     {`${company.Name} - Export Sales Journal to Sage`}
        // </Button>
    ));
    return (
        <div className='h-100 d-flex flex-column'>
            <div className='h-100 overflow-auto'>
                {adminMode &&
                    <Container className=''>
                        <Button
                            className='my-3'
                            variant='secondary'
                            size='lg'
                            href={`${GOAPIPATH}/sjExport?from=${firstDay}&to=${lastDay}`}
                            // disabled={!isLocked}
                        >
                            {getMonthName(selectedMonth)} {selectedYear} Sales Journal Summary
                        </Button>
                    </Container>
                }
                {/* <DepositGrandTotals start={FirstDay(monthYearData)} end={LastDay(monthYearData)} /> */}
                {/* <Button className='ms-auto' href={`${GOAPIPATH}/exportSJtoSage?companyID=${companyID}&from=${FirstDay(monthYearData)}&to=${LastDay(monthYearData)}`} >Download</Button> */}
                <Container className='py-5 d-flex flex-column'>
                    {/* {exportButtonElements} */}
                    {companyElements}
                </Container>
            </div>
            <Container className='text-bg-secondary' fluid>
                <Row className='py-1 gy-1 d-flex align-items-center'>
                    <Col xs='auto'>
                        <h3>Sales Journal Export</h3>
                    </Col>
                    <MonthYearSelector />
                    {adminMode &&
                        <Col className='ms-5'>
                            <Button 
                                variant='primary'
                                href={`${GOAPIPATH}/exportYearlyCCAndDepositSummary?from=${selectedYear}-01-01&to=${selectedYear}-12-31`}
                            >{selectedYear} CC & Deposit Summary</Button>
                        </Col>
                    }
                    <Col className='text-end'>
                        {lockButtonElement}
                        {/* <SalesJournalLockButton locked={isLocked} allowToggle={true} month={FirstDayUTC(monthYearData)} callback={() => getCompanySalesJournal(store.ID) }/> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SalesJournalExport;