import { createSlice } from '@reduxjs/toolkit';
import { StoreInterface, defaultStoreInterface } from './auth-slice';
// import { MonthYearData } from '../components/MonthYearSelector';

interface SelectorsState {
    selectedStore: StoreInterface;
    // selectedMonthYear: MonthYearData;
    selectedMonth: number;
    selectedYear: number;
    availableYears: number[];
}

const getAvailableYears = () => {
    const currentDate = new Date();
    let year = currentDate.getFullYear();
    let years = [year];
    while (years.length < 8 && year > 2023) {
        year -= 1;
        years.push(year);
    }
    return years;
}

const initialState: SelectorsState = {
    selectedStore: {...defaultStoreInterface},
    selectedMonth: (new Date()).getMonth(),
    selectedYear: (new Date()).getFullYear(),
    availableYears: getAvailableYears(),
}

const selectorsSlice = createSlice({
    name: 'selectors',
    initialState,
    reducers: {
        selectStore(state, action) {
            state.selectedStore = action.payload;
        },
        selectYear(state, action) {
            state.selectedYear = action.payload;
        },
        selectMonth(state, action) {
            state.selectedMonth = action.payload;
        },
    }
});

export const selectorsActions = selectorsSlice.actions;

export default selectorsSlice;