import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { goDate } from '../helpers';
import { useAppDispatch, useAppSelector } from '../hooks/use-redux';
import { selectorsActions } from '../store/selectors-slice';

// export interface MonthYearData {
//     years?: number[];
//     selectedYear?: number;
//     selectedMonth?: number;
// }

export function FirstDayUTC(year: number, month: number) {
    // if (monthYear.selectedYear === undefined || monthYear.selectedMonth === undefined) return undefined;
    return new Date(Date.UTC(year, month, 1));
}

export function FirstDay(year: number, month: number) {
    // if (monthYear.selectedYear === undefined || monthYear.selectedMonth === undefined) return "";
    return goDate(new Date(year, month, 1));
}

export function LastDay(year: number, month: number) {
    // if (monthYear.selectedYear === undefined || monthYear.selectedMonth === undefined) return "";
    return goDate(new Date(year, month + 1, 0));
}

interface MonthYearSelectorProps {
    // monthYear: MonthYearData;
    // setMonthYear: React.Dispatch<React.SetStateAction<MonthYearData>>;
}

const MonthYearSelector = ({ ...props }: MonthYearSelectorProps) => {
    // React.useEffect(() => {
    //     const currentDate = new Date();
    //     let year = currentDate.getFullYear();
    //     let years = [year];
    //     while (years.length < 8 && year > 2023) {
    //         year -= 1;
    //         years.push(year);
    //     }
    //     setMonthYear({
    //         years: years,
    //         selectedYear: currentDate.getFullYear(),
    //         selectedMonth: currentDate.getMonth(),
    //     });

    // }, [setMonthYear]);
    const selectedYear = useAppSelector(state => state.selectors.selectedYear);
    const selectedMonth = useAppSelector(state => state.selectors.selectedMonth);
    const availableYears = useAppSelector(state => state.selectors.availableYears);
    const dispatch = useAppDispatch();
    const monthElementName = 'selectedMonth';

    const selectMonthYearHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value, name } = event.target;
        const valueNumber = Number(value);
        if (name === monthElementName) {
            dispatch(selectorsActions.selectMonth(valueNumber));
            return;
        }
        dispatch(selectorsActions.selectYear(valueNumber));
        // setMonthYear(prevState => {
        //     return {
        //         ...prevState,
        //         [name]: Number(value),
        //     };
        // });
    };

    const yearOptionElements = availableYears.map(year => (
        <option key={year} value={year}>{year}</option>
    ));

    return (
        <React.Fragment>
            <Col xs='auto'>
                <InputGroup>
                    <InputGroup.Text>Month</InputGroup.Text>
                    <Form.Select
                        name={monthElementName}
                        className='text-end'
                        onChange={selectMonthYearHandler}
                        value={selectedMonth}
                    >
                        <option value={0}>January</option>
                        <option value={1}>February</option>
                        <option value={2}>March</option>
                        <option value={3}>April</option>
                        <option value={4}>May</option>
                        <option value={5}>June</option>
                        <option value={6}>July</option>
                        <option value={7}>August</option>
                        <option value={8}>September</option>
                        <option value={9}>October</option>
                        <option value={10}>November</option>
                        <option value={11}>December</option>
                    </Form.Select>
                </InputGroup>
            </Col>
            <Col xs='auto'>
                <InputGroup>
                    <InputGroup.Text>Year</InputGroup.Text>
                    <Form.Select
                        name='selectedYear'
                        className='text-end'
                        onChange={selectMonthYearHandler}
                        value={selectedYear}
                    >
                        {yearOptionElements}
                    </Form.Select>
                </InputGroup>
            </Col>
        </React.Fragment>
    );
};

export default MonthYearSelector;