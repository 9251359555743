import { createSlice } from '@reduxjs/toolkit';

export interface SJAccount {
    ID: string;
    Account: string;
    Description: string;
    HelpText: string;
    FlipSign: boolean;
    AdminOnly: boolean;
    CompanyID: string;
}

export interface SJCompany {
    ID: string;
    Name: string;
}

interface SalesJournalSlice {
    accounts: SJAccount[];
    accountLookup: { [accountID: string]: SJAccount; };
    accountsLoaded: boolean;
    companies: SJCompany[];
    companiesLoaded: boolean;
}

const initialState: SalesJournalSlice = {
    accounts: [],
    accountLookup: {},
    accountsLoaded: false,
    companies: [],
    companiesLoaded: false,
};

const salesJournalSlice = createSlice({
    name: 'salesJournal',
    initialState,
    reducers: {
        updateAccounts(state, action) {
            const newAccounts: SJAccount[] = action.payload;
            state.accounts = newAccounts;
            state.accountsLoaded = action.payload.length > 0;
            let newAccountLookup: { [accountID: string]: SJAccount; } = {};
            newAccountLookup = newAccounts.reduce((group, account) => {
                group[account.ID] = account;
                return group;
            }, newAccountLookup);
            state.accountLookup = newAccountLookup;
        },
        updateCompanies(state, action) {
            const newCompanies: SJCompany[] = action.payload;
            state.companies = newCompanies;
            state.companiesLoaded = action.payload.length > 0;
        },
        clear(state) {
            // state.all = [];
        },
    }
});

export const salesJournalActions = salesJournalSlice.actions;

export default salesJournalSlice;