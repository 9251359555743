import { SJType } from '../enums';
import * as salesJournalInterfaces from './SalesJournalInterfaces';

export function itemTotalBase(total: number, override: number, isOverride: boolean) {
    if (isOverride) {
        return override;
    }
    return total;
}

export function itemTotal(item: salesJournalInterfaces.Item | undefined) {
    if (item === undefined) {
        return NaN;
    }
    return itemTotalBase(item.Total, item.Override, item.IsOverride);
}

export function itemTotalStringBase(total: string, override: string, isOverride: boolean) {
    if (isOverride) {
        return override;
    }
    return total;
}

export function itemTotalString(item: salesJournalInterfaces.Item | undefined) {
    if (item === undefined) {
        return '';
    }
    return itemTotalStringBase(item.TotalString ?? '', item.OverrideString ?? '', item.IsOverride);
}

export function itemTotalSummable(item: salesJournalInterfaces.Item | undefined) {
    const total = itemTotal(item);
    if (Number.isNaN(total)) return 0;
    return total;
}

export function daySubmitted(items: salesJournalInterfaces.Day['Items'], isAdjustment?: boolean) {
    if (!items[SJType.OVERSHORT]) return false;
    if (isAdjustment) return true;
    if (!items[SJType.AM_DEPOSIT]) return false;
    if (!items[SJType.PM_DEPOSIT]) return false;
    if (!items[SJType.CREDIT_CARD_1]) return false;
    if (!items[SJType.CREDIT_CARD_2]) return false;
    return true;
}