import React from 'react';
import { Badge, Button, Container, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { NumericFormat, NumberFormatValues, SourceInfo } from 'react-number-format';
import { GOAPIPATH } from '../constants';
import { numberFormatter2 } from '../helpers';
import useAxiosGet from '../hooks/use-axios-get';
import useAxiosPost from '../hooks/use-axios-post';
import { useAppSelector } from '../hooks/use-redux';

interface CCTotalDetail {
    CC1ID: string;
    CC2ID: string;
    Store: number;
    Submitted: Date;
    SubmittedString: string;
    Accepted: Date;
    AcceptedString: string;
    CC1Total: number;
    CC2Total: number;
    Total: number;
    OverrideTotal: number;
    IsOverride: boolean;
    Notes: string;
    SJAccepted: boolean;
    HitBankNextMonth: boolean;
}
const defaultCCTotalDetail: CCTotalDetail = {
    CC1ID: '',
    CC2ID: '',
    Store: 0,
    Submitted: new Date(),
    SubmittedString: '',
    Accepted: new Date(),
    AcceptedString: '',
    CC1Total: 0,
    CC2Total: 0,
    Total: 0,
    OverrideTotal: 0,
    IsOverride: false,
    Notes: '',
    SJAccepted: false,
    HitBankNextMonth: false,
};

interface CCDay {
    Date: Date;
    DateString: string;
    CCTotals: CCTotalDetail[];
}

interface OverrideCCTotalModalProps {
    show: boolean;
    hideModal: () => void;
    ccTotal: CCTotalDetail;
    dateString: string;
    isLocked: boolean;
    submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, hitBankNextMonth: boolean) => void;
}

const OverrideCCTotalModal = ({ ...props }: OverrideCCTotalModalProps) => {
    const userValue = useAppSelector(state => state.auth.user);
    // const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
    const dateFormatter = new Intl.DateTimeFormat('en-us', { dateStyle: 'short', timeStyle: 'short' });
    const [newValues, setNewValues] = React.useState({
        overrideTotal: NaN,
        overrideTotalString: '',
        notes: '',
        hitBankNextMonth: false,
    });

    const submitted = props.ccTotal.SubmittedString !== '' ? dateFormatter.format(props.ccTotal.Submitted) : '';
    const accepted = props.ccTotal.AcceptedString !== '' ? dateFormatter.format(props.ccTotal.Accepted) : '';
    const disabled = props.isLocked; // || props.ccTotal.SJAccepted;
    const isSuperUserOrComptroller = userValue?.Permissions?.find(role => ['comptroller', 'superUser'].includes(role)) ? true : false;

    React.useEffect(() => {
        if (props.ccTotal && props.show) {
            setNewValues({
                overrideTotal: props.ccTotal.IsOverride ? props.ccTotal.OverrideTotal : NaN,
                overrideTotalString: props.ccTotal.IsOverride ? props.ccTotal.OverrideTotal.toString() : '',
                notes: props.ccTotal.Notes,
                hitBankNextMonth: props.ccTotal.HitBankNextMonth,
            });
        }
    }, [props.ccTotal, props.show]);

    const handleNumberChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const name = sourceInfo.event.target.name;
        setNewValues(prevValues => {
            return {
                ...prevValues,
                [name]: values.floatValue,
                [`${name}String`]: values.value,
            };
        });
    };

    const handleNotesOrCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
        setNewValues(prevValues => {
            return {
                ...prevValues,
                [name]: type === "checkbox" ? checked : value
            };
        });
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var override = newValues.overrideTotal;
        var isOverride = true;
        if (Number.isNaN(override) || override === undefined) {
            override = 0;
            isOverride = false;
        }
        props.submitOverrideHandler(override, isOverride, newValues.notes, newValues.hitBankNextMonth);
        props.hideModal();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.hideModal}
            backdrop='static'
            size='lg'
            centered
        // animation={false}
        >
            <Form onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title>Credit Card Total Override</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Store</th>
                                <th>Submitted</th>
                                <th>Accepted</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.dateString}</td>
                                <td>#{props.ccTotal.Store}</td>
                                <td>{submitted}</td>
                                <td>{accepted}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table className='text-center'>
                        <thead>
                            <tr>
                                <th>Credit Card 1</th>
                                <th>Credit Card 2</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{numberFormatter2.format(props.ccTotal.CC1Total)}</td>
                                <td>{numberFormatter2.format(props.ccTotal.CC2Total)}</td>
                                <td>{numberFormatter2.format(props.ccTotal.Total)}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text className='w-50'>Override Total:</InputGroup.Text>
                        <Form.Control
                            as={NumericFormat}
                            className='text-end'
                            id='overrideTotal'
                            name='overrideTotal'
                            value={newValues.overrideTotalString}
                            onValueChange={handleNumberChange}
                            type='text'
                            prefix='$ '
                            thousandSeparator=','
                            allowNegative={false}
                            decimalScale={2}
                            autoFocus
                            disabled={disabled}
                            valueIsNumericString
                        />
                    </InputGroup>
                    {/* <InputGroup>
                        <InputGroup.Text className='w-50'>+/- Adjustment:</InputGroup.Text>
                        <Form.Control
                            className='text-end'
                            id='ccAdj'
                            name='ccAdj'
                            value={newValues.ccAdj}
                            onChange={handleChange}
                            type='text'
                        />
                    </InputGroup> */}
                    <Form.Group className='mt-3'>
                        <Form.Label>Notes:</Form.Label>
                        <Form.Control
                            id='notes'
                            name='notes'
                            as='textarea'
                            value={newValues.notes}
                            onChange={handleNotesOrCheckboxChange}
                            maxLength={200}
                            rows={4}
                            disabled={disabled}
                        />
                    </Form.Group>
                    {isSuperUserOrComptroller &&
                        <Form.Check
                            className={`mt-3 fs-5 rounded`}
                            id='hitBankNextMonth'
                            name='hitBankNextMonth'
                            type='checkbox'
                            checked={newValues.hitBankNextMonth}
                            onChange={handleNotesOrCheckboxChange}
                            label='Posted next month'
                            reverse
                            disabled={disabled}
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' size='lg' onClick={props.hideModal} tabIndex={-1}>Cancel</Button>
                    <Button type='submit' size='lg' disabled={disabled}>Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

// const MemoOverrideCCModal = React.memo(OverrideCCTotalModal);

interface CCTotalCellProps {
    ccTotal: CCTotalDetail;
    dayIndex: number;
    storeIndex: number;
    dateString: string;
    isLocked: boolean;
    updateHandler: (dayIndex: number, storeIndex: number, ccTotal: CCTotalDetail) => void;
    setModalProps: React.Dispatch<React.SetStateAction<CCModalPropsInterface>>;
}

const CCTotalCell = ({ ...props }: CCTotalCellProps) => {
    // const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
    const storeHasSubmitted = props.ccTotal.SubmittedString !== '';
    var total = numberFormatter2.format(props.ccTotal.Total);
    var classes = ['text-end'];
    if (props.ccTotal.IsOverride) {
        total = numberFormatter2.format(props.ccTotal.OverrideTotal);
        classes.push('text-danger');
    }
    if (!storeHasSubmitted) classes.push('text-light bg-white');

    if (props.ccTotal.AcceptedString !== '') classes.push('bg-warning bg-opacity-50');
    var badgeClasses = ['px-0'];
    if (props.ccTotal.Notes !== '') badgeClasses.push('corner-cut');

    const rightClickHandler = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        event.preventDefault();
        if (storeHasSubmitted) {
            props.setModalProps({
                show: true,
                // ccTotal: props.ccTotal,
                dayIndex: props.dayIndex,
                dateString: props.dateString,
                storeIndex: props.storeIndex,
                submitOverrideHandler: overrideHandler,
            });
        }
    };

    const toggleAccepted = () => {
        // if (!storeHasSubmitted || props.ccTotal.IsOverride || props.isLocked) return;
        // If the month is locked OR the Sales Journal day has been accepted, don't allow changes.
        // if (!storeHasSubmitted || props.isLocked || props.ccTotal.SJAccepted) return;
        if (!storeHasSubmitted || props.isLocked) return;
        var newCCTotal = { ...props.ccTotal };
        if (newCCTotal.AcceptedString === '') {
            newCCTotal.Accepted = new Date(Date.now());
            newCCTotal.AcceptedString = newCCTotal.Accepted.toString();
        } else {
            newCCTotal.Accepted = new Date(0);
            newCCTotal.AcceptedString = '';
        }
        props.updateHandler(props.dayIndex, props.storeIndex, newCCTotal);
    };

    const overrideHandler = (overrideTotal: number, isOverride: boolean, notes: string, hitBankNextMonth: boolean) => {
        var newCCTotal = { ...props.ccTotal };
        if (isOverride && newCCTotal.AcceptedString === '') {
            newCCTotal.Accepted = new Date(Date.now());
            newCCTotal.AcceptedString = newCCTotal.Accepted.toString();
        }
        newCCTotal.OverrideTotal = overrideTotal;
        newCCTotal.IsOverride = isOverride;
        newCCTotal.Notes = notes;
        newCCTotal.HitBankNextMonth = hitBankNextMonth;
        props.updateHandler(props.dayIndex, props.storeIndex, newCCTotal);
    };

    const badgeValue = props.ccTotal.IsOverride ? props.ccTotal.OverrideTotal - props.ccTotal.CC1Total - props.ccTotal.CC2Total : 0;
    const badgeBg = badgeValue >= 0 ? 'success' : 'danger';

    return (
        <React.Fragment>
            {/* <MemoOverrideCCModal show={showEdit} setShow={setShowEdit} dateString={dateString} ccTotal={ccTotal} submitOverrideHandler={overrideHandler} /> */}
            <td
                onClick={toggleAccepted}
                onContextMenu={rightClickHandler}
                className={classes.join(' ')}
            >
                <div className={props.ccTotal.HitBankNextMonth ? 'border border-secondary border-1' : ''}>
                    {total}
                </div>
            </td>
            <td
                onClick={toggleAccepted}
                onContextMenu={rightClickHandler}
                className={`${badgeClasses.join(' ')} ${classes.join(' ')}`}
            >
                <Badge className='align-middle' bg={badgeBg} pill>{badgeValue === 0 ? '' : numberFormatter2.format(badgeValue)}</Badge>
            </td>
        </React.Fragment>
    );
};

const MemoCCTotalCell = React.memo(CCTotalCell);

interface CCTotalsRowProps {
    ccDay: CCDay;
    dayIndex: number;
    isLocked: boolean;
    updateHandler: (dayIndex: number, storeIndex: number, ccTotal: CCTotalDetail) => void;
    setModalProps: React.Dispatch<React.SetStateAction<CCModalPropsInterface>>;
}

const CCTotalsRow = ({ ...props }: CCTotalsRowProps) => {
    const cellElements = props.ccDay.CCTotals.map((total, index) => {
        return (
            <MemoCCTotalCell
                key={`${props.ccDay.DateString}-${total.Store}`}
                ccTotal={total}
                dateString={props.ccDay.DateString}
                dayIndex={props.dayIndex}
                storeIndex={index}
                isLocked={props.isLocked}
                updateHandler={props.updateHandler}
                setModalProps={props.setModalProps}
            />
        );
    });
    return (
        <tr>
            <th className='position-sticky start-0 table-secondary'>{props.ccDay.DateString}</th>
            {cellElements}
        </tr>
    );
};

interface VerifyCreditTotalsProps {
    start: string; // YYYY-MM-DD 
    end: string; // YYYY-MM-DD
    isLocked: boolean;
}

interface CCModalPropsInterface {
    show: boolean;
    // ccTotal: CCTotalDetail;
    dayIndex: number;
    storeIndex: number;
    dateString: string;
    submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, hitBankNextMonth: boolean) => void;
}

const VerifyCreditTotals = ({ ...props }: VerifyCreditTotalsProps) => {
    const [ccDays, setCCDays] = React.useState<CCDay[]>([]);
    const [grandTotals, setGrandTotals] = React.useState<{ [store: number]: number; }>({});
    // const [isLocked, setIsLocked] = React.useState(false);
    const [modalProps, setModalProps] = React.useState<CCModalPropsInterface>({
        show: false,
        dayIndex: 0,
        storeIndex: 0,
        // ccTotal: {
        //     CC1ID: '',
        //     CC2ID: '',
        //     Store: 0,
        //     Submitted: new Date(),
        //     SubmittedString: '',
        //     Accepted: new Date(),
        //     AcceptedString: '',
        //     CC1Total: 0,
        //     CC2Total: 0,
        //     Total: 0,
        //     OverrideTotal: 0,
        //     IsOverride: false,
        //     Notes: '',
        // },
        dateString: '',
        submitOverrideHandler: (overrideTotal: number, isOverride: boolean, notes: string, hitBankNextMonth: boolean) => { return; },
    });

    const applyCCData = React.useCallback((data: {
        ccDays: CCDay[];
        // isLocked: boolean;
    }) => {
        const newGrandTotals: { [store: number]: number; } = {};

        const newTotals = data.ccDays.map(day => {
            var newDay = {
                ...day,
                Date: new Date(day.DateString)
            };
            newDay.CCTotals = newDay.CCTotals.map(total => {
                const additiveTotal = total.IsOverride ? total.OverrideTotal : total.Total;
                if (total.Store in newGrandTotals) {
                    newGrandTotals[total.Store] += additiveTotal;
                } else {
                    newGrandTotals[total.Store] = additiveTotal;
                }
                return {
                    ...total,
                    Submitted: new Date(total.SubmittedString),
                    Accepted: new Date(total.AcceptedString)
                };
            });
            return newDay;
        });
        setCCDays(newTotals);
        setGrandTotals(newGrandTotals);
        // setIsLocked(data.isLocked);
    }, []);

    const [axiosGetCCData] = useAxiosGet(applyCCData);

    React.useEffect(() => {
        const getCCData = () => {
            axiosGetCCData(
                `${GOAPIPATH}/companyCCTotals?from=${props.start}&to=${props.end}`,
                {
                    withCredentials: true
                }
            );
        };
        getCCData();
        const intervalId = setInterval(getCCData, 1 * 60 * 1000); // x minute(s) x*60*1000
        return () => clearInterval(intervalId);
        // Including storeIndex and dayIndex in dependency array to trigger refresh on cell right click. Just using modalProps.show triggers to often.
    }, [props.start, props.end, axiosGetCCData, modalProps.storeIndex, modalProps.dayIndex]);

    const [axiosPostCCTotal] = useAxiosPost(undefined);

    const updateCCTotal = React.useCallback(async (dayIndex: number, storeIndex: number, ccTotal: CCTotalDetail) => {
        if (props.isLocked) return;
        const data = [
            {
                depositID: ccTotal.CC1ID,
                overrideTotal: ccTotal.OverrideTotal,
                isOverride: ccTotal.IsOverride,
                accepted: ccTotal.Accepted,
                isAccepted: ccTotal.AcceptedString !== "",
                notes: ccTotal.Notes,
                hitBankNextMonth: ccTotal.HitBankNextMonth
            },
            {
                depositID: ccTotal.CC2ID,
                overrideTotal: 0,
                isOverride: ccTotal.IsOverride,
                accepted: ccTotal.Accepted,
                isAccepted: ccTotal.AcceptedString !== "",
                hitBankNextMonth: ccTotal.HitBankNextMonth
                // notes: ccTotal.Notes
            },
        ];
        const error = await axiosPostCCTotal(
            `${GOAPIPATH}/verifyDeposit`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        );
        if (!error) {
            setCCDays(prevCCDays => {
                var newCCDays = [...prevCCDays];
                var oldCCTotal = prevCCDays[dayIndex].CCTotals[storeIndex];
                const oldTotal = oldCCTotal.IsOverride ? oldCCTotal.OverrideTotal : oldCCTotal.Total;
                const newTotal = ccTotal.IsOverride ? ccTotal.OverrideTotal : ccTotal.Total;
                const difference = newTotal - oldTotal;
                if (difference !== 0) {
                    setGrandTotals(prevTotals => {
                        var newTotals = { ...prevTotals };
                        newTotals[ccTotal.Store] += difference;
                        return newTotals;
                    });
                }
                newCCDays[dayIndex].CCTotals[storeIndex] = ccTotal;
                return newCCDays;
            });
        }
    }, [axiosPostCCTotal, props.isLocked]);

    const storeHeaderElements = ccDays[0]?.CCTotals.map(total => (
        <React.Fragment key={total.Store}>
            <th
                className='text-end pe-4'
            >
                #{total.Store}
            </th>
            <th></th>
        </React.Fragment>
        // <th 
        //     key={total.Store} 
        //     colSpan={2}
        // >
        //     #{total.Store}
        // </th>
    ));

    const totalsRowElements = ccDays.map((day, index) => {
        return (
            <CCTotalsRow
                key={day.DateString}
                ccDay={day}
                dayIndex={index}
                isLocked={props.isLocked}
                updateHandler={updateCCTotal}
                setModalProps={setModalProps}
            />
        );
    });

    // const formatter = new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 });
    const grandTotalElements = ccDays[0]?.CCTotals.map(total => {
        return (
            <React.Fragment key={total.Store}>
                <th>{numberFormatter2.format(grandTotals[total.Store])}</th>
                <th></th>
            </React.Fragment>
        );
    });

    return (
        <div>
            <OverrideCCTotalModal
                show={modalProps.show}
                hideModal={() => {
                    setModalProps(prevState => {
                        return { ...prevState, show: false };
                    });
                }}
                dateString={modalProps.dateString}
                ccTotal={ccDays.length > 0 ? ccDays[modalProps.dayIndex].CCTotals[modalProps.storeIndex] : {...defaultCCTotalDetail}}
                // ccTotal={modalProps.ccTotal}
                submitOverrideHandler={modalProps.submitOverrideHandler}
                isLocked={props.isLocked}
            />
            <Container fluid className='mt-2 pb-2'>
                <Table className='' borderless hover size='sm'>
                    <thead className='sticky-top text-bg-secondary'>
                        <tr className='text-center'>
                            <th className='position-sticky start-0 text-bg-secondary'>Date\Store</th>
                            {storeHeaderElements}
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {totalsRowElements}
                    </tbody>
                    <tfoot className='text-end'>
                        <tr className='table-dark'>
                            <th className='position-sticky start-0 text-center table-dark'>TOTAL</th>
                            {grandTotalElements}
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        </div>
    );
};

export default VerifyCreditTotals;