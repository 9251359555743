export enum SJType {
    // Short Stop & Sandy's
    SALES_AND_TAX = 'S&T',
    
    // La Palapa
    FOOD_SALES = 'FS',
    SHIFT_MEALS = 'SHM',
    FOOD_COMPS = 'FC',
    LIQUOR_COMPS = 'LC',
    BEER_COMPS = 'BC',
    WINE_COMPS = 'WC',
    LIQUOR_SALES = 'LS',
    BEER_SALES = 'BS',
    WINE_SALES = 'WS',
    FOOD_TAX = 'FTX',
    LIQUOR_TAX = 'LTX',
    GIFT_CARD_SOLD = 'GCS',
    GIFT_CARD_REDEEMED = 'GCR',

    // Shared
    AM_DEPOSIT = 'AM',
    PM_DEPOSIT = 'PM',
    CREDIT_CARD_1 = 'CC1',
    CREDIT_CARD_2 = 'CC2',
    OVERSHORT = 'O/S'
}