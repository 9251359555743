import { Card, Container } from 'react-bootstrap';
import useAxiosGet from '../hooks/use-axios-get';
import React from 'react';
import { GOAPIPATH } from '../constants';
import packageJson from '../../package.json'

const AboutComponent = () => {
    const [aboutStrings, setAboutStrings] = React.useState<string[]>([]);

    const applyAboutData = React.useCallback((data: {aboutStrings: string[]}) => {
        setAboutStrings([
            // 'make newversion | newversionminor | newversionmajor' Use 'npm version' to update packageJson.version. (npm version major | minor | patch) version: major.minor.patch
            // Don't forget to check in package.json and package-lock.json after updating version.
            `React ${React.version}`,
            ...data.aboutStrings,
            `React Dashboard Version ${packageJson.version} (${process.env.REACT_APP_BUILD_TIME ?? 'DEV-BUILD'})`,
        ]);
    }, []);
    const [axiosGetAboutData] = useAxiosGet(applyAboutData);

    React.useEffect(() => {
        axiosGetAboutData(
            `${GOAPIPATH}/aboutData`,
            {
                withCredentials: true
            }
        );
    }, [axiosGetAboutData])

    const aboutElements = aboutStrings ? aboutStrings.map(aboutString => <Card.Text className='mb-2' key={aboutString}>{aboutString}</Card.Text>) : undefined;

    return (
        <Container className='mt-4'>
            <Card>
                <Card.Header as='h4'>
                    <svg className="me-2 bi bi-info-circle" style={{"verticalAlign": "-9%"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    About
                </Card.Header>
                <Card.Body className='text-center'>
                    {aboutElements}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AboutComponent;