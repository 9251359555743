import React from 'react';

// DANGEROUS! Only use if you are absolutely sure it is needed! 
// https://www.bam.tech/article/how-to-avoid-bugs-in-useeffect
const useComponentDidMount = (effectOnMount: () => void) => {
    React.useEffect(() => {
        effectOnMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useComponentDidMount;