import React from 'react';
import { GOAPIPATH } from '../constants';
import { goDate } from '../helpers';
import SalesJournalLockButton from '../sales-journal/SalesJournalLockButton';
import useAxiosGet from './use-axios-get';
import useAxiosPost from './use-axios-post';

const useSJLockedState = (allowToggle: boolean, monthStart?: Date): [boolean, JSX.Element] => {
    const [isLocked, setIsLocked] = React.useState(false);
    
    const applyLockedState = (data: { isLocked: boolean; }) => {
        setIsLocked(data.isLocked);
    };

    const [axiosGetLockedState] = useAxiosGet(applyLockedState);

    const getLockedState = React.useCallback(() => {
        if (!monthStart) return;
        axiosGetLockedState(
            `${GOAPIPATH}/salesJournalLockedState?month=${goDate(monthStart)}`,
            {
                withCredentials: true
            }
        );
    }, [axiosGetLockedState, monthStart]);

    const [axiosPostLockedState] = useAxiosPost(undefined);

    const submitLockedState = React.useCallback(async (locked: boolean) => {
        if (!monthStart) return;
        const data = {
            month: monthStart.toISOString(),
            locked: locked
        };
        const error = await axiosPostLockedState(
            `${GOAPIPATH}/lockSalesJournal`,
            JSON.stringify(data),
            {
                withCredentials: true
            }
        )
        if (error !== null) {
            console.log('Failed to toggle locked state. The following error was generated:', error);
            return;
        }
        setIsLocked(locked);
    }, [axiosPostLockedState, monthStart]);

    React.useEffect(() => {
        getLockedState();
        const intervalId = setInterval(getLockedState, 1 * 60 * 1000); // x minute(s) x*60*1000
        return () => clearInterval(intervalId);
    }, [getLockedState]);

    const lockButtonElement = <SalesJournalLockButton month={monthStart} allowToggle={allowToggle} locked={isLocked} submitLockedState={submitLockedState} />

    return [
        isLocked,
        lockButtonElement
    ];
};

export default useSJLockedState;