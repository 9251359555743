import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { NumberFormatValues, SourceInfo } from 'react-number-format';
import { SJType } from '../enums';
import * as salesJournalInterfaces from './SalesJournalInterfaces';
import SalesJournalExtendedModal from './SalesJournalExtendedModal';
import { ExtendedItemsDisplay, SalesJournalEditModalInput } from './SalesJournalEditModalSharedComponents';
import { itemTotal, itemTotalString, itemTotalSummable } from './sales-journal-helpers';

export const SignFlipTypes: string[] = [
    SJType.SALES_AND_TAX,
    SJType.FOOD_SALES,
    // SJType.TAX_EXEMPT,
    SJType.LIQUOR_SALES,
    SJType.BEER_SALES,
    SJType.WINE_SALES,
    SJType.FOOD_TAX,
    SJType.LIQUOR_TAX,
    SJType.GIFT_CARD_SOLD,
    SJType.OVERSHORT
];

// interface SalesJournalEditDay {
//     totalSales: number;
//     cc1: number;
//     cc2: number;
//     am: number;
//     pm: number;
//     overShort: number;
//     extendedItems: salesJournalInterfaces.ExtendedItem[];
//     deletedExtendedItems: string[]; // IDs of deleted sales journal extended items.
// }

// interface ExtendedItemElementProps {
//     extendedItem: salesJournalInterfaces.ExtendedItem;
//     index: number;
//     editHandler: (extendedItemIndex: number) => void;
//     disabled: boolean;
// }

// const ExtendedItemElement = ({ ...props }: ExtendedItemElementProps) => {
//     const onClickHandler = () => {
//         if (!props.disabled){
//             props.editHandler(props.index)
//         }
//     };

//     const style = props.disabled ? {} : {cursor: 'pointer'}

//     return (
//         <InputGroup className='mb-2' onClick={onClickHandler} style={style}>
//             <InputGroup.Text className='ps-4 w-50 text-wrap text-start'><p className='my-0'><strong>{props.extendedItem.AccountDesc}</strong>: {props.extendedItem.Notes}</p></InputGroup.Text>
//             {/* <InputGroup.Text className='w-25' id=''>{props.extendedItem.desc}</InputGroup.Text>
//             <InputGroup.Text className='w-50' id=''>{props.extendedItem.note}</InputGroup.Text> */}
//             <Form.Control
//                 style={style}
//                 as={NumberFormat}
//                 className={`text-end ${props.extendedItem.Total < 0 ? 'text-danger' : ''}`}
//                 // className='text-end'
//                 id=''
//                 name=''
//                 value={props.extendedItem.Total}
//                 // onValueChange={handleChange}
//                 type='text'
//                 // prefix={isPaidOut ? '$ -' : '$ '}
//                 prefix='$ '
//                 thousandSeparator=','
//                 allowNegative={true}
//                 decimalScale={2}
//                 fixedDecimalScale={true}
//                 // autoFocus={!deposit.AMAccepted}
//                 // readOnly={true}
//                 disabled={true}
//             // placeholder='0'
//             />
//         </InputGroup>
//     );
// };

// interface ExtendedItemsDisplayProps {
//     isPaidOut: boolean;
//     extendedItems: salesJournalInterfaces.ExtendedItem[];
//     editHandler: (extendedItemIndex: number) => void;
//     disabled: boolean;
// }

// const ExtendedItemsDisplay = ({ ...props }: ExtendedItemsDisplayProps) => {
//     let extendedItemElements: JSX.Element[] = [];
//     props.extendedItems.forEach((extendedItem, index) => {
//         if (props.isPaidOut === extendedItem.IsPaidOut) {
//             extendedItemElements.push(
//                 <ExtendedItemElement
//                     key={index}
//                     extendedItem={extendedItem}
//                     index={index}
//                     // isPaidOut={props.isPaidOut}
//                     editHandler={props.editHandler}
//                     disabled={props.disabled}
//                 />
//             );
//         }
//     });

//     return (
//         <React.Fragment>
//             {extendedItemElements.length > 0 &&
//                 <h5 className='text-start ms-3 mb-1'>{props.isPaidOut ? 'Paid Outs' : 'Other Income'}</h5>
//             }
//             {extendedItemElements}
//         </React.Fragment>
//     );
// };


interface SalesJournalEditModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    data: salesJournalInterfaces.Day;
    tips?: salesJournalInterfaces.Tips;
    storeNum: number;
    companyID: string;
    isAdmin: boolean;
    isMonthlyAdjustment: boolean;
    submitHandler: (
        salesJournalDay: salesJournalInterfaces.Day,
        deletedExtendedItems: string[],
        isMonthlyAdjustment: boolean,
        tips?: salesJournalInterfaces.Tips
    ) => void;
    locked: boolean;
}

interface ExtendedItemModalProps {
    show: boolean;
    extendedItem: salesJournalInterfaces.ExtendedItem;
    extendedItemIndex: number,
    account?: string,
}

const SalesJournalEditModal = ({ ...props }: SalesJournalEditModalProps) => {
    const [values, setValues] = React.useState<salesJournalInterfaces.EditDay>({
        // totalSales: NaN,
        // cc1: NaN,
        // cc2: NaN,
        // am: NaN,
        // pm: NaN,
        // overShort: NaN,
        accepted: false,
        notes: '',
        items: {},
        extendedItems: [],
        deletedExtendedItems: [],
    });

    const [tips, setTips] = React.useState<salesJournalInterfaces.Tips | undefined>();

    // const am_accepted = props.data?.Items[SJType.AM_DEPOSIT]?.Accepted;
    // const pm_accepted = props.data?.Items[SJType.PM_DEPOSIT]?.Accepted;
    // const cc_accepted = (props.data?.Items[SJType.CREDIT_CARD_1]?.Accepted) || (props.data?.Items[SJType.CREDIT_CARD_2]?.Accepted);

    // const cc_disabled = props.isAdmin ? false : cc_accepted;
    // const am_disabled = props.isMonthlyAdjustment ? false : am_accepted;
    // const pm_disabled = props.isMonthlyAdjustment ? false : pm_accepted;
    // const overshort_disabled = props.isAdmin ? false : props.data?.Items[SJType.OVERSHORT]?.IsOverride ?? false;
    // const all_disabled = props.isAdmin ? props.locked : props.locked || values.accepted || (cc_disabled && am_disabled && pm_disabled);

    const is_la_palapa = props.storeNum === 310;
    const am_accepted = props.data?.Items[SJType.AM_DEPOSIT]?.Accepted;
    const pm_accepted = props.data?.Items[SJType.PM_DEPOSIT]?.Accepted;
    const cc_accepted = (props.data?.Items[SJType.CREDIT_CARD_1]?.Accepted) || (props.data?.Items[SJType.CREDIT_CARD_2]?.Accepted);

    const all_disabled = props.locked
        || (!props.isAdmin && !props.isMonthlyAdjustment && (values.accepted || (cc_accepted && am_accepted && pm_accepted)));

    const cc_disabled = all_disabled || (!props.isAdmin && cc_accepted);
    const am_disabled = all_disabled || am_accepted; // props.isMonthlyAdjustment ? false : am_accepted;
    const pm_disabled = all_disabled || pm_accepted; // props.isMonthlyAdjustment ? false : pm_accepted;
    const overshort_disabled = all_disabled || (!props.isAdmin && props.data?.Items[SJType.OVERSHORT]?.IsOverride); // props.isAdmin ? false : props.data?.Items[SJType.OVERSHORT]?.IsOverride ?? false;

    const show_accepted = props.isAdmin && !props.isMonthlyAdjustment;
    // const show_unverified_warning = show_accepted && (!am_accepted || !pm_accepted || !cc_accepted);

    const [childModalProps, setChildModalProps] = React.useState<ExtendedItemModalProps>({
        show: false,
        extendedItem: { ...salesJournalInterfaces.defaultExtendedItem },
        extendedItemIndex: -1,
    });

    // const currentValue = (type: string) => {
    //     if (!values.items[type]) return NaN;
    //     return itemTotal(values.items[type]);
    // };

    const calculateOverShortAdmin = () => {
        let overShort = 0;
        for (const type in values.items) {
            if (type !== SJType.OVERSHORT) {
                const total = itemTotalSummable(values.items[type]);
                overShort += total;
            }
        }
        overShort += values.extendedItems.reduce((partialTotal, extItem) => {
            // Do nothing with Tax-Exempt (305). It is included in Sales and handled differently.
            if (extItem.AccountDesc.startsWith('305')) {
                return partialTotal;
            }
            return partialTotal + extItem.Total;
        }, 0);
        return Math.round(overShort * -100) / 100;
    };

    const calculateOverShort = () => {
        let overShort = 0;
        // const typesToSubtract: string[] = [SJType.AM_DEPOSIT, SJType.PM_DEPOSIT, SJType.CREDIT_CARD_1, SJType.CREDIT_CARD_2];
        for (const type in values.items) {
            if (type !== SJType.OVERSHORT) {
                let total = itemTotalSummable(values.items[type]);
                if (SignFlipTypes.includes(type)) {
                    overShort += total;
                } else {
                    overShort -= total;
                }
            }
        }
        overShort += values.extendedItems.reduce((partialTotal, extItem) => {
            // Do nothing with Tax-Exempt (305). It is included in Sales and handled differently.
            if (extItem.AccountDesc.startsWith('305')) {
                return partialTotal;
            }
            // Subtract paid outs, add other income.
            let effectiveTotal = extItem.IsPaidOut ? extItem.Total * -1 : extItem.Total;
            if (extItem.FlipSign) {
                effectiveTotal *= -1;
            }
            return partialTotal + effectiveTotal;
        }, 0);
        return Math.round(overShort * -100) / 100;
    };

    const calculatedOverShort = props.isAdmin ? calculateOverShortAdmin() : calculateOverShort();
    const calculatedIsOver = calculatedOverShort * (props.isAdmin ? 1 : -1) <= 0;
    const currentOverShort = itemTotalSummable(values.items[SJType.OVERSHORT]);
    const isOver = currentOverShort * (props.isAdmin ? 1 : -1) <= 0;

    React.useEffect(() => {
        if (props.show) {
            // Make a deep copy of props.data.Items to prevent funny business.
            let newItems: { [type: string]: salesJournalInterfaces.Item; } = {};
            for (let key in props.data.Items) {
                newItems[key] = { 
                    ...props.data.Items[key],
                    TotalString: props.data.Items[key].Total.toString(),
                    OverrideString: props.data.Items[key].Override.toString(),
                };
            }
            setValues({
                accepted: props.data.Items[SJType.OVERSHORT]?.Accepted ?? false,
                notes: props.data.Items[SJType.OVERSHORT]?.Notes ?? '',
                items: newItems,
                extendedItems: [...props.data.ItemsExtended],
                deletedExtendedItems: [],
            });
            if (props.tips) {
                setTips({
                    ...props.tips,
                    Tips1String: props.tips.Tips1.toString(),
                    Tips2String: props.tips.Tips2.toString(),
                });
            } else {
                setTips(undefined);
            }
        }
    }, [props.data, props.show, props.tips]);

    function handleChange(values: NumberFormatValues, sourceInfo: SourceInfo): void {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const type = sourceInfo.event.target.name;
        setValues(prevValues => {
            let newItems = {
                ...prevValues.items,
            };
            if (!newItems[type]) {
                newItems[type] = { ...salesJournalInterfaces.defaultItem };
            }
            if (props.isAdmin) {
                newItems[type].Override = values.floatValue ?? NaN;
                newItems[type].OverrideString = values.value;
                newItems[type].IsOverride = true;
                // newItems[type].Accepted = true;
            } else {
                newItems[type].Total = values.floatValue ?? NaN;
                newItems[type].TotalString = values.value;
            }
            return {
                ...prevValues,
                items: newItems
            };
        });
    }

    function handleTipChange(values: NumberFormatValues, sourceInfo: SourceInfo): void {
        if (!(sourceInfo.event?.target instanceof HTMLInputElement)) {
            return;
        }
        const name = sourceInfo.event.target.name;
        setTips(prevValues => {
            let newValues: salesJournalInterfaces.Tips = {
                Tips1: NaN,
                Tips2: NaN,
            };
            if (prevValues) {
                newValues = {
                    ...prevValues
                };
            }
            return {
                ...newValues,
                [name]: values.floatValue ?? NaN,
                [`${name}String`]: values.value,
            };
        });
    }

    const handleNotesOrCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
        setValues(prevValues => {
            return {
                ...prevValues,
                [name]: type === "checkbox" ? checked : value
            };
        });
    };

    const getSalesJournalDayFromUI = () => {
        let latestData: salesJournalInterfaces.Day = {
            Date: props.data.Date,
            DateDisplay: props.data.DateDisplay,
            Day: props.data.Day,
            PaidOutTotal: 0,
            OtherIncomeTotal: 0,
            AccountTotals: {},
            Sigma: 0,
            Items: values.items,
            ItemsExtended: values.extendedItems,
        };
        if (props.isAdmin) {
            if (!latestData.Items[SJType.OVERSHORT]) {
                latestData.Items[SJType.OVERSHORT] = { ...salesJournalInterfaces.defaultItem };
                // latestData.Items[SJType.OVERSHORT] = {
                //     ID: '',
                //     Total: NaN,
                //     Override: NaN,
                //     IsOverride: false,
                //     Accepted: false,
                // }
            }
            latestData.Items[SJType.OVERSHORT].Accepted = values.accepted;
            latestData.Items[SJType.OVERSHORT].Notes = values.notes;
        }
        return latestData;
    };

    // const submit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     props.submitHandler(getSalesJournalDayFromUI(), values.deletedExtendedItems, props.isMonthlyAdjustment);
    // };

    const submit = () => {
        props.submitHandler(getSalesJournalDayFromUI(), values.deletedExtendedItems, props.isMonthlyAdjustment, tips);
    };

    // const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    //     if (event.key === 'Enter') {
    //         submit();
    //     }
    // }

    const hideModal = () => {
        props.setShow(false);
    };

    const createNewExtendedItemOnClickHandler = (isPaidOut: boolean, account?: string) => {
        setChildModalProps({
            show: true,
            extendedItem: {
                ...salesJournalInterfaces.defaultExtendedItem,
                IsPaidOut: isPaidOut,
            },
            extendedItemIndex: -1,
            account: account,
        });
    };

    const editExtendedItemOnClickHandler = (extendedItemIndex: number) => {
        const selectedExtendedItem = values.extendedItems[extendedItemIndex];
        setChildModalProps({
            show: true,
            extendedItem: selectedExtendedItem,
            // isPaidOut: isPaidOut,
            extendedItemIndex: extendedItemIndex,
            account: undefined,
        });
    };

    const deleteExtendedItemHandler = (extendedItemIndex: number) => {
        setValues(prevValues => {
            const deletedId = prevValues.extendedItems[extendedItemIndex].ID;
            let newDeletedExtendedItems = [...prevValues.deletedExtendedItems];
            if (deletedId !== '') {
                newDeletedExtendedItems.push(deletedId);
            }
            return {
                ...prevValues,
                extendedItems: prevValues.extendedItems.filter((_, index) => index !== extendedItemIndex),
                deletedExtendedItems: newDeletedExtendedItems,
            };
        });
    };

    const addEditExtendedItemSubmitHandler = (extendedItem: salesJournalInterfaces.ExtendedItem, extendedItemIndex: number) => {
        setValues(prevValues => {
            let newValues = { ...prevValues };
            if (extendedItemIndex < 0) {
                newValues.extendedItems = [...prevValues.extendedItems, extendedItem];
            } else {
                newValues.extendedItems = [...prevValues.extendedItems];
                newValues.extendedItems[extendedItemIndex] = extendedItem;
            }
            return newValues;
        });
    };

    const isOverrideOrAccepted = (type: string) => {
        if (!props.data?.Items[type] ?? false) return false;
        return props.data.Items[type].IsOverride || props.data.Items[type].Accepted;
    };

    return (
        <Modal
            show={props.show}
            size="lg"
            onHide={hideModal}
            backdrop='static'
            scrollable
        >
            <SalesJournalExtendedModal
                show={childModalProps.show}
                hideModal={() => {
                    setChildModalProps(prevState => {
                        return { ...prevState, show: false };
                    });
                }}
                extendedItem={childModalProps.extendedItem}
                // isPaidOut={childModalProps.isPaidOut}
                companyID={props.companyID}
                extendedItemIndex={childModalProps.extendedItemIndex}
                submitHandler={addEditExtendedItemSubmitHandler}
                deleteExtendedItemHandler={deleteExtendedItemHandler}
                adminMode={props.isAdmin}
                account={childModalProps.account}
            />
            {/* <div className=""> */}
            {/* <Form onSubmit={submit} className=""> */}
            <Modal.Header closeButton>
                <Modal.Title>{props.show ? `Sales Journal - #${props.storeNum} - ${props.data.Day} (${props.data.DateDisplay})` : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body
            // onKeyDown={handleKeyDown}
            >
                {/* Total Sales & Tax */}
                {
                    !is_la_palapa ?
                        <>
                            <h5 className='text-start ms-3 mb-1'>Gross Sales</h5>
                            <SalesJournalEditModalInput
                                id={SJType.SALES_AND_TAX}
                                label='Total Sales & Tax'
                                value={itemTotal(values.items[SJType.SALES_AND_TAX])}
                                valueString={itemTotalString(values.items[SJType.SALES_AND_TAX])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.SALES_AND_TAX))}
                                autoFocus
                            />
                        </> :
                        <React.Fragment>
                            <SalesJournalEditModalInput
                                id={SJType.FOOD_SALES}
                                label='Net Food Sales (before tax)'
                                value={itemTotal(values.items[SJType.FOOD_SALES])}
                                valueString={itemTotalString(values.items[SJType.FOOD_SALES])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.FOOD_SALES))}
                                autoFocus
                            />
                            <SalesJournalEditModalInput
                                id={SJType.SHIFT_MEALS}
                                label='Discounts (Shift Meals)'
                                value={itemTotal(values.items[SJType.SHIFT_MEALS])}
                                valueString={itemTotalString(values.items[SJType.SHIFT_MEALS])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.SHIFT_MEALS))}
                            />
                            <h5 className='text-start ms-3 mb-1'>Comps</h5>
                            <SalesJournalEditModalInput
                                id={SJType.FOOD_COMPS}
                                label='Food Comps'
                                value={itemTotal(values.items[SJType.FOOD_COMPS])}
                                valueString={itemTotalString(values.items[SJType.FOOD_COMPS])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.FOOD_COMPS))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.LIQUOR_COMPS}
                                label='Liquor Comps'
                                value={itemTotal(values.items[SJType.LIQUOR_COMPS])}
                                valueString={itemTotalString(values.items[SJType.LIQUOR_COMPS])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.LIQUOR_COMPS))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.BEER_COMPS}
                                label='Beer Comps'
                                value={itemTotal(values.items[SJType.BEER_COMPS])}
                                valueString={itemTotalString(values.items[SJType.BEER_COMPS])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.BEER_COMPS))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.WINE_COMPS}
                                label='Wine Comps'
                                value={itemTotal(values.items[SJType.WINE_COMPS])}
                                valueString={itemTotalString(values.items[SJType.WINE_COMPS])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.WINE_COMPS))}
                            />
                            {/* <h5 className='text-start ms-3 mb-1'>Tax-Exempt</h5>
                            <SalesJournalEditModalInput
                                id={SJType.TAX_EXEMPT}
                                label='NON Taxable (Tax-Exempt)'
                                value={itemTotal(values.items[SJType.TAX_EXEMPT])}
                                valueString={itemTotalString(values.items[SJType.TAX_EXEMPT])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.TAX_EXEMPT))}
                            /> */}
                            <h5 className='text-start ms-3 mb-1'>Liquor/Beer/Wine Sales</h5>
                            <SalesJournalEditModalInput
                                id={SJType.LIQUOR_SALES}
                                label='Net Liquor Sales'
                                value={itemTotal(values.items[SJType.LIQUOR_SALES])}
                                valueString={itemTotalString(values.items[SJType.LIQUOR_SALES])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.LIQUOR_SALES))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.BEER_SALES}
                                label='Net Beer Sales'
                                value={itemTotal(values.items[SJType.BEER_SALES])}
                                valueString={itemTotalString(values.items[SJType.BEER_SALES])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.BEER_SALES))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.WINE_SALES}
                                label='Net Wine Sales'
                                value={itemTotal(values.items[SJType.WINE_SALES])}
                                valueString={itemTotalString(values.items[SJType.WINE_SALES])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.WINE_SALES))}
                            />
                            <h5 className='text-start ms-3 mb-1'>Tax</h5>
                            <SalesJournalEditModalInput
                                id={SJType.FOOD_TAX}
                                label='Food Sales Tax Collected'
                                value={itemTotal(values.items[SJType.FOOD_TAX])}
                                valueString={itemTotalString(values.items[SJType.FOOD_TAX])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.FOOD_TAX))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.LIQUOR_TAX}
                                label='MB Sales Tax Collected'
                                value={itemTotal(values.items[SJType.LIQUOR_TAX])}
                                valueString={itemTotalString(values.items[SJType.LIQUOR_TAX])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.LIQUOR_TAX))}
                            />
                            <h5 className='text-start ms-3 mb-1'>Gift Cards</h5>
                            <SalesJournalEditModalInput
                                id={SJType.GIFT_CARD_SOLD}
                                label='Gift Cards Sold'
                                value={itemTotal(values.items[SJType.GIFT_CARD_SOLD])}
                                valueString={itemTotalString(values.items[SJType.GIFT_CARD_SOLD])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.GIFT_CARD_SOLD))}
                            />
                            <SalesJournalEditModalInput
                                id={SJType.GIFT_CARD_REDEEMED}
                                label='Gift Cards Redeemed'
                                value={itemTotal(values.items[SJType.GIFT_CARD_REDEEMED])}
                                valueString={itemTotalString(values.items[SJType.GIFT_CARD_REDEEMED])}
                                handleChange={handleChange}
                                decimalScale={2}
                                allowNegative={props.isAdmin}
                                disabled={all_disabled || (!props.isAdmin && isOverrideOrAccepted(SJType.GIFT_CARD_REDEEMED))}
                            />
                        </React.Fragment>
                }
                <h5 className='text-start ms-3 mb-1'>Credit Cards</h5>
                {/* Credit Card 1 & 2 */}
                <SalesJournalEditModalInput
                    id={SJType.CREDIT_CARD_1}
                    label='Credit Card 1'
                    value={itemTotal(values.items[SJType.CREDIT_CARD_1])}
                    valueString={itemTotalString(values.items[SJType.CREDIT_CARD_1])}
                    handleChange={handleChange}
                    decimalScale={2}
                    allowNegative={props.isAdmin}
                    disabled={cc_disabled}
                    accepted={show_accepted ? cc_accepted : undefined}
                />
                {
                    is_la_palapa && !props.isMonthlyAdjustment &&
                    <SalesJournalEditModalInput
                        id='Tips1'
                        label='Credit Card Tips 1'
                        value={tips?.Tips1 ?? NaN}
                        valueString={tips?.Tips1String ?? ''}
                        handleChange={handleTipChange}
                        decimalScale={2}
                        allowNegative={props.isAdmin}
                        disabled={cc_disabled}
                    />
                }
                <SalesJournalEditModalInput
                    id={SJType.CREDIT_CARD_2}
                    label='Credit Card 2'
                    value={itemTotal(values.items[SJType.CREDIT_CARD_2])}
                    valueString={itemTotalString(values.items[SJType.CREDIT_CARD_2])}
                    handleChange={handleChange}
                    decimalScale={2}
                    allowNegative={props.isAdmin}
                    disabled={cc_disabled}
                    accepted={show_accepted ? cc_accepted : undefined}
                />
                {
                    is_la_palapa && !props.isMonthlyAdjustment &&
                    <SalesJournalEditModalInput
                        id='Tips2'
                        label='Credit Card Tips 2'
                        value={tips?.Tips2 ?? NaN}
                        valueString={tips?.Tips2String ?? ''}
                        handleChange={handleTipChange}
                        decimalScale={2}
                        allowNegative={props.isAdmin}
                        disabled={cc_disabled}
                    />
                }
                {/* Deposit AM & PM */}
                <h5 className='text-start ms-3 mb-1'>Deposits</h5>
                <SalesJournalEditModalInput
                    id={SJType.AM_DEPOSIT}
                    label='Deposit AM'
                    value={itemTotal(values.items[SJType.AM_DEPOSIT])}
                    valueString={itemTotalString(values.items[SJType.AM_DEPOSIT])}
                    handleChange={handleChange}
                    decimalScale={is_la_palapa ? 2 : 0}
                    allowNegative={props.isAdmin}
                    disabled={am_disabled}
                    accepted={show_accepted ? am_accepted : undefined}
                />
                <SalesJournalEditModalInput
                    id={SJType.PM_DEPOSIT}
                    label='Deposit PM'
                    value={itemTotal(values.items[SJType.PM_DEPOSIT])}
                    valueString={itemTotalString(values.items[SJType.PM_DEPOSIT])}
                    handleChange={handleChange}
                    decimalScale={is_la_palapa ? 2 : 0}
                    allowNegative={props.isAdmin}
                    disabled={pm_disabled}
                    accepted={show_accepted ? pm_accepted : undefined}
                />
                {/* Paid Outs / Other Income */}
                <ExtendedItemsDisplay
                    isPaidOut={true}
                    extendedItems={values.extendedItems}
                    editHandler={editExtendedItemOnClickHandler}
                    disabled={all_disabled}
                />
                <ExtendedItemsDisplay
                    isPaidOut={false}
                    extendedItems={values.extendedItems}
                    editHandler={editExtendedItemOnClickHandler}
                    disabled={all_disabled}
                />
                {/* Over/Short */}
                <h5 className='text-start ms-3 mb-1'>Cash Over/Short on Envelope </h5>
                <SalesJournalEditModalInput
                    id={SJType.OVERSHORT}
                    labelElement={
                        <React.Fragment>
                            <div className='my-0 ms-auto'>
                                Cash
                            </div>
                            &nbsp;
                            <div className={`my-0 ${isOver ? 'border border-2 border-secondary px-1 me-1' : ''}`}>
                                Over
                            </div>
                            /
                            <div className={`my-0 ${!isOver ? 'border border-2 border-secondary px-1 ms-1' : ''}`}>
                                Short
                            </div>
                        </React.Fragment>
                    }
                    // value={currentOverShort}
                    // valueString={currentOverShort.toString()}
                    value={itemTotal(values.items[SJType.OVERSHORT])}
                    originalValue={values.items[SJType.OVERSHORT]?.IsOverride && values.items[SJType.OVERSHORT]?.Total !== values.items[SJType.OVERSHORT]?.Override ? values.items[SJType.OVERSHORT]?.Total : undefined}
                    valueString={itemTotalString(values.items[SJType.OVERSHORT])}
                    handleChange={handleChange}
                    decimalScale={2}
                    allowNegative
                    disabled={overshort_disabled}
                />
                <SalesJournalEditModalInput
                    id='calculatedOverShort'
                    labelElement={
                        <React.Fragment>
                            <div className='my-0 ms-auto'>
                                Calculated Cash
                            </div>
                            &nbsp;
                            <div className={`my-0 ${calculatedIsOver ? 'border border-2 border-secondary px-1 me-1' : ''}`}>
                                Over
                            </div>
                            /
                            <div className={`my-0 ${!calculatedIsOver ? 'border border-2 border-secondary px-1 ms-1' : ''}`}>
                                Short
                            </div>
                        </React.Fragment>
                    }
                    value={calculatedOverShort}
                    valueString={calculatedOverShort.toString()}
                    decimalScale={2}
                    allowNegative
                    disabled
                />
                {
                    props.isAdmin &&
                    <React.Fragment>
                        <Form.Group className='mb-2'>
                            <Form.Label>Notes:</Form.Label>
                            <Form.Control
                                id='notes'
                                name='notes'
                                as='textarea'
                                value={values.notes}
                                onChange={handleNotesOrCheckboxChange}
                                maxLength={200}
                                rows={2} //{Math.floor(values.notes.length / 95) + 1}
                                disabled={props.locked}
                            />
                        </Form.Group>
                        <Form.Check
                            className={`fs-3 pe-5 rounded ${values.accepted ? '' : 'text-bg-warning'}`}
                            id='accepted'
                            name='accepted'
                            type='checkbox'
                            checked={values.accepted}
                            onChange={handleNotesOrCheckboxChange}
                            label='Verified/Accepted'
                            reverse
                            disabled={props.locked}
                        // disabled={props.locked || show_unverified_warning}
                        />
                        {/* {show_unverified_warning && <div className='fs-5'>❗Unverified Deposit and/or Credit Card Total</div>} */}
                    </React.Fragment>
                }
                {/* <Form.Check className='fs-3' type='checkbox' reverse>
                            <Form.Check.Input className='bg-success' type='checkbox' />
                            <Form.Check.Label className='px-1 text-bg-primary'>Verified/Accepted</Form.Check.Label>
                        </Form.Check> */}
            </Modal.Body>
            <Modal.Footer>
                {props.isAdmin ?
                    <Button
                        className='me-auto'
                        variant='secondary'
                        onClick={() => createNewExtendedItemOnClickHandler(false)}
                        tabIndex={-1}
                        disabled={props.locked}
                    >
                        Add Paid Out/Other Income
                    </Button>
                    :
                    <React.Fragment>
                        <Button
                            className=''
                            variant='secondary'
                            onClick={() => createNewExtendedItemOnClickHandler(true)}
                            tabIndex={-1}
                            disabled={all_disabled}
                        >
                            Paid Outs
                        </Button>
                        <Button
                            className=''
                            variant='secondary'
                            onClick={() => createNewExtendedItemOnClickHandler(false)}
                            tabIndex={-1}
                            disabled={all_disabled}
                        >
                            Other Income
                        </Button>
                        <Button
                            className='me-auto'
                            variant='secondary'
                            onClick={() => createNewExtendedItemOnClickHandler(false, '305')}
                            tabIndex={-1}
                            disabled={all_disabled}
                        >
                            Tax-Exempt
                        </Button>
                    </React.Fragment>
                }
                <Button
                    variant='secondary'
                    size='lg'
                    onClick={hideModal}
                    tabIndex={-1}
                >
                    Cancel
                </Button>
                <Button
                    disabled={all_disabled || calculatedOverShort !== currentOverShort}
                    size='lg'
                    onClick={submit}
                >
                    Save
                </Button>
            </Modal.Footer>
            {/* </Form> */}
            {/* </div> */}
        </Modal>
    );
};

export default SalesJournalEditModal;