import React from 'react';
import { Button, Form, Row, Col, ButtonGroup, Container } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../hooks/use-redux';
import useSJLockedState from '../hooks/use-SJLockedState';
import { getBanks } from '../store/banks-actions';
// import DepositGrandTotals from './DepositGrandTotals';
import VerifyCreditTotals from './VerifyCreditTotals';
import VerifyDeposits from './VerifyDeposits';
import useAxiosPost from '../hooks/use-axios-post';
import { GOAPIPATH } from '../constants';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';

interface month {
    displayName: string;
    start: string; // YYYY-MM-DD
    end: string; // YYYY-MM-DD
}

const DepositTabs = () => {
    const userValue = useAppSelector(state => state.auth.user);
    const [refreshKey, setRefreshKey] = React.useState(1);
    const [key, setKey] = React.useState('deposit');
    const [months, setMonths] = React.useState<month[]>([]);
    const [selectedMonth, setSelectedMonth] = React.useState(-1);
    // Get the current sales journal month and year. Sync up, if possible.
    const selectedSJYear = useAppSelector(state => state.selectors.selectedYear);
    const selectedSJMonth = useAppSelector(state => state.selectors.selectedMonth);
    // const [isLocked, setIsLocked] = React.useState(false);
    const dispatch = useAppDispatch();

    const isSuperUserOrComptroller = userValue?.Permissions?.find(role => ['comptroller', 'superUser'].includes(role)) ? true : false;
    
    React.useEffect(() => {
        dispatch(getBanks());
        const displayDateFormatter = Intl.DateTimeFormat('en-us', { month: 'long', year: 'numeric' });
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        var newMonths: month[] = [];
        let newSelectedMonth = 0;
        for (var i = 0; i < 3; i++) {

            var dateStart = new Date(todayYear, todayMonth - i, 1);
            var dateEnd = new Date(todayYear, todayMonth - i + 1, 0);
            var month: month = {
                displayName: displayDateFormatter.format(dateStart),
                start: dateStart.toISOString().split('T')[0],
                end: dateEnd.toISOString().split('T')[0],
            };
            if (dateStart.getMonth() === selectedSJMonth && dateStart.getFullYear() === selectedSJYear) {
                // We can select the current Sales Journal month/year! Do it!
                newSelectedMonth = i;
            }
            newMonths.push(month);
        }
        setMonths(newMonths);
        setSelectedMonth(newSelectedMonth);
    }, [dispatch, selectedSJMonth, selectedSJYear]);

    // const applyLockedState = (data: { isLocked: boolean; }) => {
    //     setIsLocked(data.isLocked);
    // };

    // const [axiosGetLockedState] = useAxiosGet(applyLockedState);

    // const getLockedState = React.useCallback(() => {
    //     if (selectedMonth < 0) return;
    //     axiosGetLockedState(
    //         `${GOAPIPATH}/salesJournalLockedState?month=${months[selectedMonth].start}`,
    //         {
    //             withCredentials: true
    //         }
    //     );
    // }, [axiosGetLockedState, selectedMonth, months]);

    // React.useEffect(() => {
    //     getLockedState();
    //     const intervalId = setInterval(getLockedState, 1 * 60 * 1000); // x minute(s) x*60*1000
    //     return () => clearInterval(intervalId);
    // }, [getLockedState]);

    const monthsLoaded = selectedMonth > -1;
    const start = monthsLoaded ? months[selectedMonth].start : '';
    const end = monthsLoaded ? months[selectedMonth].end : '';
    const [isLocked, lockButtonElement] = useSJLockedState(false, start !== '' ? new Date(start) : undefined);

    const monthOptionElements = months.map((month, index) => {
        return (
            <option key={index} value={index}>{month.displayName}</option>
            );
        });

    const updateSelectedMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setSelectedMonth(parseInt(value));
    };

    const [axiosPostSetUnacceptedToHitBankNextMonth] = useAxiosPost(undefined);

    const setUnacceptedToHitBankNextMonth = async () => {
        // const confirmed = window.confirm(`Are you sure verification for ${months[selectedMonth].displayName} is complete? Is this good wording?`);
        // if (!confirmed) return;

        const error = await axiosPostSetUnacceptedToHitBankNextMonth(
            `${GOAPIPATH}/setUnacceptedToHitBankNextMonth?from=${start}&to=${end}`,
            undefined,
            {
                withCredentials: true
            }
        );
        if (error !== null) {
            console.log('Failed to submit deposit. The following error was generated:', error);
        } else {
            setRefreshKey(prevValue => prevValue + 1);
        }
    }

    return (
        <div className='h-100 d-flex flex-column'>
            <div className='h-100 overflow-auto'>
                {monthsLoaded && key === 'deposit' && <VerifyDeposits key={refreshKey} start={start} end={end} isLocked={isLocked}/>}
                {monthsLoaded && key === 'creditCard' && <VerifyCreditTotals key={refreshKey} start={start} end={end} isLocked={isLocked} />}
                {/* {monthsLoaded && key === 'totals' && <DepositGrandTotals start={start} end={end} />} */}
            </div>
            <Container className='text-bg-secondary' fluid>
                <Row className='py-1 gy-1 d-flex align-items-center'>
                    <Col xs='auto'>
                        <h3>Daily Log</h3>
                    </Col>
                    <Col xs='auto'>
                        <Form.Select value={selectedMonth} onChange={updateSelectedMonth}>
                            {monthOptionElements}
                        </Form.Select>
                    </Col>
                    <Col xs='auto'>
                        <ButtonGroup className='text-nowrap'>
                            <Button
                                onClick={() => setKey('deposit')}
                                variant={key === 'deposit' ? 'dark' : 'light'}
                            >
                                Deposit
                            </Button>
                            <Button
                                onClick={() => setKey('creditCard')}
                                variant={key === 'creditCard' ? 'dark' : 'light'}
                            >
                                Credit Card
                            </Button>
                            {/* <Button
                                onClick={() => setKey('totals')}
                                variant={key === 'totals' ? 'dark' : 'light'}
                            >
                                Grand Totals
                            </Button> */}
                        </ButtonGroup>
                    </Col>
                    <Col className='ms-auto me-4' xs='auto'>
                        {isSuperUserOrComptroller &&
                            <ButtonWithConfirmation
                                popupHeader=''
                                popupMessage={`Verification for ${months[selectedMonth]?.displayName} is complete. Mark unverified deposits and credit card totals as posting to the bank next month.`}
                                buttonLabel='Month End'
                                onConfirm={setUnacceptedToHitBankNextMonth}
                            />
                        }
                    </Col>
                    <Col xs='auto'>
                        {lockButtonElement}
                        {/* <SalesJournalLockButton month={new Date(start)} allowToggle={false} locked={isLocked} /> */}
                    </Col>
                    {/* <Col className='d-flex align-items-center'>
                        <Button className='ms-auto' variant='light' href={`${GOAPIPATH}/downloadDepositData?from=${start}&to=${end}`} >Download</Button>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default DepositTabs;