import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAppSelector } from '../hooks/use-redux';
// import { authActions } from '../store/auth-slice';
import { Navbar, Button, Nav, NavDropdown } from 'react-bootstrap';
import { PYTHONFLASKPATH } from '../constants';
import { User } from '../store/auth-slice';

const Layout = () => {
    // const dispatch = useAppDispatch();
    // const navigate = useNavigate();

    const userValue: User = useAppSelector(state => state.auth.user);

    const logout = () => {
        // dispatch(authActions.logout());
        window.location.href = `${PYTHONFLASKPATH}/logout`;
        // window.location.replace(`${PYTHONFLASKPATH}/logout`);
        // navigate('/login');
    };
    return (
        <React.Fragment>
            <Navbar className='py-1 pe-1 pe-sm-0' expand='sm' collapseOnSelect bg='dark' variant='dark'>
                <Navbar.Brand className='ms-3' href={PYTHONFLASKPATH} >
                    Dashboard LMC
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className='me-1'>
                    <Nav>
                        {/* <Nav.Item className=''>
                            <Nav.Link eventKey='1' href={PYTHONFLASKPATH} >My Dashboard</Nav.Link>
                        </Nav.Item> */}
                        {userValue?.Permissions?.find(role => ['comptroller', 'superUser'].includes(role)) &&
                            <React.Fragment>
                                <NavDropdown className='zindex-sticky-plus-one' title='Daily Log' menuVariant='dark'>
                                    <NavDropdown.Item as='div'>
                                        <Nav.Link as={Link} to='/dailyLog' eventKey='dl-1'>Manager Entry</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as='div'>
                                        <Nav.Link as={Link} to='/dailyLogSummary' eventKey='dl-2'>Summary/Verification</Nav.Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </React.Fragment>
                        }
                        {userValue?.Permissions?.find(role => ['PFC', 'acct payable'].includes(role)) &&
                            <React.Fragment>
                                <Nav.Link as={Link} to='/dailyLogSummary' eventKey='dl-2'>Daily Log</Nav.Link>
                            </React.Fragment>
                        }
                        {userValue?.Permissions?.find(role => ['PFC', 'acct payable', 'comptroller', 'superUser'].includes(role)) &&
                            <React.Fragment>
                                <NavDropdown className='zindex-sticky-plus-one' title='Sales Journal' menuVariant='dark'>
                                    <NavDropdown.Item as='div'>
                                        <Nav.Link as={Link} to='/salesJournal' eventKey='sj-1'>Sales Journal</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as='div'>
                                        <Nav.Link as={Link} to='/salesJournalExport' eventKey='sj-2'>Summary/Export</Nav.Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </React.Fragment> 
                        }
                        {userValue?.Permissions?.find(role => ['superUser'].includes(role)) &&
                            <Nav.Link as={Link} to='/about' eventKey='dl-2'>About</Nav.Link>
                        }
                    </Nav>
                    {userValue?.Email &&
                        <Nav.Item className='ms-auto me-3'>
                            <Button variant='secondary' onClick={logout}>Logout</Button>
                        </Nav.Item>
                    }
                </Navbar.Collapse>
            </Navbar>
            <div className='h-100 overflow-auto'>
                <Outlet />
            </div>
            {/* <div className='text-bg-secondary'>
                Footer
            </div> */}
        </React.Fragment>
    );
};

export default Layout;